import { UpdateTutorialMutationVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_TUTORIAL = 'UPDATE_TUTORIAL';
export const UPDATE_TUTORIAL_SUCCESS = 'UPDATE_TUTORIAL_SUCCESS';
export const UPDATE_TUTORIAL_ERROR = 'UPDATE_TUTORIAL_ERROR';

export interface UpdateTutorialAction {
  type: typeof UPDATE_TUTORIAL;
  payload: UpdateTutorialMutationVariables;
}

export interface UpdateTutorialActionSuccess {
  type: typeof UPDATE_TUTORIAL_SUCCESS;
  payload: boolean;
}

export interface UpdateTutorialActionError {
  type: typeof UPDATE_TUTORIAL_ERROR;
  payload: AppError;
}
export type UpdateTutorialActionTypes = UpdateTutorialAction | UpdateTutorialActionSuccess | UpdateTutorialActionError;
