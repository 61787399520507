import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { getToken } from '@helpers/token';
import env from './env';
import { message } from 'antd';
const httpLink = createUploadLink({
  uri: env.apiEndPoint,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      const errorMessage = error.extensions?.message || error.message;
      if (errorMessage) {
        message.error(errorMessage);
      } else {
        message.error('Không thể kết nối tới API, Vui lòng liên hệ nhân viên hỗ trợ.');
      }
    }
  }

  if (networkError) {
    message.error('Không thể kết nối tới API, Vui lòng liên hệ nhân viên hỗ trợ.');
  }
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network' },
  },
});

export default client;
