import { Tutorial } from '@/graphql/generated/graphql';
import { Button, Form, Input, InputNumber, Upload, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import updateTutorial from '@modules/Tutorial/hooks/useUpdateTutorial';
import useUploadPhoto from '@modules/Tutorial/hooks/useUploadPhoto';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import useGetTutorials from '../hooks/useGetTutorials';

interface PropsInterface {
  isShow: boolean;
  toggleShow(visible?: boolean): void;
  data?: Tutorial | null;
}

interface IState {
  loading: boolean;
  imageUrl: string;
}

export default function TutorialUpdateModal(props: PropsInterface) {
  const [form] = Form.useForm();
  const { loading, onUpdate, isSuccess } = updateTutorial();
  const { onUpload, loading: uploadLoading, data: imageFile } = useUploadPhoto();
  const { onGetList } = useGetTutorials();

  useEffect(() => {
    if (isSuccess) {
      onGetList();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const [state, setState] = useState<IState>({
    loading: false,
    imageUrl: '',
  });

  useEffect(() => {
    if (props.isShow && props.data) {
      setState({
        ...state,
        imageUrl: props.data?.thumb?.thumbnail || '',
      });
    }
    // eslint-disable-next-line
  }, [props.isShow]);

  // upload code
  useEffect(() => {
    if (imageFile?.thumbnail) {
      setState({
        ...state,
        imageUrl: imageFile.thumbnail,
        loading: false,
      });
    }
    // eslint-disable-next-line
  }, [imageFile]);

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadRequest = async (options: any) => {
    onUpload({ file: options.file });
  };
  // end of upload code

  const onClose = () => {
    props.toggleShow(false);
  };

  useEffect(() => {
    return () => onClose();
    // eslint-disable-next-line
  }, []);

  const onFinish = (values: any) => {
    if (props.data?.id) {
      onUpdate({
        id: props.data.id,
        data: {
          ...values,
          thumbId: imageFile ? imageFile.id : props.data.thumbId,
        },
      });
      onClose();
    }
  };

  return (
    <Modal
      width="60%"
      visible={props.isShow}
      title="Cập nhật hướng dẫn"
      onCancel={onClose}
      footer={null}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      {props.data ? (
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="title"
            label="Tiêu đề"
            initialValue={props.data.title}
            rules={[
              { required: true, message: 'Trường này là bắt buộc' },
              { whitespace: true, message: 'Trường này là bắt buộc' },
              { max: 255, message: 'Trường này không được quá 255 ký tự' },
            ]}
            labelCol={{ span: 6 }}
          >
            <Input placeholder="Tiêu đề" />
          </Form.Item>
          <Form.Item
            name="url"
            label="Video Url"
            initialValue={props.data.url}
            rules={[
              { required: true, message: 'Trường này là bắt buộc' },
              { whitespace: true, message: 'Trường này là bắt buộc' },
            ]}
            labelCol={{ span: 6 }}
          >
            <Input placeholder="Video Url" />
          </Form.Item>
          <Form.Item
            name="thumbId"
            label="Ảnh"
            // rules={[{ required: true, message: 'Trường này là bắt buộc' }]}
            labelCol={{ span: 6 }}
            initialValue={props.data.thumbId}
            // valuePropName="fileList"
          >
            <Upload
              style={{ width: '200px', height: '200px' }}
              listType="picture"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={uploadRequest}
            >
              {state.imageUrl ? (
                <img src={state.imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                <div>{uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}</div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            name="sort"
            label="Thứ tự"
            initialValue={props.data.sort}
            rules={[{ required: true, message: 'Trường này là bắt buộc' }]}
            labelCol={{ span: 6 }}
          >
            <InputNumber min={0} type="number" placeholder="Thứ tự" />
          </Form.Item>
          <div className="text-right">
            <Button key="back" onClick={onClose} className="mr-3">
              Huỷ
            </Button>
            <Button htmlType="submit" key="submit" type="primary" loading={loading}>
              Cập nhật
            </Button>
          </div>
        </Form>
      ) : null}
    </Modal>
  );
}
