import { Organization, OrganizationStatus } from '@/graphql/generated/graphql';
import { CheckOutlined, EditFilled } from '@ant-design/icons';
import PageHeader from '@commons/components/PageHeader';
import TableHeader from '@commons/components/TableHeader';
import OrganizationUpdateForm from '@modules/Organization/components/OrganizationUpdateForm';
import useChangeOrganizationStatus from '@modules/Organization/hooks/useChangeOrganizationStatus';
import useGetOrganizations from '@modules/Organization/hooks/useGetOrganizations';
import { Button, Popconfirm, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const routes = [
  {
    path: '/',
    breadcrumbName: 'Trang chủ',
  },
  {
    path: '/organization-registered',
    breadcrumbName: 'Tổ chức mới',
  },
];

interface InitState {
  selectedRowKeys: any[];
  pageIndex: number;
  pageSize: number;
  isShowDetail: boolean;
  isShowUpdate: boolean;
  data: Organization | null;
}

export default function ListUserPage() {
  const { onGetList, data: organizations } = useGetOrganizations();
  const { onChangeStatus, isSuccess } = useChangeOrganizationStatus();
  const [state, setState] = useState<InitState>({
    selectedRowKeys: [],
    pageIndex: 1,
    pageSize: 10,
    isShowDetail: false,
    isShowUpdate: false,
    data: null,
  });

  // const onSelectChange = (selectedRowKeys: any[]) => {
  //   setState({ ...state, selectedRowKeys });
  // };

  // const rowSelection = {
  //   type: 'checkbox' as RowSelectionType,
  //   selectedRowKeys: state.selectedRowKeys,
  //   columnWidth: 30,
  //   onChange: onSelectChange,
  // };

  useEffect(() => {
    onGetList({
      types: [OrganizationStatus.Registered, OrganizationStatus.AwaitForActive],
      pageSize: state.pageSize,
      pageIndex: state.pageIndex,
    });
    // eslint-disable-next-line
  }, [state.pageIndex, state.pageSize]);

  const handleChangeStatus = (event: any, record: any) => {
    onChangeStatus({ data: { organizationId: record.id, status: OrganizationStatus.Active } });
  };

  useEffect(() => {
    if (isSuccess)
      onGetList({
        types: [OrganizationStatus.Registered, OrganizationStatus.AwaitForActive],
        pageSize: state.pageSize,
        pageIndex: state.pageIndex,
      });
    // eslint-disable-next-line
  }, [isSuccess]);

  // const handleDelete = (event: any, key: any) => {
  //   event.stopPropagation();
  //   // Todo: delete
  // };

  const handleEdit = (event: any, record: any) => {
    event.stopPropagation();
    setState({ ...state, isShowUpdate: true, data: record });
  };

  const columns: ColumnsType<Organization> = [
    {
      title: 'STT',
      dataIndex: '',
      className: 'text-center',
      render: (text: any, record: any, index: number) => index + 1,
      width: 30,
    },
    {
      title: 'Công ty',
      dataIndex: 'name',
      width: 100,
      render: (text: any, record: any, index: number) => {
        return <b>{text}</b>;
      },
    },
    {
      title: 'Lời nhắn',
      dataIndex: 'message',
      width: 100,
      responsive: ['lg'] as Breakpoint[],
      render: (_, record: any) => {
        return record?.owner?.message;
      },
    },
    {
      title: 'Người đại diện (utm data)',
      dataIndex: '',
      render: (text: any, record: any, index: number) => {
        const utm = JSON.parse(record.owner?.utm);
        let utmStr = '';
        if (typeof utm === 'object' && utm !== null) {
          for (const [key, value] of Object.entries(utm)) {
            if (['utm_source', 'utm_campaign', 'utm_term'].includes(key)) {
              utmStr += `<span><span class="mr-2">${key}:</span><strong>${decodeURIComponent(
                value as string,
              )}</strong>, </span>`;
            }
          }
        }
        return (
          <>
            <div>{`${record.owner?.lastName} ${record.owner?.firstName}`}</div>
            {utmStr ? <div dangerouslySetInnerHTML={{ __html: utmStr }} /> : null}
          </>
        );
      },
      width: 140,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 80,
    },
    {
      title: 'Số ĐT',
      dataIndex: 'phoneNumber',
      width: 80,
    },
    {
      title: 'Thành viên',
      dataIndex: 'membersNumber',
      width: 50,
      responsive: ['lg'] as Breakpoint[],
    },
    // {
    //   title: 'Ngày bắt đầu',
    //   dataIndex: 'startDate',
    //   render: (value: any) => {
    //     return value && moment.unix(value).format('DD/MM/YYYY h:mm A');
    //   },
    //   width: 80,
    //   responsive: ['md'] as Breakpoint[],
    // },
    // {
    //   title: 'Ngày kết thúc',
    //   dataIndex: 'endDate',
    //   render: (value: any) => {
    //     return value && moment.unix(value).format('DD/MM/YYYY h:mm A');
    //   },
    //   width: 80,
    //   responsive: ['md'] as Breakpoint[],
    // },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 60,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (value: any) => {
        return value && moment.unix(value).format('DD/MM/YYYY h:mm A');
      },
      width: 80,
      responsive: ['xl'] as Breakpoint[],
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: 70,
      fixed: 'right',
      render: (_text: string, record: any) => {
        return (
          <Row style={{ minWidth: 116, whiteSpace: 'nowrap' }}>
            <Popconfirm
              title="Bạn muốn kích hoạt tổ chức này?"
              onCancel={(event) => {
                event?.stopPropagation();
              }}
              onConfirm={(event) => handleChangeStatus(event, record)}
            >
              <Button
                size="small"
                className="ant-btn ml-1 mr-1 ant-btn-sm mb-1"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <CheckOutlined />
              </Button>
            </Popconfirm>
            <Button
              size="small"
              className="ant-btn ml-1 mr-1 ant-btn-sm mb-1"
              onClick={(event) => handleEdit(event, record)}
            >
              <EditFilled />
            </Button>
            {/* <Popconfirm
              title="Bạn muốn xoá tổ chức này?"
              onCancel={(event) => {
                event?.stopPropagation();
              }}
              onConfirm={(event) => handleDelete(event, record.id)}
            >
              <Button
                size="small"
                type="default"
                className="ant-btn ml-1 mr-1 ant-btn-sm"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <DeleteFilled />
              </Button>
            </Popconfirm> */}
          </Row>
        );
      },
    },
  ];

  const toggleShowUpdateForm = (visible?: boolean) => {
    if (typeof visible !== undefined) {
      setState({ ...state, isShowUpdate: !!visible });
    } else {
      setState({ ...state, isShowUpdate: !!state.isShowUpdate });
    }
  };

  return (
    <>
      <PageHeader title="Quản lý tổ chức" breadcrumb={{ routes }} />
      <TableHeader title="Danh sách tổ chức mới">
        <Table
          dataSource={organizations?.items as Organization[]}
          columns={columns}
          rowKey="id"
          size="small"
          scroll={{ x: 1024 }}
          tableLayout="fixed"
          pagination={{
            hideOnSinglePage: true,
            current: state.pageIndex,
            pageSize: state.pageSize,
            total: organizations?.paginate?.totalItems || 10,
            onChange: (pageIndex) => setState({ ...state, pageIndex }),
            onShowSizeChange: (currentPage, pageSize) => setState({ ...state, pageSize }),
            showTotal: (total, range) => `Đang xem ${range[0]} đến ${range[1]} trong tổng số ${total} mục`,
          }}
          // rowSelection={rowSelection}
        />
      </TableHeader>
      <OrganizationUpdateForm
        updateList={() =>
          onGetList({
            types: [OrganizationStatus.Registered, OrganizationStatus.AwaitForActive],
            pageSize: state.pageSize,
            pageIndex: state.pageIndex,
          })
        }
        isShow={state.isShowUpdate}
        toggleShow={toggleShowUpdateForm}
        data={state.data}
      />
    </>
  );
}
