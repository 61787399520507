import { Tutorial } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { GetTutorialsActionTypes, GET_TUTORIALS, GET_TUTORIALS_ERROR, GET_TUTORIALS_SUCCESS } from '../action-types';

export const getTutorials = (): GetTutorialsActionTypes => ({
  type: GET_TUTORIALS,
});

export const getTutorialsError = (payload: AppError): GetTutorialsActionTypes => ({
  type: GET_TUTORIALS_ERROR,
  payload,
});

export const getTutorialsSuccess = (payload?: Tutorial[]): GetTutorialsActionTypes => ({
  type: GET_TUTORIALS_SUCCESS,
  payload,
});
