import { combineReducers } from 'redux';
import listUser, { ListUserState } from './list-user';

export interface UserModuleState {
  listUser: ListUserState;
}

export default combineReducers<UserModuleState>({
  listUser,
});
