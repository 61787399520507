import {
  DeleteTutorialActionTypes,
  DELETE_TUTORIAL,
  DELETE_TUTORIAL_ERROR,
  DELETE_TUTORIAL_SUCCESS,
} from '../action-types';

export interface DeleteTutorialState {
  loading: boolean;
  isSuccess: boolean;
}

const initialState = {
  loading: false,
  isSuccess: false,
};

export default function deleteTutorial(
  state: DeleteTutorialState = initialState,
  action: DeleteTutorialActionTypes,
): DeleteTutorialState {
  switch (action.type) {
    case DELETE_TUTORIAL:
      return {
        ...state,
        loading: true,
      };

    case DELETE_TUTORIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    case DELETE_TUTORIAL_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };

    default:
      return state;
  }
}
