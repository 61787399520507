import {
  DashboardOutlined,
  UserOutlined,
  CheckCircleFilled,
  ExclamationCircleOutlined,
  VideoCameraOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import { Link } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

export default function LeftNavigation() {
  const breadCrumb = useReactRouterBreadcrumbs();
  const last = breadCrumb[breadCrumb.length - 1];

  return (
    <Menu theme="dark" mode="inline" selectedKeys={[last.key]}>
      <Menu.Item key="/" icon={<DashboardOutlined />}>
        <Link to="/">Tổng quan</Link>
      </Menu.Item>
      <Menu.Item key="/organization-registered" icon={<ExclamationCircleOutlined />}>
        <Link to="/organization-registered">Tổ chức mới</Link>
      </Menu.Item>
      <Menu.Item key="/organization-active" icon={<CheckCircleFilled />}>
        <Link to="/organization-active">Tổ chức đã kích hoạt</Link>
      </Menu.Item>
      <Menu.Item key="/users" icon={<UserOutlined />}>
        <Link to="/users">Người dùng</Link>
      </Menu.Item>
      <Menu.Item key="/tutorials" icon={<VideoCameraOutlined />}>
        <Link to="/tutorials">Hướng dẫn sử dụng</Link>
      </Menu.Item>
      <SubMenu key="sub1" icon={<FormOutlined />} title="Blog">
        <Menu.Item key="/blogs">
          <Link to="/blogs">Bài viết </Link>
        </Menu.Item>
        <Menu.Item key="/blog-categories">
          <Link to="/blog-categories">Danh mục </Link>
        </Menu.Item>
        <Menu.Item key="/blog-stories">
          <Link to="/blog-stories">Chuyên mục </Link>
        </Menu.Item>
        <Menu.Item key="/blog-tags">
          <Link to="/blog-tags">Từ khóa (#tag)</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}
