import { AdminLoginInput, JwtFields } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { LOGIN, LoginActionTypes, LOGIN_ERROR, LOGIN_SUCCESS } from '../action-types/login';

export const login = (payload: AdminLoginInput): LoginActionTypes => ({
  type: LOGIN,
  payload,
});

export const loginError = (payload: AppError): LoginActionTypes => ({
  type: LOGIN_ERROR,
  payload,
});

export const loginSuccess = (payload: JwtFields, isAutoLogin = false): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  payload,
  isAutoLogin,
});
