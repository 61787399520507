import { Button, Form, Input, InputNumber, Upload, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import createTutorial from '@modules/Tutorial/hooks/useCreateTutorial';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import useUploadPhoto from '@modules/Tutorial/hooks/useUploadPhoto';
import useGetTutorials from '../hooks/useGetTutorials';
import { getThumbYoutube, YoutubeThumbSize } from '../helpers/youtube';

interface PropsInterface {
  isShow: boolean;
  toggleShow(visible?: boolean): void;
}

export default function TutorialCreateModal(props: PropsInterface) {
  const [form] = Form.useForm();
  const { loading, onCreate, isSuccess } = createTutorial();
  const { onUpload, loading: uploadLoading, data: imageFile } = useUploadPhoto();
  const { onGetList } = useGetTutorials();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      onGetList();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const [state, setState] = useState({
    loading: false,
    imageUrl: '',
  });

  // upload code
  useEffect(() => {
    if (imageFile?.thumbnail) {
      setState({
        imageUrl: imageFile.thumbnail,
        loading: false,
      });
    }
    // eslint-disable-next-line
  }, [imageFile]);

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setState({ ...state, loading: true });
      return;
    }
  };

  const uploadRequest = async (options: any) => {
    onUpload({ file: options.file });
  };
  // end of upload code

  const onClose = () => {
    props.toggleShow(false);
    form.resetFields();
    setState({
      loading: false,
      imageUrl: '',
    });
  };

  useEffect(() => {
    return () => onClose();
    // eslint-disable-next-line
  }, []);

  const onFinish = (values: any) => {
    if (imageFile?.id) {
      onCreate({
        data: {
          ...values,
          thumbId: imageFile.id,
        },
      });
    } else {
      const thumbUrl = getThumbYoutube(values.url, YoutubeThumbSize.HIGHT);
      onCreate({
        data: {
          ...values,
          thumbUrl,
        },
      });
    }
  };

  return (
    <Modal width="60%" visible={props.isShow} title="Tạo hướng dẫn" onCancel={onClose} footer={null} destroyOnClose>
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          name="title"
          label="Tiêu đề"
          rules={[
            { required: true, message: 'Trường này là bắt buộc' },
            { whitespace: true, message: 'Trường này là bắt buộc' },
            { max: 255, message: 'Trường này không được quá 255 ký tự' },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Tiêu đề" />
        </Form.Item>
        <Form.Item
          name="url"
          label="Video Url"
          rules={[
            { required: true, message: 'Trường này là bắt buộc' },
            { whitespace: true, message: 'Trường này là bắt buộc' },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Video Url" />
        </Form.Item>
        <Form.Item
          name="thumbId"
          label="Ảnh"
          // rules={[{ required: true, message: 'Trường này là bắt buộc' }]}
          labelCol={{ span: 6 }}
          valuePropName="fileList"
        >
          <Upload
            name="avatar"
            listType="picture"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={uploadRequest}
          >
            {state.imageUrl ? (
              <img src={state.imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              <div>{uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}</div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="sort"
          label="Thứ tự"
          rules={[{ required: true, message: 'Trường này là bắt buộc' }]}
          labelCol={{ span: 6 }}
        >
          <InputNumber min={0} type="number" placeholder="Thứ tự" />
        </Form.Item>
        <div className="text-right">
          <Button key="back" onClick={onClose} className="mr-3">
            Huỷ
          </Button>
          <Button htmlType="submit" key="submit" type="primary" loading={loading}>
            Tạo
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
