import { ListUser, ListUserVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const LIST_USER = 'LIST_USER';
export const LIST_USER_SUCCESS = 'LIST_USER_SUCCESS';
export const LIST_USER_ERROR = 'LIST_USER_ERROR';
export const LIST_USER_RESET = 'LIST_USER_RESET';

export interface ListUserAction {
  type: typeof LIST_USER;
  payload: ListUserVariables;
}

export interface ListUserActionSuccess {
  type: typeof LIST_USER_SUCCESS;
  payload: ListUser;
}

export interface ListUserActionError {
  type: typeof LIST_USER_ERROR;
  payload: AppError;
}

export interface ListUserActionReset {
  type: typeof LIST_USER_RESET;
}

export type ListUserActionTypes = ListUserAction | ListUserActionSuccess | ListUserActionError | ListUserActionReset;
