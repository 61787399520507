import { ListUser } from '@/graphql/generated/graphql';
import { call, put } from 'redux-saga/effects';
import { listUserError, listUserSuccess } from '../actions/list-user';
import { ListUserAction } from '../action-types';
import { getSDK } from '@services/graphql-client';

export function* listUserSaga(action: ListUserAction) {
  const skd = getSDK();
  try {
    const res: ListUser = yield call(skd.listUser, action.payload);
    yield put(listUserSuccess(res));
  } catch (error) {
    yield put(listUserError(error));
  }
}
