import { User } from '@/graphql/generated/graphql';
import { EditFilled } from '@ant-design/icons';
import PageHeader from '@commons/components/PageHeader';
import TableHeader from '@commons/components/TableHeader';
import useGetUsers from '@modules/User/hooks/useGetUsers';
import { Button, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import UserUpdateModal from '@modules/User/components/UserUpdateModal';

const { Text } = Typography;

const routes = [
  {
    path: '/',
    breadcrumbName: 'Trang chủ',
  },
  {
    path: '/users',
    breadcrumbName: 'Quản lý người dùng',
  },
];

interface InitState {
  selectedRowKeys: any[];
  pageIndex: number;
  pageSize: number;
  isShowDetail: boolean;
  isShowUpdate: boolean;
  data: User | null;
}

export default function ListUserPage() {
  const { onGetList, data: users } = useGetUsers();
  const [state, setState] = useState<InitState>({
    selectedRowKeys: [],
    pageIndex: 1,
    pageSize: 10,
    isShowDetail: false,
    isShowUpdate: false,
    data: null,
  });

  // const onSelectChange = (selectedRowKeys: any[]) => {
  //   setState({ ...state, selectedRowKeys });
  // };

  // const rowSelection = {
  //   type: 'checkbox' as RowSelectionType,
  //   selectedRowKeys: state.selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  useEffect(() => {
    onGetList({ pageSize: state.pageSize, pageIndex: state.pageIndex });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onGetList({ pageSize: state.pageSize, pageIndex: state.pageIndex });
    // eslint-disable-next-line
  }, [state.pageIndex]);

  useEffect(() => {
    onGetList({ pageSize: state.pageSize, pageIndex: state.pageIndex });
    // eslint-disable-next-line
  }, [state.pageSize]);

  const toggleShowUpdateForm = (visible?: boolean) => {
    if (typeof visible !== undefined) {
      setState({ ...state, isShowUpdate: !!visible });
    } else {
      setState({ ...state, isShowUpdate: !!state.isShowUpdate });
    }
  };

  const handleEdit = (event: any, record: any) => {
    event.stopPropagation();
    setState({ ...state, isShowUpdate: true, data: record });
  };

  const columns: ColumnsType<User> = [
    {
      title: 'STT',
      dataIndex: '',
      render: (text: any, record: any, index: number) => index + 1,
      width: 30,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      width: 100,
      render: (value: string, record: any) => {
        return (
          <div>
            <Text>{`${record.firstName} ${record.lastName}`}</Text>
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 100,
    },
    {
      title: 'Chức vụ',
      dataIndex: 'jobTitle',
      width: 80,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      width: 60,
      render: (value: any) => {
        return value && moment.unix(value).format('DD/MM/YYYY h:mm A');
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: 50,
      fixed: 'right',
      render: (_text: string, record: User) => {
        return (
          <Row style={{ whiteSpace: 'nowrap' }}>
            <Button
              size="small"
              className="ant-btn ml-1 mr-1 ant-btn-sm mb-1"
              onClick={(event) => handleEdit(event, record)}
            >
              <EditFilled />
            </Button>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader title="Quản lý người dùng" breadcrumb={{ routes }} />
      <TableHeader title="Danh sách người dùng">
        <Table
          dataSource={users?.items as User[]}
          columns={columns}
          rowKey="id"
          tableLayout="fixed"
          scroll={{ x: 1024 }}
          size="middle"
          pagination={{
            hideOnSinglePage: true,
            current: state.pageIndex,
            pageSize: state.pageSize,
            total: users?.paginate?.totalItems || 10,
            onChange: (pageIndex) => setState({ ...state, pageIndex }),
            onShowSizeChange: (currentPage, pageSize) => setState({ ...state, pageSize }),
            showTotal: (total, range) => `Đang xem ${range[0]} đến ${range[1]} trong tổng số ${total} mục`,
          }}
          // rowSelection={rowSelection}
        />
      </TableHeader>
      <UserUpdateModal isShow={state.isShowUpdate} toggleShow={toggleShowUpdateForm} data={state.data} />
    </>
  );
}
