import { ListUser, ListUserVariables } from '@/graphql/generated/graphql';
import { listUser } from '@modules/User/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useGetUsers = () => {
  const loading = useSelector<RootState>((state) => state.user.listUser.loading) as boolean;
  const data = useSelector<RootState, ListUser['users'] | undefined>((state) => state.user.listUser.data);
  const dispatch = useDispatch();
  const onGetList = useCallback((data: ListUserVariables) => {
    dispatch(listUser(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onGetList,
    loading,
    data,
  };
};

export default useGetUsers;
