import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import {
  changeOrganizationStatusErrorSaga,
  changeOrganizationStatusSaga,
  changeOrganizationStatusSuccessSaga,
} from './change-status';
import { getOrganizationsSaga } from './list';
import { updateOrganizationErrorSaga, updateOrganizationSaga, updateOrganizationSuccessSaga } from './update';

export default function* root() {
  return all([
    yield takeLatest(actionTypes.LIST_ORGANIZATION, getOrganizationsSaga),
    yield takeLatest(actionTypes.UPDATE_ORGANIZATION, updateOrganizationSaga),
    yield takeLatest(actionTypes.UPDATE_ORGANIZATION_SUCCESS, updateOrganizationSuccessSaga),
    yield takeLatest(actionTypes.UPDATE_ORGANIZATION_ERROR, updateOrganizationErrorSaga),
    yield takeLatest(actionTypes.CHANGE_ORGANIZATION_STATUS, changeOrganizationStatusSaga),
    yield takeLatest(actionTypes.CHANGE_ORGANIZATION_STATUS_SUCCESS, changeOrganizationStatusSuccessSaga),
    yield takeLatest(actionTypes.CHANGE_ORGANIZATION_STATUS_ERROR, changeOrganizationStatusErrorSaga),
  ]);
}
