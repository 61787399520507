import { getSDK } from '@services/graphql-client';
import { message } from 'antd';
import { call, put } from 'redux-saga/effects';
import {
  ChangeOrganizationStatusAction,
  ChangeOrganizationStatusActionError,
  ChangeOrganizationStatusActionSuccess,
} from '../action-types';
import { changeOrganizationStatusActionSuccess, changeOrganizationStatusActionError } from '../actions/change-status';

export function* changeOrganizationStatusSaga(action: ChangeOrganizationStatusAction) {
  try {
    const skd = getSDK();
    const res: boolean = yield call(skd.changeOrganizationStatus, action.payload);
    yield put(changeOrganizationStatusActionSuccess(res));
  } catch (error) {
    yield put(changeOrganizationStatusActionError(error));
  }
}

export function changeOrganizationStatusSuccessSaga(action: ChangeOrganizationStatusActionError) {
  message.success('Cập nhật thành công');
}

export function changeOrganizationStatusErrorSaga(action: ChangeOrganizationStatusActionSuccess) {
  message.error('Có lỗi, vui lòng thử lại');
}
