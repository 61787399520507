import env from '@/env';
import { getToken } from '@helpers/token';
import React, { useRef } from 'react';

declare var CKEDITOR: any;
interface IProps {
  value?: string;
  actionRef: any;
}
let uploadApi = `${env.apiEndPoint.replace('graphql', '')}api/upload-image`;
const RichText = ({ value, actionRef }: IProps) => {
  React.useEffect(() => {
    if (CKEDITOR.instances['onplan-editor']) {
      CKEDITOR.instances['onplan-editor'].setData(value);
    }
  }, [value]);
  React.useImperativeHandle(
    actionRef,
    () => {
      return {
        getData: () => {
          return CKEDITOR.instances['onplan-editor']?.getData() || '';
        },
      };
    },
    [],
  );

  React.useEffect(() => {
    setTimeout(() => {
      CKEDITOR.replace('onplan-editor', {
        extraPlugins: 'image2,uploadimage',
        filebrowserImageUploadUrl: uploadApi,
        removeDialogTabs: 'image:advanced;link:advanced',
        height: 450,
        removeButtons: 'PasteFromWord',
      }).on('fileUploadRequest', (evt: any) => {
        const token = getToken();
        const xhr = evt.data.fileLoader.xhr;
        xhr.setRequestHeader('authorization', token);
      });
      if (value) {
        CKEDITOR.instances['onplan-editor']?.setData(value);
      }
    }, 100);

    return () => {
      CKEDITOR.instances['onplan-editor']?.destroy();
    };
  }, []);
  return (
    <textarea id="onplan-editor" value={value}>
      {value}
    </textarea>
  );
};

export default RichText;
