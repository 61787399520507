import { protectedComponent } from '@hoc/protectedComponent';
import NewOrganizationPage from './pages/list/new';
import ActiveOrganizationPage from './pages/list/active';

export const OrganizationRouter = [
  {
    path: '/organization-registered',
    exact: true,
    component: protectedComponent(NewOrganizationPage),
  },
  {
    path: '/organization-active',
    exact: true,
    component: protectedComponent(ActiveOrganizationPage),
  },
];
