import { GetBlogsDocument, DeleteBlogDocument, Blog } from '@/graphql/generated/graphql';
import ZTable, { IColumnType, IFilterItem, ITableRef } from '@commons/ZTable/ZTable';
import { Button, Tag, Image } from 'antd';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { BlogStatusLable } from '../../types';
import { useHistory } from 'react-router';
import PageHeader from '@commons/components/PageHeader';

const routes = [
  {
    path: '/',
    breadcrumbName: 'Trang chủ',
  },
  {
    path: '',
    breadcrumbName: 'Bài viết',
  },
];

const columns: IColumnType<Blog>[] = [
  {
    title: 'Ảnh title',
    dataIndex: 'titleImage',
    width: 70,
    render: (v) => {
      return <Image preview={true} style={{ border: '1px solid #ccc' }} height="50px" width="auto" src={v} />;
    },
  },
  {
    title: 'Tên',
    dataIndex: 'title',
    width: 100,
  },
  {
    title: 'Danh mục',
    dataIndex: 'blogCategories',
    width: 100,
    render: (categories) => {
      return categories ? categories.map((item: any) => item.name).join(', ') : '';
    },
  },
  {
    title: 'Người tạo',
    dataIndex: 'createdBy',
    width: 100,
    render: (v) => {
      return v ? v.fullname : '';
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    width: 100,
    render: (v) => {
      return <Tag color={BlogStatusLable[v].color}>{BlogStatusLable[v].text}</Tag>;
    },
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    width: 100,
    render: (v) => {
      return moment(new Date(v ? v * 1000 : '')).format('DD/MM/YYYY HH:mm ');
    },
  },
];

function BlogMain() {
  const history = useHistory();
  const tableRef = React.useRef<ITableRef>();

  const filter: IFilterItem[] = React.useMemo(() => {
    const _filter: IFilterItem[] = [{ label: '', placeholder: 'Tên/Mô tả', type: 'TEXT', name: 'query', mdSize: 5 }];
    return _filter;
  }, []);

  return (
    <>
      <PageHeader title="Quản lý bài viết" breadcrumb={{ routes }} />
      <div style={{ margin: '10px 20px 10px 20px' }}>
        <ZTable<Blog>
          bordered
          size="small"
          columns={columns}
          gql={GetBlogsDocument}
          delete_gql={DeleteBlogDocument}
          primaryKey="id"
          tableRef={tableRef as any}
          schema="getBlogs"
          allowEdit={true}
          allowDelete={true}
          actionRight={false}
          filters={filter}
          onEdit={(record) => {
            history.push(`/blogs/update/${record.id}`);
          }}
          extraBtns={[
            <Button
              type="primary"
              key="add"
              onClick={() => {
                history.push('/blogs/create');
              }}
            >
              <PlusOutlined /> Tạo mới
            </Button>,
          ]}
        />
      </div>
    </>
  );
}

export default BlogMain;
