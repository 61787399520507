import { ChangeOrganizationStatusVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CHANGE_ORGANIZATION_STATUS = 'CHANGE_ORGANIZATION_STATUS';
export const CHANGE_ORGANIZATION_STATUS_SUCCESS = 'CHANGE_ORGANIZATION_STATUS_SUCCESS';
export const CHANGE_ORGANIZATION_STATUS_ERROR = 'CHANGE_ORGANIZATION_STATUS_ERROR';
export const CHANGE_ORGANIZATION_STATUS_RESET = 'CHANGE_ORGANIZATION_STATUS_RESET';

export interface ChangeOrganizationStatusAction {
  type: typeof CHANGE_ORGANIZATION_STATUS;
  payload: ChangeOrganizationStatusVariables;
}

export interface ChangeOrganizationStatusActionSuccess {
  type: typeof CHANGE_ORGANIZATION_STATUS_SUCCESS;
  payload: boolean;
}

export interface ChangeOrganizationStatusActionError {
  type: typeof CHANGE_ORGANIZATION_STATUS_ERROR;
  payload: AppError;
}

export interface ChangeOrganizationStatusActionReset {
  type: typeof CHANGE_ORGANIZATION_STATUS_RESET;
}

export type ChangeOrganizationStatusActionTypes =
  | ChangeOrganizationStatusAction
  | ChangeOrganizationStatusActionSuccess
  | ChangeOrganizationStatusActionError
  | ChangeOrganizationStatusActionReset;
