import { Tutorial } from '@/graphql/generated/graphql';
import { GetTutorialsActionTypes, GET_TUTORIALS, GET_TUTORIALS_ERROR, GET_TUTORIALS_SUCCESS } from '../action-types';

export interface GetTutorialsState {
  loading: boolean;
  data?: Tutorial[];
}

const initialState = {
  loading: false,
};

export default function listTutorial(
  state: GetTutorialsState = initialState,
  action: GetTutorialsActionTypes,
): GetTutorialsState {
  switch (action.type) {
    case GET_TUTORIALS:
      return {
        ...state,
        loading: true,
      };

    case GET_TUTORIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_TUTORIALS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
