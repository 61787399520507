import * as React from 'react';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { ColSize } from 'antd/es/grid/col';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import DatePicker from '../DatePicker';
declare type ColSpanType = number | string;

export interface ITableFilterItem {
  name: string;
  type: 'TEXT' | 'QUERY' | 'LIST' | 'DATE' | 'DATETIME' | 'SELECT' | 'CUSTOM';
  label: string;
  placeholder?: string;
  mdSize?: ColSpanType | ColSize;
  listData?: Array<{ value: string; label: string }>;
  defaultValue?: any;
  parseValue?: (v: any) => any;
  customElm?: React.ReactElement;
  required?: boolean;
}

interface IZFilterProps {
  onSearch: (query: any) => void;
  filters: Array<ITableFilterItem>;
  extraBtns?: any;
}

const requiredMess = 'Vui lòng nhập trường này';
const ZFilter: React.FunctionComponent<IZFilterProps> = ({ onSearch, filters, extraBtns }) => {
  const [searchForm] = Form.useForm();
  const _search = (values: any) => {
    let condition: any = {};
    filters.forEach((f) => {
      const parseData =
        f.parseValue ||
        ((v: any) => {
          return v;
        });
      if (values[f.name] !== null && values[f.name] !== undefined && values[f.name] !== '') {
        condition[f.name] = parseData(values[f.name]);
      }
    });

    onSearch(condition);
  };

  const initValue = React.useMemo(() => {
    const initialValues: any = {};
    filters.forEach((f) => {
      initialValues[f.name] = f.defaultValue;
    });
    return initialValues;
  }, [filters]);

  return (
    <Form
      id="ztable-search"
      form={searchForm}
      layout="horizontal"
      initialValues={initValue}
      onFinish={_search}
      style={{ overflow: 'hidden' }}
    >
      <Row gutter={16}>
        {filters.map(({ name, type, label, placeholder, mdSize, listData, customElm, required }, i) => {
          switch (type) {
            case 'CUSTOM':
              return (
                <Col xxl={mdSize || 6} md={mdSize || 6} key={i}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[{ required, message: requiredMess }]}
                    style={{ marginBottom: 0 }}
                  >
                    {customElm}
                  </Form.Item>
                </Col>
              );
            case 'SELECT':
              return (
                <Col xxl={mdSize || 6} md={mdSize || 6} key={i}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[{ required, message: requiredMess }]}
                    style={{ marginBottom: 0 }}
                  >
                    {customElm}
                  </Form.Item>
                </Col>
              );
            case 'LIST':
              return (
                <Col xxl={mdSize || 6} md={mdSize || 6} key={i}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[{ required, message: requiredMess }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select placeholder={listData ? listData[0].label : ''}>
                      {listData?.map((l, index) => {
                        return (
                          <Select.Option key={index} value={l.value}>
                            {l.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              );
            case 'QUERY':
              return (
                <Col xxl={mdSize || 6} md={mdSize || 6} key={i}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[{ required, message: requiredMess }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input placeholder={placeholder} />
                  </Form.Item>
                </Col>
              );
            case 'DATE':
              return (
                <Col xxl={mdSize || 6} md={mdSize || 6} key={i}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[{ required, message: requiredMess }]}
                    style={{ marginBottom: 0 }}
                  >
                    <DatePicker className="w-100" />
                  </Form.Item>
                </Col>
              );
            case 'DATETIME':
              return (
                <Col xxl={mdSize || 6} md={mdSize || 6} key={i}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[{ required, message: requiredMess }]}
                    style={{ marginBottom: 0 }}
                  >
                    <DatePicker showTime={true} className="w-100" />
                  </Form.Item>
                </Col>
              );

            default:
              return (
                <Col xxl={mdSize || 6} md={mdSize || 6} key={i}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[{ required, message: requiredMess }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input placeholder={placeholder} />
                  </Form.Item>
                </Col>
              );
          }
        })}
        <Col xxl={4} md={6}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                <SearchOutlined />
                {'Tìm kiếm'}
              </Button>
              <Button
                type="ghost"
                onClick={() => {
                  searchForm.resetFields();
                  searchForm.submit();
                }}
              >
                <CloseOutlined />
                {'Xóa'}
              </Button>
            </Space>
          </Form.Item>
        </Col>
        {extraBtns && (
          <Col style={{ flex: 1, textAlign: 'right' }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Space>{extraBtns}</Space>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default ZFilter;
