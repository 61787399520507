import React from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';

export const AddNewBtn = (props: ButtonProps) => {
  return (
    <Button title="Thêm mới" size="middle" icon={<PlusCircleOutlined />} type="primary" {...props}>
      {props.children}
    </Button>
  );
};
export const DeleteBtn = (props: ButtonProps) => {
  return (
    <Button title="Xoá" size="small" icon={<DeleteOutlined />} type="primary" danger={true} {...props}>
      {props.children}
    </Button>
  );
};
export const EditBtn = (props: ButtonProps) => {
  return (
    <Button
      title="Sửa"
      size="small"
      style={{ backgroundColor: '#28a745' }}
      icon={<EditOutlined />}
      type="primary"
      {...props}
    >
      {props.children}
    </Button>
  );
};
