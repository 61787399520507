import { all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../action-types';
import { createTutorialSaga } from './create';
import { deleteTutorialSaga } from './delete';
import { getTutorialsSaga } from './list';
import { updateTutorialSaga } from './update';
import { uploadPhotoSaga } from './upload';

export default function* root() {
  return all([
    yield takeLatest(actionTypes.GET_TUTORIALS, getTutorialsSaga),
    yield takeLatest(actionTypes.CREATE_TUTORIAL, createTutorialSaga),
    yield takeLatest(actionTypes.UPDATE_TUTORIAL, updateTutorialSaga),
    yield takeLatest(actionTypes.DELETE_TUTORIAL, deleteTutorialSaga),
    yield takeLatest(actionTypes.UPLOAD_PHOTO, uploadPhotoSaga),
  ]);
}
