import {
  BlogCategory,
  BlogTag,
  BlogTagStatus,
  GetBlogCategoriesDocument,
  DeleteBlogCategoryDocument,
} from '@/graphql/generated/graphql';
import ZTable, { IColumnType, IFilterItem, ITableRef } from '@commons/ZTable/ZTable';
import { Form, Input, message, Button, Tag, Modal, Row, Col, Select } from 'antd';
import React from 'react';
import { useCreateBlogCategory, useUpdateBlogCategory } from '../../hooks/category';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { BlogStatusLable, REQUIRE_MESSAGE, SLUG_INVALID_MESSAGE } from '../../types';
import TextArea from 'antd/lib/input/TextArea';
import { Collapse } from 'antd';
import PageHeader from '@commons/components/PageHeader';

const { Panel } = Collapse;
const { Option } = Select;

const routes = [
  {
    path: '/',
    breadcrumbName: 'Trang chủ',
  },
  {
    path: '',
    breadcrumbName: 'Danh mục',
  },
];

const columns: IColumnType<BlogCategory>[] = [
  {
    title: 'Tên',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    width: 100,
    render: (v) => {
      return <Tag color={BlogStatusLable[v].color}>{BlogStatusLable[v].text}</Tag>;
    },
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    width: 100,
    render: (v) => {
      return moment(new Date(v ? v * 1000 : '')).format('DD/MM/YYYY HH:mm ');
    },
  },
];

function BlogCategoryMain() {
  const [form] = Form.useForm();
  const tableRef = React.useRef<ITableRef>();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [currentBlogCategory, setCurrentBlogCategory] = React.useState<BlogTag>();

  const [createBlogCategory, { loading: createLoading }] = useCreateBlogCategory();
  const [updateBlogCategory, { loading: updateLoading }] = useUpdateBlogCategory();

  const filter: IFilterItem[] = React.useMemo(() => {
    const _filter: IFilterItem[] = [{ label: '', placeholder: 'Tên/Mô tả', type: 'TEXT', name: 'query', mdSize: 5 }];
    return _filter;
  }, []);

  React.useEffect(() => {
    if (currentBlogCategory) {
      form.setFieldsValue({
        ...currentBlogCategory,
      });
    }
  }, [currentBlogCategory]);

  React.useEffect(() => {
    if (!modalVisible) {
      form.resetFields();
    }
  }, [modalVisible]);

  const onSubmitSuccess = () => {
    setCurrentBlogCategory(undefined);
    setModalVisible(false);
    tableRef.current?.reload();
  };

  const onFormSubmit = (v: any) => {
    if (currentBlogCategory) {
      updateBlogCategory({
        variables: {
          data: {
            id: currentBlogCategory.id,
            name: v.name,
            description: v.description,
            slug: v.slug,
            nameForSeo: v.nameForSeo,
            descriptionForSeo: v.descriptionForSeo,
            status: v.status,
          },
        },
      }).then((res) => {
        if (res.data?.updateBlogCategory) {
          onSubmitSuccess();
        } else {
          message.error('Update Tag không thành công');
        }
      });
    } else {
      createBlogCategory({
        variables: {
          data: {
            name: v.name,
            description: v.description,
            slug: v.slug,
            nameForSeo: v.nameForSeo,
            descriptionForSeo: v.descriptionForSeo,
            status: v.status,
          },
        },
      }).then((res) => {
        if (res.data?.createBlogCategory) {
          onSubmitSuccess();
        } else {
          message.error('Tạo Tag không thành công');
        }
      });
    }
  };

  const handleCreateTagName = (v: any) => {
    const name = v.target.value;
    const slug = form.getFieldValue('slug');
    if (!slug) {
      const currentFormValues = form.getFieldsValue();
      form.setFieldsValue({
        ...currentFormValues,
        slug: convertToSlug(name),
      });
    }
  };

  const convertToSlug = (name: string) => {
    let slug = name.toLowerCase();
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');

    return slug.replace(/[^\w ]+/g, '').replace(/ +/g, '-');
  };

  return (
    <>
      <PageHeader title="Quản lý danh mục" breadcrumb={{ routes }} />
      <div style={{ margin: '10px 20px 10px 20px' }}>
        <ZTable<BlogTag>
          bordered
          size="small"
          columns={columns}
          gql={GetBlogCategoriesDocument}
          delete_gql={DeleteBlogCategoryDocument}
          primaryKey="id"
          tableRef={tableRef as any}
          schema="getBlogCategories"
          allowEdit={true}
          allowDelete={true}
          actionRight={false}
          filters={filter}
          onEdit={(record) => {
            setCurrentBlogCategory(record);
            setModalVisible(true);
          }}
          extraBtns={[
            <Button
              type="primary"
              key="add"
              onClick={() => {
                setModalVisible(true);
                form.setFieldsValue({ status: BlogTagStatus.Published });
              }}
            >
              <PlusOutlined /> Tạo mới
            </Button>,
          ]}
        />

        <Modal
          okButtonProps={{ loading: createLoading || updateLoading }}
          cancelButtonProps={{ loading: createLoading || updateLoading }}
          width={800}
          maskClosable={false}
          visible={modalVisible}
          title={currentBlogCategory ? 'Cập nhật danh mục ' : 'Tạo mới danh mục'}
          okText="Lưu"
          cancelText="Hủy"
          onOk={() => {
            form.validateFields().then(onFormSubmit);
          }}
          onCancel={() => {
            setCurrentBlogCategory(undefined);
            setModalVisible(false);
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Row>
              <Col md={24}>
                <Form.Item name="name" label="Tên" rules={[{ required: true, message: REQUIRE_MESSAGE }]}>
                  <Input placeholder="Tên danh mục" onBlur={(e) => handleCreateTagName(e)} />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  name="slug"
                  label="Đường dẫn (slug)"
                  rules={[
                    { required: true, message: REQUIRE_MESSAGE },
                    { pattern: RegExp('^[a-z0-9-_]+$'), message: SLUG_INVALID_MESSAGE },
                  ]}
                >
                  <Input placeholder="Đường dẫn (slug)" />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item name="description" label="Mô tả">
                  <TextArea rows={2} placeholder="Mô tả" />
                </Form.Item>
              </Col>
            </Row>
            <Collapse accordion>
              <Panel header="Tối ưu hóa bộ máy tìm kiếm (SEO)" key="1">
                <Row>
                  <Col md={24}>
                    <Form.Item name="nameForSeo" label="Tiêu đề trang">
                      <Input placeholder="Tiêu đề trang" />
                    </Form.Item>
                    <Form.Item name="descriptionForSeo" label="">
                      <TextArea rows={2} placeholder="Mô tả trang" />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>

            <Row style={{ marginTop: 20 }}>
              <Col md={6}>
                <Form.Item name="status" label="Trạng thái" rules={[{ required: true }]}>
                  <Select>
                    <Option value={BlogTagStatus.Published}>Đã xuất bản</Option>
                    <Option value={BlogTagStatus.Draft}>Bản nháp</Option>
                    <Option value={BlogTagStatus.Pending}>Đang chờ xử lý</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default BlogCategoryMain;
