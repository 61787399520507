import { getSDK } from '@services/graphql-client';
import { message } from 'antd';
import { call, put } from 'redux-saga/effects';
import {
  UpdateOrganizationAction,
  UpdateOrganizationActionError,
  UpdateOrganizationActionSuccess,
} from '../action-types';
import { updateOrganizationActionError, updateOrganizationActionSuccess } from '../actions/update';

export function* updateOrganizationSaga(action: UpdateOrganizationAction) {
  try {
    const skd = getSDK();
    const res: boolean = yield call(skd.updateOrganizationInfo, action.payload);
    yield put(updateOrganizationActionSuccess(res));
  } catch (error) {
    yield put(updateOrganizationActionError(error));
  }
}

export function updateOrganizationSuccessSaga(action: UpdateOrganizationActionSuccess) {
  message.success('Cập nhật thành công');
}

export function updateOrganizationErrorSaga(action: UpdateOrganizationActionError) {
  message.error('Có lỗi, vui lòng thử lại');
}
