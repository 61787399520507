import {
  CreateBlog,
  CreateBlogDocument,
  CreateBlogVariables,
  GetBlog,
  GetBlogDocument,
  GetBlogVariables,
  UpdateBlog,
  UpdateBlogDocument,
  UpdateBlogVariables,
} from '@/graphql/generated/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';

export const useGetBlog = () => {
  return useLazyQuery<GetBlog, GetBlogVariables>(GetBlogDocument);
};

export const useCreatBlog = () => {
  return useMutation<CreateBlog, CreateBlogVariables>(CreateBlogDocument);
};

export const useUpdateBlog = () => {
  return useMutation<UpdateBlog, UpdateBlogVariables>(UpdateBlogDocument);
};
