import { combineReducers } from 'redux';
import list, { GetTutorialsState } from './list';
import upload, { UploadState } from './upload';
import create, { CreateTutorialState } from './create';
import update, { UpdateTutorialState } from './update';
import deleteTutorial, { DeleteTutorialState } from './delete';

export interface TutorialModuleState {
  list: GetTutorialsState;
  upload: UploadState;
  create: CreateTutorialState;
  update: UpdateTutorialState;
  delete: DeleteTutorialState;
}

export default combineReducers<TutorialModuleState>({
  list,
  upload,
  create,
  update,
  delete: deleteTutorial,
});
