import {
  CreateBlogStory,
  CreateBlogStoryDocument,
  CreateBlogStoryVariables,
  GetBlogStoriesDocument,
  UpdateBlogStory,
  UpdateBlogStoryDocument,
  UpdateBlogStoryVariables,
} from '@/graphql/generated/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GetBlogStories, GetBlogStoriesVariables } from '@/graphql/generated/graphql';

export const useGetBlogStories = () => {
  return useLazyQuery<GetBlogStories, GetBlogStoriesVariables>(GetBlogStoriesDocument);
};

export const useCreateBlogStory = () => {
  return useMutation<CreateBlogStory, CreateBlogStoryVariables>(CreateBlogStoryDocument);
};

export const useUpdateBlogStory = () => {
  return useMutation<UpdateBlogStory, UpdateBlogStoryVariables>(UpdateBlogStoryDocument);
};
