import { call, put } from 'redux-saga/effects';
import { uploadPhotoError, uploadPhotoSuccess } from '../actions/upload-photo';
import { UploadPhotoAction } from '../action-types';
import { getSDK } from '@services/graphql-client';

export function* uploadPhotoSaga(action: UploadPhotoAction) {
  const sdk = getSDK();
  try {
    const res = yield call(sdk.singleUploadMutation, action.payload);
    yield put(uploadPhotoSuccess(res.singleUpload));
  } catch (error) {
    yield put(uploadPhotoError(error));
  }
}
