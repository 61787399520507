import { UpdateTutorialMutationVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdateTutorialActionTypes,
  UPDATE_TUTORIAL,
  UPDATE_TUTORIAL_ERROR,
  UPDATE_TUTORIAL_SUCCESS,
} from '../action-types';

export const updateTutorial = (payload: UpdateTutorialMutationVariables): UpdateTutorialActionTypes => ({
  type: UPDATE_TUTORIAL,
  payload,
});

export const updateTutorialError = (payload: AppError): UpdateTutorialActionTypes => ({
  type: UPDATE_TUTORIAL_ERROR,
  payload,
});

export const updateTutorialSuccess = (payload: boolean): UpdateTutorialActionTypes => ({
  type: UPDATE_TUTORIAL_SUCCESS,
  payload,
});
