import { combineReducers } from 'redux';
import list, { ListOrganizationState } from './list';
import update, { UpdateOrganizationState } from './update';
import changeStatus, { ChangeOrganizationStatusState } from './change-status';

export interface OrganizationModuleState {
  list: ListOrganizationState;
  update: UpdateOrganizationState;
  changeStatus: ChangeOrganizationStatusState;
}

export default combineReducers<OrganizationModuleState>({
  list,
  update,
  changeStatus,
});
