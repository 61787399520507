import { File } from '@/graphql/generated/graphql';
import { UploadPhotoActionTypes, UPLOAD_PHOTO, UPLOAD_PHOTO_ERROR, UPLOAD_PHOTO_SUCCESS } from '../action-types';

export interface UploadState {
  loading: boolean;
  isSuccess: boolean;
  visible: boolean;
  data?: File;
}

const initialState = {
  visible: false,
  loading: false,
  isSuccess: false,
};

export default function uploadPhoto(state: UploadState = initialState, action: UploadPhotoActionTypes): UploadState {
  switch (action.type) {
    case UPLOAD_PHOTO:
      return {
        ...state,
        visible: true,
        loading: true,
        isSuccess: false,
      };

    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case UPLOAD_PHOTO_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
