import { combineReducers } from 'redux';
import auth, { AuthModuleState } from '@modules/Auth/redux/reducers';
import user, { UserModuleState } from '@modules/User/redux/reducers';
import organization, { OrganizationModuleState } from '@modules/Organization/redux/reducers';
import tutorial, { TutorialModuleState } from '@modules/Tutorial/redux/reducers';

export interface RootState {
  auth: AuthModuleState;
  user: UserModuleState;
  organization: OrganizationModuleState;
  tutorial: TutorialModuleState;
}

export default combineReducers<RootState>({
  auth,
  user,
  organization,
  tutorial,
});
