import React from 'react';
import logoText from '@assets/images/logo-text.png';
import logoTextWhite from '@assets/images/logo-text-white.png';
import logoTextBlack from '@assets/images/logo-text-black.png';
import logoOnly from '@assets/images/logo.png';
import logoOnlyWhite from '@assets/images/logo-white.png';
import logoOnlyBlack from '@assets/images/logo-black.png';
import env from '@/env';
import { Link } from 'react-router-dom';
import { CommonPath } from '@commons/base-routes';

type Size = 'small' | 'default';
type Theme = 'default' | 'white' | 'black';

interface LogoInterface {
  theme?: Theme;
  size?: Size;
}

interface PropsInterface extends LogoInterface {
  collapsed: boolean;
}

export const LogoIcon = (props: LogoInterface) => {
  let logo;
  // const size = props.size || 'default';
  const theme = props.theme || 'default';
  switch (theme) {
    case 'default':
      logo = logoOnly;
      break;
    case 'white':
      logo = logoOnlyWhite;
      break;
    case 'black':
      logo = logoOnlyBlack;
      break;
    default:
      logo = logoOnly;
      break;
  }

  return (
    <Link to={CommonPath.DEFAULT_PATH}>
      <img
        src={logo}
        alt={env.pageTitle}
        className="mw-100 logo"
        // style={{
        //   height: size === 'default' ? '50px' : '35px',
        //   paddingLeft: size === 'default' ? '15px' : '18px',
        // }}
      />
    </Link>
  );
};

export const LogoFull = (props: LogoInterface) => {
  let logo;
  // const size = props.size || 'default';
  const theme = props.theme || 'default';
  switch (theme) {
    case 'default':
      logo = logoText;
      break;
    case 'white':
      logo = logoTextWhite;
      break;
    case 'black':
      logo = logoTextBlack;
      break;
    default:
      logo = logoText;
      break;
  }

  return (
    <Link to={CommonPath.DEFAULT_PATH}>
      <img
        src={logo}
        alt={env.pageTitle}
        className="mw-100 logo"
        // style={{
        //   height: size === 'default' ? '50px' : '35px',
        //   paddingLeft: size === 'default' ? '15px' : '18px',
        // }}
      />
    </Link>
  );
};

export const Logo = (props: PropsInterface) => {
  return !props.collapsed ? <LogoFull {...props} /> : <LogoIcon {...props} />;
};
