import { getSDK } from '@services/graphql-client';
import { call, put } from 'redux-saga/effects';
import { DeleteTutorialAction } from '../action-types';
import { deleteTutorialError, deleteTutorialSuccess } from '../actions/delete';

export function* deleteTutorialSaga(action: DeleteTutorialAction) {
  const sdk = getSDK();
  try {
    const res = yield call(sdk.deleteTutorialMutation, action.payload);
    yield put(deleteTutorialSuccess(res));
  } catch (error) {
    yield put(deleteTutorialError(error));
  }
}
