import { ListUser } from '@/graphql/generated/graphql';
import { ListUserActionTypes, LIST_USER, LIST_USER_ERROR, LIST_USER_SUCCESS, LIST_USER_RESET } from '../action-types';

export interface ListUserState {
  loading: boolean;
  data?: ListUser['users'];
}

const initialState = {
  loading: false,
};

export default function listUser(state: ListUserState = initialState, action: ListUserActionTypes): ListUserState {
  switch (action.type) {
    case LIST_USER_RESET:
      return initialState;

    case LIST_USER:
      return {
        ...state,
        loading: true,
      };

    case LIST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.users,
      };
    case LIST_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
