import { protectedComponent } from '@hoc/protectedComponent';
import BlogPage from './pages/Blog/index';
import CreateBlogPage from './pages/Blog/CreateBlog';
import UpdateBlogPage from './pages/Blog/UpdateBlog';
import BlogTagPage from './pages/Tag/index';
import BlogCategoryPage from './pages/Category/index';
import BlogStoryPage from './pages/BlogStory/index';

export const BlogRouter = [
  {
    path: '/blog-tags',
    exact: true,
    component: protectedComponent(BlogTagPage),
  },
  {
    path: '/blog-stories',
    exact: true,
    component: protectedComponent(BlogStoryPage),
  },
  {
    path: '/blog-categories',
    exact: true,
    component: protectedComponent(BlogCategoryPage),
  },
  {
    path: '/blogs',
    exact: true,
    component: protectedComponent(BlogPage),
  },
  {
    path: '/blogs/create',
    exact: true,
    component: protectedComponent(CreateBlogPage),
  },
  {
    path: '/blogs/update/:id',
    exact: true,
    component: protectedComponent(UpdateBlogPage),
  },
];
