import { UpdateTutorialMutationVariables } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTutorial } from '../redux/actions';

const useUpdateTutorial = () => {
  const loading = useSelector<RootState, boolean>((state) => state.tutorial.update.loading);
  const isSuccess = useSelector<RootState, boolean>((state) => state.tutorial.update.isSuccess);
  const dispatch = useDispatch();
  const onUpdate = useCallback((data: UpdateTutorialMutationVariables) => {
    dispatch(updateTutorial(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onUpdate,
    loading,
    isSuccess,
  };
};

export default useUpdateTutorial;
