import { call, put } from 'redux-saga/effects';
import { updateTutorialError, updateTutorialSuccess } from '../actions/update';
import { UpdateTutorialAction } from '../action-types';
import { getSDK } from '@services/graphql-client';

export function* updateTutorialSaga(action: UpdateTutorialAction) {
  const sdk = getSDK();
  try {
    const res = yield call(sdk.updateTutorialMutation, action.payload);
    yield put(updateTutorialSuccess(res));
  } catch (error) {
    yield put(updateTutorialError(error));
  }
}
