import { SingleUploadMutationVariables, File } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UPLOAD_PHOTO, UPLOAD_PHOTO_ERROR, UPLOAD_PHOTO_SUCCESS } from '../action-types';

export const uploadPhoto = (payload: SingleUploadMutationVariables) => ({
  type: UPLOAD_PHOTO,
  payload,
});

export const uploadPhotoSuccess = (payload: File) => ({
  type: UPLOAD_PHOTO_SUCCESS,
  payload,
});

export const uploadPhotoError = (payload: AppError) => ({
  type: UPLOAD_PHOTO_ERROR,
  payload,
});
