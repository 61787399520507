import { GetOrganizations } from '@/graphql/generated/graphql';
import { getSDK } from '@services/graphql-client';
import { call, put } from 'redux-saga/effects';
import { ListOrganizationAction } from '../action-types';
import { getOrganizationActionError, getOrganizationActionSuccess } from '../actions/list';

export function* getOrganizationsSaga(action: ListOrganizationAction) {
  try {
    const skd = getSDK();
    const res: GetOrganizations = yield call(skd.getOrganizations, action.payload);
    yield put(getOrganizationActionSuccess(res));
  } catch (error) {
    yield put(getOrganizationActionError(error));
  }
}
