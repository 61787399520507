export const BlogStatusLable: {
  [key: string]: { color: string; text: string };
} = {
  PUBLISHED: {
    color: 'green',
    text: 'Đã xuất bản',
  },
  DRAFT: {
    color: '#928989',
    text: 'Bản nháp',
  },
  PENDING: {
    color: 'blue',
    text: 'Đang chờ xử lý',
  },
};

export const REQUIRE_MESSAGE = 'Vui lòng nhập trường này';
export const SLUG_INVALID_MESSAGE = 'Vui lòng chỉ nhập các ký tự không dấu từ a-z, số, gạch ngang và gạch dưới';
