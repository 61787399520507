import { getSDK } from '@services/graphql-client';
import { call, put } from 'redux-saga/effects';
import { GetTutorialsAction } from '../action-types';
import { getTutorialsError, getTutorialsSuccess } from '../actions/list';

export function* getTutorialsSaga(action: GetTutorialsAction) {
  const skd = getSDK();
  try {
    const res = yield call(skd.getAllTutorial);
    yield put(getTutorialsSuccess(res.getAllTutorial));
  } catch (error) {
    yield put(getTutorialsError(error));
  }
}
