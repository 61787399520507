import { GetOrganizationsVariables, GetOrganizations } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const LIST_ORGANIZATION = 'LIST_ORGANIZATION';
export const LIST_ORGANIZATION_SUCCESS = 'LIST_ORGANIZATION_SUCCESS';
export const LIST_ORGANIZATION_ERROR = 'LIST_ORGANIZATION_ERROR';
export const LIST_ORGANIZATION_RESET = 'LIST_ORGANIZATION_RESET';

export interface ListOrganizationAction {
  type: typeof LIST_ORGANIZATION;
  payload: GetOrganizationsVariables;
}

export interface ListOrganizationActionSuccess {
  type: typeof LIST_ORGANIZATION_SUCCESS;
  payload: GetOrganizations;
}

export interface ListOrganizationActionError {
  type: typeof LIST_ORGANIZATION_ERROR;
  payload: AppError;
}

export interface ListOrganizationActionReset {
  type: typeof LIST_ORGANIZATION_RESET;
}

export type ListOrganizationActionTypes =
  | ListOrganizationAction
  | ListOrganizationActionSuccess
  | ListOrganizationActionError
  | ListOrganizationActionReset;
