import { SingleUploadMutationVariables, File } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_ERROR = 'UPLOAD_PHOTO_ERROR';

export interface UploadPhotoAction {
  type: typeof UPLOAD_PHOTO;
  payload: SingleUploadMutationVariables;
}

export interface UploadPhotoActionSuccess {
  type: typeof UPLOAD_PHOTO_SUCCESS;
  payload: File;
}

export interface UploadPhotoActionError {
  type: typeof UPLOAD_PHOTO_ERROR;
  payload: AppError;
}

export type UploadPhotoActionTypes = UploadPhotoAction | UploadPhotoActionSuccess | UploadPhotoActionError;
