import { UpdateOrganizationInfoVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const UPDATE_ORGANIZATION_RESET = 'UPDATE_ORGANIZATION_RESET';

export interface UpdateOrganizationAction {
  type: typeof UPDATE_ORGANIZATION;
  payload: UpdateOrganizationInfoVariables;
}

export interface UpdateOrganizationActionSuccess {
  type: typeof UPDATE_ORGANIZATION_SUCCESS;
  payload: boolean;
}

export interface UpdateOrganizationActionError {
  type: typeof UPDATE_ORGANIZATION_ERROR;
  payload: AppError;
}

export interface UpdateOrganizationActionReset {
  type: typeof UPDATE_ORGANIZATION_RESET;
}

export type UpdateOrganizationActionTypes =
  | UpdateOrganizationAction
  | UpdateOrganizationActionSuccess
  | UpdateOrganizationActionError
  | UpdateOrganizationActionReset;
