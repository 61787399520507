import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import { PickerProps } from 'antd/es/date-picker/generatePicker';

export const BaseDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export interface ZPickerProps extends Omit<PickerProps<Dayjs>, any> {
  className?: string;
  showTime?: boolean;
  format?: string;
  value?: any;
  disabledDate?: (value: Dayjs) => boolean;
  style?: any;
  onChange?: (value: any) => void;
}

const DatePicker = React.forwardRef<any, ZPickerProps>((props, ref) => {
  let { value } = props;
  if (value && typeof value === 'string') {
    value = dayjs(value);
  }
  return <BaseDatePicker {...props} value={value} ref={ref} />;
});

DatePicker.displayName = 'DatePicker';

export default DatePicker;
