import React, { useState } from 'react';
import { Col, Row, Upload, Image, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { SingleUpload, SingleUploadVariables, SingleUploadDocument } from '@/graphql/generated/graphql';
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface';

interface Props {
  value?: string;
  onChange?: (file: string) => void;
}

const SingleUploadImage = ({ value, onChange }: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadFile, { loading }] = useMutation<SingleUpload, SingleUploadVariables>(SingleUploadDocument);

  const uploadRequest = async (options: RcCustomRequestOptions) => {
    uploadFile({
      variables: {
        file: options.file,
      },
    }).then((res) => {
      if (res.data?.singleUpload) {
        options.onSuccess(res.data?.singleUpload, options.file);
        const newFile = [
          { uid: res.data.singleUpload.id, name: res.data.singleUpload.name, url: res.data.singleUpload.url },
        ] as any;
        setFileList(newFile);
        if (onChange) {
          onChange(res.data.singleUpload.url as string);
        }
      }
    });
  };

  React.useEffect(() => {
    if (value) {
      const filename = value.replace(/^.*[\\\/]/, '');
      const fileList = [{ uid: 1, name: filename, url: value }] as any;
      setFileList(fileList);
    } else {
      setFileList([]);
    }
  }, [value]);

  return (
    <Row>
      {loading && <Col md={24}>Đang upload...</Col>}
      <Col md={24}>
        {fileList.length > 0 && (
          <Image preview={true} style={{ border: '1px solid #ccc' }} width="80" height="auto" src={fileList[0].url} />
        )}
      </Col>

      <Col md={24} style={{ marginTop: 20 }}>
        <Upload
          name="file"
          multiple={false}
          accept="image/*"
          fileList={fileList}
          showUploadList={false}
          customRequest={uploadRequest}
        >
          <Button loading={loading} icon={<UploadOutlined />}>
            Upload ảnh title
          </Button>
        </Upload>
      </Col>
    </Row>
  );
};

export default SingleUploadImage;
