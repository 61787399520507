import { BlogCategory, BlogTag, BlogTagStatus } from '@/graphql/generated/graphql';
import {
  Form,
  Input,
  message,
  Row,
  Col,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Space,
  Card,
  Checkbox,
  InputNumber,
  Breadcrumb,
} from 'antd';
import React from 'react';
import { useUpdateBlog } from '../../hooks/blog';
import { REQUIRE_MESSAGE, SLUG_INVALID_MESSAGE } from '../../types';
import TextArea from 'antd/lib/input/TextArea';
import { Collapse } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useGetBlogCategories } from '@modules/Blog/hooks/category';
import { useGetBlogTags } from '@modules/Blog/hooks/tag';
import { useGetBlog } from '@modules/Blog/hooks/blog';
import Upload from '@modules/Blog/components/Form/Upload';
import RichText from '@modules/Blog/components/Form/RichText/RichText';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';
import PageHeader from '@commons/components/PageHeader';
import { useGetBlogStories } from '@modules/Blog/hooks/blogstory';

const { Panel } = Collapse;
const { Option } = Select;

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const routes = [
  {
    path: '/',
    breadcrumbName: 'Trang chủ',
  },
  {
    path: '/blogs',
    breadcrumbName: 'Bài viết',
  },
  {
    path: '',
    breadcrumbName: 'Cập nhật bài viết',
  },
];

function UpdateBlog() {
  const history = useHistory();
  const { id } = useParams<any>();
  const [form] = Form.useForm();
  const [updateBlog, { loading: createLoading }] = useUpdateBlog();
  const [getBlogCategories, { data: blogCategoryData }] = useGetBlogCategories();
  const [getBlogTags, { data: blogTagData }] = useGetBlogTags();
  const [getBlog, { data: blogData }] = useGetBlog();
  const [getBlogStories, { data: blogStoryData }] = useGetBlogStories();
  const ref = React.useRef<{ getData: () => any }>();

  React.useEffect(() => {
    getBlog({
      variables: {
        id: id,
      },
    });
    getBlogCategories({
      variables: {
        pageIndex: 1,
        pageSize: 500,
      },
    });
    getBlogTags({
      variables: {
        pageIndex: 1,
        pageSize: 500,
      },
    });
    getBlogStories({
      variables: {
        pageIndex: 1,
        pageSize: 500,
      },
    });
  }, []);

  React.useEffect(() => {
    if (blogData?.getBlog) {
      const publishDate = moment(blogData.getBlog.publishDate);
      let blog = {
        ...blogData.getBlog,
        publishedDate: publishDate,
        publishedTime: publishDate,
        blogCategoryIds: blogData.getBlog.blogCategories?.map((item: any) => item.id),
        blogTagIds: blogData.getBlog.blogTags?.map((item: any) => item.id),
      };
      form.setFieldsValue(blog);
    }
  }, [blogData]);

  const onSubmitSuccess = () => {
    message.success('cập nhật bài viết thành công');
    history.push('/blogs');
  };

  const onFormSubmit = (v: any) => {
    let publishDate = v.publishedDate.startOf('day');
    const diffMinutues = v.publishedTime.hour() * 60 + v.publishedTime.minutes();
    publishDate = publishDate.add(diffMinutues, 'minutes');
    const content = ref.current?.getData() || '';

    updateBlog({
      variables: {
        data: {
          id: id,
          title: v.title,
          titleImage: v.titleImage,
          shortDescription: v.shortDescription,
          slug: v.slug,
          order: v.order,
          content: content,
          publishDate: publishDate,
          blogCategoryIds: v.blogCategoryIds,
          blogTagIds: v.blogTagIds,
          titleForSeo: v.titleForSeo,
          shortDescriptionForSeo: v.shortDescriptionForSeo,
          status: v.status,
          storyId: v.storyId,
          storyPart: v.storyPart,
          titleImageAlt: v.titleImageAlt,
          titleImageCaption: v.titleImageCaption,
          titleImageDescription: v.titleImageDescription,
        },
      },
    }).then((res) => {
      if (res.data?.updateBlog) {
        onSubmitSuccess();
      } else {
        message.error('Cập nhật bài viết không thành công');
      }
    });
  };

  const onFromCancel = () => {
    history.push('/blogs');
  };

  const handleCreateBlogName = (v: any) => {
    const name = v.target.value;
    const slug = form.getFieldValue('slug');
    if (!slug) {
      const currentFormValues = form.getFieldsValue();
      form.setFieldsValue({
        ...currentFormValues,
        slug: convertToSlug(name),
      });
    }
  };

  const convertToSlug = (name: string) => {
    let slug = name.toLowerCase();
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');

    return slug.replace(/[^\w ]+/g, '').replace(/ +/g, '-');
  };

  const handleTagChange = (value: any) => {};

  return (
    <>
      <PageHeader title="Cập nhật bài viết" breadcrumb={{ routes }} />
      <div style={{ margin: '0px 20px 10px 20px' }}>
        <Form
          form={form}
          size="middle"
          layout="vertical"
          onFinish={() => {
            form.validateFields().then(onFormSubmit);
          }}
        >
          <Input type="hidden" name="id"></Input>
          <Row gutter={16}>
            <Col md={18}>
              <Space direction="vertical" style={{ width: '100%', marginTop: 16 }}>
                <Card size="small" title="" style={{ width: '100%' }}>
                  <Row style={{ backgroundColor: 'white' }}>
                    <Col md={24}>
                      <Form.Item name="title" label="Tên" rules={[{ required: true, message: REQUIRE_MESSAGE }]}>
                        <Input placeholder="Tên bài viết" onBlur={(e) => handleCreateBlogName(e)} />
                      </Form.Item>
                    </Col>
                    <Col md={24}>
                      <Form.Item
                        name="slug"
                        label="Đường dẫn (slug)"
                        rules={[
                          { required: true, message: REQUIRE_MESSAGE },
                          { pattern: RegExp('^[a-z0-9-_]+$'), message: SLUG_INVALID_MESSAGE },
                        ]}
                      >
                        <Input placeholder="Đường dẫn (slug)" />
                      </Form.Item>
                    </Col>
                    <Col md={24}>
                      <Form.Item name="shortDescription" label="Mô tả">
                        <TextArea rows={2} placeholder="Mô tả" />
                      </Form.Item>
                    </Col>
                    <Col md={24}>
                      <Form.Item name="content" label="Nội dung">
                        <RichText value={blogData?.getBlog?.content || ''} actionRef={ref} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card size="small" title="" style={{ width: '100%' }}>
                  <Collapse accordion>
                    <Panel header="Tối ưu hóa bộ máy tìm kiếm (SEO)" key="1">
                      <Row>
                        <Col md={24}>
                          <Form.Item name="titleForSeo" label="Tiêu đề trang">
                            <Input placeholder="Tiêu đề trang" />
                          </Form.Item>
                          <Form.Item name="shortDescriptionForSeo" label="">
                            <TextArea rows={2} placeholder="Mô tả trang" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Card>
              </Space>

              <Row>
                <Col md={24}></Col>
              </Row>
            </Col>
            <Col md={6}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Card size="small" title="Xuất bản" style={{ width: '100%' }}>
                  <Form.Item {...tailLayout} noStyle={true}>
                    <Button icon={<SaveOutlined />} type="primary" htmlType="submit" loading={createLoading}>
                      Lưu
                    </Button>
                    <Button
                      icon={<CloseOutlined />}
                      htmlType="button"
                      onClick={onFromCancel}
                      style={{ marginLeft: 20 }}
                    >
                      Hủy
                    </Button>
                  </Form.Item>
                </Card>
                <Card
                  size="small"
                  title={
                    <span>
                      <span
                        style={{ color: '#ff4d4f', fontSize: '14px', marginRight: 4, fontFamily: 'SimSun, sans-serif' }}
                      >
                        *
                      </span>
                      Ngày giờ xuất bản
                    </span>
                  }
                  style={{ width: '100%' }}
                >
                  <Row>
                    <Col md={6} xs={8}>
                      <strong>Ngày</strong>
                    </Col>
                    <Col md={18} xs={16}>
                      <Form.Item name="publishedDate" rules={[{ required: true, message: REQUIRE_MESSAGE }]}>
                        <DatePicker
                          placeholder="Chọn ngày xuất bản"
                          format="DD/MM/YYYY"
                          style={{ width: '100%' }}
                          // disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={8}>
                      <strong>Giờ</strong>
                    </Col>
                    <Col md={18} xs={16}>
                      <Form.Item
                        name="publishedTime"
                        noStyle={true}
                        rules={[{ required: true, message: REQUIRE_MESSAGE }]}
                      >
                        <TimePicker
                          placeholder="Chọn giờ xuất bản"
                          format="HH:mm"
                          style={{ width: '100%' }}
                          // disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card size="small" title="Thứ tự" style={{ width: '100%' }}>
                  <Form.Item name="order" label="" style={{ marginBottom: 0 }}>
                    <InputNumber min={0} style={{ width: '100%' }}></InputNumber>
                  </Form.Item>
                </Card>
                <Card
                  size="small"
                  title={
                    <span>
                      <span
                        style={{ color: '#ff4d4f', fontSize: '14px', marginRight: 4, fontFamily: 'SimSun, sans-serif' }}
                      >
                        *
                      </span>
                      Trạng Thái
                    </span>
                  }
                  style={{ width: '100%' }}
                >
                  <Form.Item name="status" label="" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                    <Select>
                      <Option value={BlogTagStatus.Published}>Đã xuất bản</Option>
                      <Option value={BlogTagStatus.Draft}>Bản nháp</Option>
                      <Option value={BlogTagStatus.Pending}>Đang chờ xử lý</Option>
                    </Select>
                  </Form.Item>
                </Card>
                <Card
                  size="small"
                  title={
                    <span>
                      <span
                        style={{ color: '#ff4d4f', fontSize: '14px', marginRight: 4, fontFamily: 'SimSun, sans-serif' }}
                      >
                        *
                      </span>
                      Danh mục
                    </span>
                  }
                  style={{ width: '100%' }}
                >
                  <Form.Item
                    name="blogCategoryIds"
                    label=""
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: REQUIRE_MESSAGE }]}
                  >
                    <Checkbox.Group>
                      <Row>
                        {blogCategoryData?.getBlogCategories?.items?.map((item, index) => {
                          return (
                            <Col span={24} key={index}>
                              <Checkbox value={item.id} style={{ lineHeight: '32px' }}>
                                {item.name}
                              </Checkbox>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Card>
                <Card size="small" title="Từ khóa (tag)" style={{ width: '100%' }}>
                  <Form.Item name="blogTagIds" label="" style={{ marginBottom: 0 }}>
                    <Select
                      mode="multiple"
                      placeholder="Chọn từ khóa (tag)"
                      showSearch={true}
                      filterOption={(input: string, option: any) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: '100%' }}
                      onChange={handleTagChange}
                      tokenSeparators={[',']}
                    >
                      {blogTagData?.getBlogTags?.items.map((item) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Card>
                <Card size="small" title="Chuyên mục" style={{ width: '100%' }}>
                  <Row>
                    <Col md={6} xs={24}>
                      Tên
                    </Col>
                    <Col md={18} xs={24}>
                      <Form.Item name="storyId" label="" style={{ marginBottom: 0 }}>
                        <Select
                          placeholder="Chọn chuyên mục"
                          showSearch={true}
                          filterOption={(input: string, option: any) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: '100%' }}
                        >
                          {blogStoryData?.getBlogStories?.items.map((item) => {
                            return <Option value={item.id}>{item.name}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col md={6} xs={24}>
                      Kỳ
                    </Col>
                    <Col md={18} xs={24}>
                      <Form.Item name="storyPart" label="" style={{ marginBottom: 0 }}>
                        <Input placeholder="Kỳ (Vd: Kỳ 1, Kỳ 2, Kỳ cuối)" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card
                  size="small"
                  title={
                    <span>
                      <span
                        style={{ color: '#ff4d4f', fontSize: '14px', marginRight: 4, fontFamily: 'SimSun, sans-serif' }}
                      >
                        *
                      </span>
                      Ảnh title
                    </span>
                  }
                  style={{ width: '100%' }}
                >
                  <Row>
                    <Col md={24}>
                      <Form.Item
                        label=""
                        name="titleImage"
                        style={{ marginBottom: 0 }}
                        rules={[{ required: true, message: REQUIRE_MESSAGE }]}
                      >
                        <Upload />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 20 }}>
                    <Col md={7} xs={24}>
                      Alt
                    </Col>
                    <Col md={17} xs={24}>
                      <Form.Item name="titleImageAlt" label="" style={{ marginBottom: 0 }}>
                        <Input placeholder="Image alt" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col md={7} xs={24}>
                      Caption
                    </Col>
                    <Col md={17} xs={24}>
                      <Form.Item name="titleImageCaption" label="" style={{ marginBottom: 0 }}>
                        <Input placeholder="Image caption" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col md={7} xs={24}>
                      Description
                    </Col>
                    <Col md={17} xs={24}>
                      <Form.Item name="titleImageDescription" label="" style={{ marginBottom: 0 }}>
                        <Input.TextArea rows={3} placeholder="Image description" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default UpdateBlog;
