import { BlogStory } from '@/graphql/generated/graphql';
import ZTable, { IColumnType, IFilterItem, ITableRef } from '@commons/ZTable/ZTable';
import { Form, Input, message, Button, Modal, Row, Col } from 'antd';
import React from 'react';
import { useCreateBlogStory, useUpdateBlogStory } from '../../hooks/blogstory';
import { GetBlogStoriesDocument, DeleteBlogStoryDocument } from '@/graphql/generated/graphql';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { REQUIRE_MESSAGE } from '../../types';
import PageHeader from '@commons/components/PageHeader';

const routes = [
  {
    path: '/',
    breadcrumbName: 'Trang chủ',
  },
  {
    path: '',
    breadcrumbName: 'Chuyên mục',
  },
];

const columns: IColumnType<BlogStory>[] = [
  {
    title: 'Tên',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    width: 100,
    render: (v) => {
      return moment(new Date(v ? v * 1000 : '')).format('DD/MM/YYYY HH:mm ');
    },
  },
];

function BlogStoryMain() {
  const [form] = Form.useForm();
  const tableRef = React.useRef<ITableRef>();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [currentBlogStory, setCurrentBlogStory] = React.useState<BlogStory>();

  const [createBlogStory, { loading: createLoading }] = useCreateBlogStory();
  const [updateBlogStory, { loading: updateLoading }] = useUpdateBlogStory();

  const filter: IFilterItem[] = React.useMemo(() => {
    const _filter: IFilterItem[] = [
      { label: '', placeholder: 'Tên chuyên mục', type: 'TEXT', name: 'query', mdSize: 5 },
    ];
    return _filter;
  }, []);

  React.useEffect(() => {
    if (currentBlogStory) {
      form.setFieldsValue({
        ...currentBlogStory,
      });
    }
  }, [currentBlogStory]);

  React.useEffect(() => {
    if (!modalVisible) {
      form.resetFields();
    }
  }, [modalVisible]);

  const onSubmitSuccess = () => {
    setCurrentBlogStory(undefined);
    setModalVisible(false);
    tableRef.current?.reload();
  };

  const onFormSubmit = (v: any) => {
    if (currentBlogStory) {
      updateBlogStory({
        variables: {
          data: {
            id: currentBlogStory.id,
            name: v.name,
          },
        },
      }).then((res) => {
        if (res.data?.updateBlogStory) {
          onSubmitSuccess();
        } else {
          message.error('Cập nhật Tag không thành công');
        }
      });
    } else {
      createBlogStory({
        variables: {
          data: {
            name: v.name,
          },
        },
      }).then((res) => {
        if (res.data?.createBlogStory) {
          onSubmitSuccess();
        } else {
          message.error('Tạo mới chuyên mục không thành công');
        }
      });
    }
  };

  return (
    <>
      <PageHeader title="Quản lý chuyên mục" breadcrumb={{ routes }} />
      <div style={{ margin: '10px 20px 10px 20px' }}>
        <ZTable<BlogStory>
          bordered
          size="small"
          columns={columns}
          gql={GetBlogStoriesDocument}
          delete_gql={DeleteBlogStoryDocument}
          primaryKey="id"
          tableRef={tableRef as any}
          schema="getBlogStories"
          allowEdit={true}
          allowDelete={true}
          actionRight={false}
          filters={filter}
          onEdit={(record) => {
            setCurrentBlogStory(record);
            setModalVisible(true);
          }}
          extraBtns={[
            <Button
              type="primary"
              key="add"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <PlusOutlined /> Tạo mới
            </Button>,
          ]}
        />

        <Modal
          okButtonProps={{ loading: createLoading || updateLoading }}
          cancelButtonProps={{ loading: createLoading || updateLoading }}
          maskClosable={false}
          visible={modalVisible}
          title={currentBlogStory ? 'Cập nhật chuyên mục' : 'Tạo mới chuyên mục'}
          okText="Lưu"
          cancelText="Hủy"
          onOk={() => {
            form.validateFields().then(onFormSubmit);
          }}
          onCancel={() => {
            setCurrentBlogStory(undefined);
            setModalVisible(false);
          }}
        >
          <Form form={form} size="middle" layout="vertical">
            <Row>
              <Col md={24}>
                <Form.Item name="name" label="Tên" rules={[{ required: true, message: REQUIRE_MESSAGE }]}>
                  <Input placeholder="Tên chuyên mục" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default BlogStoryMain;
