import {
  ChangeOrganizationStatusActionTypes,
  CHANGE_ORGANIZATION_STATUS,
  CHANGE_ORGANIZATION_STATUS_ERROR,
  CHANGE_ORGANIZATION_STATUS_RESET,
  CHANGE_ORGANIZATION_STATUS_SUCCESS,
} from '../action-types';

export interface ChangeOrganizationStatusState {
  loading: boolean;
  isSuccess: boolean;
}

const initialState = {
  loading: false,
  isSuccess: false,
};

export default function organizationOrganization(
  state: ChangeOrganizationStatusState = initialState,
  action: ChangeOrganizationStatusActionTypes,
): ChangeOrganizationStatusState {
  switch (action.type) {
    case CHANGE_ORGANIZATION_STATUS_RESET:
      return initialState;

    case CHANGE_ORGANIZATION_STATUS:
      return {
        ...state,
        loading: true,
      };

    case CHANGE_ORGANIZATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: action.payload,
      };
    case CHANGE_ORGANIZATION_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
