import type { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
  Mockup: any;
  FileBuffer: any;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOrganization: Organization;
  deleteOrganization: Scalars['Boolean'];
  updateOrganization: Scalars['Boolean'];
  deleteOrganizationInvitation: Scalars['Boolean'];
  updateProfile: Scalars['Boolean'];
  updateSignature: Scalars['Boolean'];
  updateOrganizationUser: Scalars['Boolean'];
  setIsActiveOrganizationUser: Scalars['Boolean'];
  transferOrganization: Scalars['Boolean'];
  changeOrganizationUserRole: Scalars['Boolean'];
  inviteToOrganization: InviteEmailPayload;
  inviteOutsiderToOrganization: InviteEmailPayload;
  resendOrganizationInvitation: Scalars['Boolean'];
  resendProjectInvitation: Scalars['Boolean'];
  inviteToProject: InviteEmailPayload;
  inviteOutsiderToProject: InviteEmailPayload;
  changeProjectUserRole: Scalars['Boolean'];
  addOrganizationUserToProject: Scalars['Boolean'];
  acceptInvitation: Scalars['Boolean'];
  changeProjectUserStatus: Scalars['Boolean'];
  changeOrganizationUserStatus: Scalars['Boolean'];
  clearNotifications: Scalars['Boolean'];
  clearAllNotification: Scalars['Boolean'];
  authenticateAccount?: Maybe<Scalars['Boolean']>;
  registerNotificationToken: Scalars['Boolean'];
  notificationSetting: Scalars['Boolean'];
  markNotificationsAsRead: Scalars['Boolean'];
  markNotificationAsRead: Scalars['Boolean'];
  register: Jwt;
  registerWithInvitationToken: Jwt;
  login: Jwt;
  logout: Scalars['Boolean'];
  refreshToken: Jwt;
  verifyEmail: Jwt;
  resendVerifyEmail: Scalars['String'];
  forgotPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  createProject: Project;
  deleteProject: Scalars['Boolean'];
  updateProjectInfo: Scalars['Boolean'];
  projectSetting: Scalars['Boolean'];
  archivedProject: Scalars['Boolean'];
  leaveProject: Scalars['Boolean'];
  copyProject: Scalars['Boolean'];
  createTag: Tag;
  updateTag: Scalars['Boolean'];
  deleteTag: Scalars['Boolean'];
  updateProjectLocation: Scalars['Boolean'];
  createFolder: Folder;
  updateFolderInfo?: Maybe<Scalars['Boolean']>;
  deleteFolder?: Maybe<Scalars['Boolean']>;
  createFileFolder: Folder;
  updateFileFolderInfo: Scalars['Boolean'];
  deleteFileFolder: Scalars['Boolean'];
  updateFileFolderUserAccess: Scalars['Boolean'];
  uploadPlan: Array<Plan>;
  uploadPlanVersion: PlanVersion;
  updatePlanInfo: Scalars['Boolean'];
  updatePlanVersionInfo: Scalars['Boolean'];
  deletePlans: Scalars['Boolean'];
  deletePlanVersion: Scalars['Boolean'];
  createTagWithPlans: Tag;
  addPlansTag: Scalars['Boolean'];
  removePlansTag: Scalars['Boolean'];
  movePlansToFolder: Scalars['Boolean'];
  changePlanVersionOrder: Scalars['Boolean'];
  rotatePlan: PlanVersion;
  uploadFile: File;
  uploadPhoto: File;
  deletePhotos: Scalars['Boolean'];
  deleteFiles: Scalars['Boolean'];
  uploadNewFileVersion: Scalars['Boolean'];
  moveFilesToFolder: Scalars['Boolean'];
  updateFileInfo: Scalars['Boolean'];
  singleUpload: File;
  createTask: Task;
  updateTaskInfo: Scalars['Boolean'];
  updateTaskFields: Scalars['Boolean'];
  changeTasksStatus: Scalars['Boolean'];
  moveTasksOffPlan: Scalars['Boolean'];
  moveTasksOnPlan: Scalars['Boolean'];
  deleteTasks: Scalars['Boolean'];
  unpinTaskFromPlan: Scalars['Boolean'];
  removeRelatedPlan: Scalars['Boolean'];
  createRelatedTask: Task;
  relateTask: Scalars['Boolean'];
  removeRelatedTask: Scalars['Boolean'];
  createTaskCheckItem: CheckItem;
  createTaskCheckList: Array<CheckItem>;
  updateTaskCheckItem: Scalars['Boolean'];
  deleteTaskCheckItem: Scalars['Boolean'];
  createTaskMessage: Message;
  updateTaskMessageInfo: Scalars['Boolean'];
  deleteTaskMessage: Scalars['Boolean'];
  uploadTaskMessageFile: Message;
  addTaskMessageFile: Message;
  addTasksTag: Scalars['Boolean'];
  createTagWithTasks: Tag;
  removeTasksTag: Scalars['Boolean'];
  duplicateTask: Task;
  createCheckListTemplate: CheckListTemplate;
  updateCheckListTemplate: CheckListTemplate;
  updateCheckListTemplateInfo: CheckListTemplate;
  deleteCheckListTemplate: Scalars['Boolean'];
  addCheckListToTask: Array<CheckItem>;
  createCategory: Category;
  updateCategoryInfo: Scalars['Boolean'];
  deleteCategory: Scalars['Boolean'];
  createLocation?: Maybe<Location>;
  importLocations?: Maybe<ImportApiResponse>;
  updateLocation?: Maybe<Scalars['Boolean']>;
  deleteLocation?: Maybe<Scalars['Boolean']>;
  deleteLocations: Scalars['Boolean'];
  createCompany: Company;
  updateCompany: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  updateMockup: Scalars['Boolean'];
  editPlanLink: Scalars['Boolean'];
  updateHyperLinkInfo: Scalars['Boolean'];
  deleteHyperLink: Scalars['Boolean'];
  uploadHyperLinkAttachment: HyperLink;
  uploadHyperLinkPhoto: HyperLink;
  createHyperLink: HyperLink;
  addHyperLinkAttachment: Scalars['Boolean'];
  deleteHyperLinkPhoto: Scalars['Boolean'];
  changeTaskPosition: Scalars['Boolean'];
  changeHyperLinkPosition: Scalars['Boolean'];
  adminLogin: AdminJwt;
  admin_refreshToken: AdminJwt;
  admin_ChangeOrganizationStatus?: Maybe<Scalars['Boolean']>;
  admin_updateOrganizationInfo?: Maybe<Scalars['Boolean']>;
  admin_createAdminUser: AdminUser;
  admin_logout: Scalars['Boolean'];
  admin_deleteAdminUser: Scalars['Boolean'];
  admin_updateAdminUserPassword: Scalars['Boolean'];
  admin_updateUserPassword: Scalars['Boolean'];
  admin_deleteOrganization: Scalars['Boolean'];
  admin_createTutorial: Tutorial;
  admin_updateTutorial: Scalars['Boolean'];
  admin_deleteTutorial: Scalars['Boolean'];
  createProjectItem: ProjectItem;
  updateProjectItem: Scalars['Boolean'];
  deleteProjectItem: Scalars['Boolean'];
  deleteProjectItems: Scalars['Boolean'];
  importProjectItems?: Maybe<ImportApiResponse>;
  createMeasurementUnit: MeasurementUnit;
  updateMeasurementUnit: Scalars['Boolean'];
  deleteMeasurementUnit: Scalars['Boolean'];
  importMeasurementUnits?: Maybe<ImportApiResponse>;
  createMaterial: Material;
  updateMaterial: Scalars['Boolean'];
  deleteMaterial: Scalars['Boolean'];
  importMaterials?: Maybe<ImportApiResponse>;
  createEquipment: Equipment;
  updateEquipment: Scalars['Boolean'];
  deleteEquipment: Scalars['Boolean'];
  importEquipments?: Maybe<ImportApiResponse>;
  createProjectManpower: ProjectManpower;
  updateProjectManpower: Scalars['Boolean'];
  deleteProjectManpower: Scalars['Boolean'];
  importProjectManpowers?: Maybe<ImportApiResponse>;
  createProjectItemReport: ProjectItemReport;
  updateProjectItemReport: Scalars['Boolean'];
  deleteProjectItemReport: Scalars['Boolean'];
  submitProjectItemReport: Scalars['Boolean'];
  approveProjectItemReport: Scalars['Boolean'];
  rejectProjectItemReport: Scalars['Boolean'];
  exportProjectItemReport: Scalars['FileBuffer'];
  copyProjectItemReports?: Maybe<Array<Maybe<ProjectItemReport>>>;
  exportQuantityCompletedReports?: Maybe<Scalars['FileBuffer']>;
  createBOQItem: BoqItem;
  importBOQItems?: Maybe<ImportApiResponse>;
  updateBOQItem?: Maybe<Scalars['Boolean']>;
  deleteBOQItem?: Maybe<Scalars['Boolean']>;
  deleteBOQItems?: Maybe<Scalars['Boolean']>;
  exportBOQItemTemplate?: Maybe<Scalars['FileBuffer']>;
  exportBOQItemMaximumQuantityFile?: Maybe<Scalars['FileBuffer']>;
  importBOQItemMaximumQuantity?: Maybe<ImportApiResponse>;
  createEquipmentReport: EquipmentReport;
  updateEquipmentReport: Scalars['Boolean'];
  deleteEquipmentReport: Scalars['Boolean'];
  submitEquipmentReport: Scalars['Boolean'];
  approveEquipmentReport: Scalars['Boolean'];
  rejectEquipmentReport: Scalars['Boolean'];
  copyEquipmentReports?: Maybe<Array<EquipmentReport>>;
  createMaterialReport: MaterialReport;
  updateMaterialReport: Scalars['Boolean'];
  deleteMaterialReport: Scalars['Boolean'];
  submitMaterialReport: Scalars['Boolean'];
  approveMaterialReport: Scalars['Boolean'];
  rejectMaterialReport: Scalars['Boolean'];
  copyMaterialReports?: Maybe<Array<Maybe<MaterialReport>>>;
  createManpowerReport: ManpowerReport;
  updateManpowerReport: Scalars['Boolean'];
  deleteManpowerReport: Scalars['Boolean'];
  submitManpowerReport: Scalars['Boolean'];
  approveManpowerReport: Scalars['Boolean'];
  rejectManpowerReport: Scalars['Boolean'];
  exportManpowerReportByWorker: Scalars['FileBuffer'];
  exportManpowerReportByWorkingHour: Scalars['FileBuffer'];
  copyManpowerReports?: Maybe<Array<ManpowerReport>>;
  createDCRWorkReport: DcrWorkReport;
  updateDCRWorkReport: Scalars['Boolean'];
  deleteDCRWorkReport?: Maybe<Scalars['Boolean']>;
  saveDCRNote: Scalars['Boolean'];
  saveDCRChecklist: Scalars['Boolean'];
  saveDCRImage: Scalars['Boolean'];
  exportDcrPdf: DcrExportPdf;
  copyDcrReport: Scalars['Boolean'];
  createBlogCategory: BlogCategory;
  updateBlogCategory: Scalars['Boolean'];
  deleteBlogCategory: Scalars['Boolean'];
  createBlogTag: BlogTag;
  updateBlogTag: Scalars['Boolean'];
  deleteBlogTag: Scalars['Boolean'];
  createBlog: Blog;
  updateBlog: Scalars['Boolean'];
  deleteBlog: Scalars['Boolean'];
  createBlogStory: BlogStory;
  updateBlogStory: Scalars['Boolean'];
  deleteBlogStory: Scalars['Boolean'];
};

export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};

export type MutationDeleteOrganizationArgs = {
  data: DeleteOrganizationInput;
};

export type MutationUpdateOrganizationArgs = {
  data: UpdateOrganizationInput;
};

export type MutationDeleteOrganizationInvitationArgs = {
  data: DeleteOrgInvitationInput;
};

export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};

export type MutationUpdateSignatureArgs = {
  signature: Scalars['String'];
};

export type MutationUpdateOrganizationUserArgs = {
  data: UpdateOrganizationUserInput;
};

export type MutationSetIsActiveOrganizationUserArgs = {
  data: SetIsActiveOrganizationUserInput;
};

export type MutationTransferOrganizationArgs = {
  data: TransferOrganizationInput;
};

export type MutationChangeOrganizationUserRoleArgs = {
  data: ChangeOrganizationUserRoleInput;
};

export type MutationInviteToOrganizationArgs = {
  organizationId: Scalars['ID'];
  emailList: Array<SendUserInviteOrganizationInput>;
};

export type MutationInviteOutsiderToOrganizationArgs = {
  organizationId: Scalars['ID'];
  emailList: Array<SendOutsiderInviteOrganizationInput>;
};

export type MutationResendOrganizationInvitationArgs = {
  data: ResendOrganizationInvitationInput;
};

export type MutationResendProjectInvitationArgs = {
  data: ResendProjectInvitationInput;
};

export type MutationInviteToProjectArgs = {
  projectId: Scalars['ID'];
  emailList: Array<SendUserInviteProjectInput>;
};

export type MutationInviteOutsiderToProjectArgs = {
  projectId: Scalars['ID'];
  emailList: Array<SendOutsiderInviteProjectInput>;
};

export type MutationChangeProjectUserRoleArgs = {
  data: ChangeProjectUserRoleInput;
};

export type MutationAddOrganizationUserToProjectArgs = {
  projectId: Scalars['ID'];
  data: Array<AddOrganizationUserToProjectInput>;
};

export type MutationAcceptInvitationArgs = {
  token: Scalars['String'];
};

export type MutationChangeProjectUserStatusArgs = {
  data: ChangeProjectUserStatusInput;
};

export type MutationChangeOrganizationUserStatusArgs = {
  data: ChangeOrganizationUserStatusInput;
};

export type MutationClearNotificationsArgs = {
  data: ClearNotificationsInput;
};

export type MutationAuthenticateAccountArgs = {
  data: AuthenticateAccountInput;
};

export type MutationRegisterNotificationTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type MutationNotificationSettingArgs = {
  isReceived: Scalars['Boolean'];
};

export type MutationMarkNotificationsAsReadArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['ID'];
};

export type MutationRegisterArgs = {
  data: MutationRegisterInput;
};

export type MutationRegisterWithInvitationTokenArgs = {
  data: MutationRegisterInput;
  token: Scalars['String'];
};

export type MutationLoginArgs = {
  data: MutationLoginInput;
};

export type MutationLogoutArgs = {
  refreshToken: Scalars['String'];
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationVerifyEmailArgs = {
  token: Scalars['String'];
};

export type MutationResendVerifyEmailArgs = {
  email: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};

export type MutationCreateProjectArgs = {
  data: CreateProjectInput;
};

export type MutationDeleteProjectArgs = {
  data: DeleteProjectInput;
};

export type MutationUpdateProjectInfoArgs = {
  data: UpdateProjectInfoInput;
};

export type MutationProjectSettingArgs = {
  data: ProjectSettingInput;
};

export type MutationArchivedProjectArgs = {
  data: ArchivedProjectInput;
};

export type MutationLeaveProjectArgs = {
  data: LeaveProjectInput;
};

export type MutationCopyProjectArgs = {
  data: CopyProjectInput;
};

export type MutationCreateTagArgs = {
  data: CreateProjectTagInput;
};

export type MutationUpdateTagArgs = {
  data: UpdateProjectTagInput;
};

export type MutationDeleteTagArgs = {
  data: DeleteProjectTagInput;
};

export type MutationUpdateProjectLocationArgs = {
  data?: Maybe<ProjectLocationInput>;
};

export type MutationCreateFolderArgs = {
  data?: Maybe<CreateFolderInput>;
};

export type MutationUpdateFolderInfoArgs = {
  data: UpdateFolderInfoInput;
};

export type MutationDeleteFolderArgs = {
  data: DeleteFolderInput;
};

export type MutationCreateFileFolderArgs = {
  data: CreateFileFolderInput;
};

export type MutationUpdateFileFolderInfoArgs = {
  data: UpdateFileFolderInfoInput;
};

export type MutationDeleteFileFolderArgs = {
  data: DeleteFileFolderInput;
};

export type MutationUpdateFileFolderUserAccessArgs = {
  id: Scalars['ID'];
  userAccess: FileFolderUserAccess;
};

export type MutationUploadPlanArgs = {
  file: Scalars['Upload'];
  folderId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type MutationUploadPlanVersionArgs = {
  file: Scalars['Upload'];
  planId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type MutationUpdatePlanInfoArgs = {
  data: UpdatePlanInfoInput;
};

export type MutationUpdatePlanVersionInfoArgs = {
  data: UpdatePlanVersionInfoInput;
};

export type MutationDeletePlansArgs = {
  data: DeletePlansInput;
};

export type MutationDeletePlanVersionArgs = {
  data: DeletePlanVersionInput;
};

export type MutationCreateTagWithPlansArgs = {
  data: CreateTagInput;
};

export type MutationAddPlansTagArgs = {
  data: AddPlansTagInput;
};

export type MutationRemovePlansTagArgs = {
  data: RemovePlansTagInput;
};

export type MutationMovePlansToFolderArgs = {
  data: MovePlansToFolderInput;
};

export type MutationChangePlanVersionOrderArgs = {
  data: ChangePlanVersionOrderInput;
};

export type MutationRotatePlanArgs = {
  data: RotatePlanInput;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  projectId: Scalars['ID'];
  folderId: Scalars['ID'];
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationUploadPhotoArgs = {
  file: Scalars['Upload'];
  projectId: Scalars['ID'];
};

export type MutationDeletePhotosArgs = {
  data: DeletePhotosInput;
};

export type MutationDeleteFilesArgs = {
  data: DeleteFilesInput;
};

export type MutationUploadNewFileVersionArgs = {
  fileId: Scalars['ID'];
  folderId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type MutationMoveFilesToFolderArgs = {
  data: MoveFileFolderInput;
};

export type MutationUpdateFileInfoArgs = {
  data: UpdateFileInfoInput;
};

export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
  projectId?: Maybe<Scalars['ID']>;
};

export type MutationCreateTaskArgs = {
  data: CreateTaskInput;
};

export type MutationUpdateTaskInfoArgs = {
  data: UpdateTaskInfoInput;
};

export type MutationUpdateTaskFieldsArgs = {
  data: UpdateTaskFieldsInput;
};

export type MutationChangeTasksStatusArgs = {
  data: ChangeTasksStatusInput;
};

export type MutationMoveTasksOffPlanArgs = {
  data: MoveTasksOffPlanInput;
};

export type MutationMoveTasksOnPlanArgs = {
  data: MoveTasksOnPlanInput;
};

export type MutationDeleteTasksArgs = {
  data: DeleteTasksInput;
};

export type MutationUnpinTaskFromPlanArgs = {
  data: UnpinTaskFromPlanInput;
};

export type MutationRemoveRelatedPlanArgs = {
  data: RemoveRelatedPlanInput;
};

export type MutationCreateRelatedTaskArgs = {
  data: CreateRelatedTaskInput;
};

export type MutationRelateTaskArgs = {
  data: RelateTaskInput;
};

export type MutationRemoveRelatedTaskArgs = {
  data?: Maybe<RemoveRelatedTaskInput>;
};

export type MutationCreateTaskCheckItemArgs = {
  data: CreateTaskCheckItemInput;
};

export type MutationCreateTaskCheckListArgs = {
  data: CreateTaskCheckListInput;
};

export type MutationUpdateTaskCheckItemArgs = {
  data: UpdateTaskCheckItemInput;
};

export type MutationDeleteTaskCheckItemArgs = {
  data: DeleteTaskCheckItemInput;
};

export type MutationCreateTaskMessageArgs = {
  data: CreateTaskMessageInput;
};

export type MutationUpdateTaskMessageInfoArgs = {
  data: UpdateTaskMessageInfoInput;
};

export type MutationDeleteTaskMessageArgs = {
  data: DeleteTaskMessageInput;
};

export type MutationUploadTaskMessageFileArgs = {
  file: Scalars['Upload'];
  taskId: Scalars['ID'];
};

export type MutationAddTaskMessageFileArgs = {
  data: AddTaskMessageFileInput;
};

export type MutationAddTasksTagArgs = {
  data: AddTasksTagInput;
};

export type MutationCreateTagWithTasksArgs = {
  data?: Maybe<CreateTagWithTasksInput>;
};

export type MutationRemoveTasksTagArgs = {
  data: RemoveTasksTagInput;
};

export type MutationDuplicateTaskArgs = {
  data: DuplicateTaskInput;
};

export type MutationCreateCheckListTemplateArgs = {
  data: CreateCheckListTemplateInput;
};

export type MutationUpdateCheckListTemplateArgs = {
  data: UpdateCheckListTemplateInput;
};

export type MutationUpdateCheckListTemplateInfoArgs = {
  data: UpdateCheckListTemplateInfoInput;
};

export type MutationDeleteCheckListTemplateArgs = {
  data: DeleteCheckListTemplateInput;
};

export type MutationAddCheckListToTaskArgs = {
  data: AddCheckListToTaskInput;
};

export type MutationCreateCategoryArgs = {
  data: CreateCategoryInput;
};

export type MutationUpdateCategoryInfoArgs = {
  data: UpdateCategoryInfoInput;
};

export type MutationDeleteCategoryArgs = {
  data: DeleteCategoryInput;
};

export type MutationCreateLocationArgs = {
  data: CreateLocationInput;
};

export type MutationImportLocationsArgs = {
  file: Scalars['Upload'];
  projectId: Scalars['ID'];
};

export type MutationUpdateLocationArgs = {
  data: UpdateLocationInput;
};

export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteLocationsArgs = {
  projectId: Scalars['ID'];
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type MutationCreateCompanyArgs = {
  data: CreateCompanyInput;
};

export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
};

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateMockupArgs = {
  data: UpdateMockupInput;
};

export type MutationEditPlanLinkArgs = {
  data: EditPlanLinkInput;
};

export type MutationUpdateHyperLinkInfoArgs = {
  data: UpdateHyperLinkInfoInput;
};

export type MutationDeleteHyperLinkArgs = {
  data: DeleteHyperLinkInput;
};

export type MutationUploadHyperLinkAttachmentArgs = {
  file: Scalars['Upload'];
  hyperLinkId: Scalars['ID'];
};

export type MutationUploadHyperLinkPhotoArgs = {
  file: Scalars['Upload'];
  hyperLinkId: Scalars['ID'];
};

export type MutationCreateHyperLinkArgs = {
  data: CreateHyperLinkInput;
};

export type MutationAddHyperLinkAttachmentArgs = {
  data: AddHyperAttachmentInput;
};

export type MutationDeleteHyperLinkPhotoArgs = {
  data: DeleteHypeLinkPhotoInput;
};

export type MutationChangeTaskPositionArgs = {
  data: ChangeTaskPositionInput;
};

export type MutationChangeHyperLinkPositionArgs = {
  data: ChangeHyperLinkPositionInput;
};

export type MutationAdminLoginArgs = {
  data: AdminLoginInput;
};

export type MutationAdmin_RefreshTokenArgs = {
  token: Scalars['String'];
};

export type MutationAdmin_ChangeOrganizationStatusArgs = {
  data: Admin_ChangeOrganizationStatus;
};

export type MutationAdmin_UpdateOrganizationInfoArgs = {
  data: Admin_UpdateOrganizationInfoInput;
};

export type MutationAdmin_CreateAdminUserArgs = {
  data: Admin_CreateAdminUserInput;
};

export type MutationAdmin_LogoutArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};

export type MutationAdmin_DeleteAdminUserArgs = {
  adminUserId: Scalars['ID'];
};

export type MutationAdmin_UpdateAdminUserPasswordArgs = {
  data: Admin_UpdateAdminUserPasswordInput;
};

export type MutationAdmin_UpdateUserPasswordArgs = {
  data: Admin_UpdateUserPasswordInput;
};

export type MutationAdmin_DeleteOrganizationArgs = {
  data: Admin_DeleteOrganizationInput;
};

export type MutationAdmin_CreateTutorialArgs = {
  data: CreateTutorialInput;
};

export type MutationAdmin_UpdateTutorialArgs = {
  id: Scalars['ID'];
  data: UpdateTutorialInput;
};

export type MutationAdmin_DeleteTutorialArgs = {
  id: Scalars['ID'];
};

export type MutationCreateProjectItemArgs = {
  data: CreateProjectItemInput;
};

export type MutationUpdateProjectItemArgs = {
  id: Scalars['ID'];
  data: UpdateProjectItemInput;
};

export type MutationDeleteProjectItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProjectItemsArgs = {
  projectId: Scalars['ID'];
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type MutationImportProjectItemsArgs = {
  projectId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type MutationCreateMeasurementUnitArgs = {
  data: CreateMeasurementUnitInput;
};

export type MutationUpdateMeasurementUnitArgs = {
  id: Scalars['ID'];
  data: UpdateMeasurementUnitInput;
};

export type MutationDeleteMeasurementUnitArgs = {
  id: Scalars['ID'];
};

export type MutationImportMeasurementUnitsArgs = {
  organizationId: Scalars['ID'];
  file?: Maybe<Scalars['Upload']>;
};

export type MutationCreateMaterialArgs = {
  data: CreateMaterialInput;
};

export type MutationUpdateMaterialArgs = {
  id: Scalars['ID'];
  data: UpdateMaterialInput;
};

export type MutationDeleteMaterialArgs = {
  id: Scalars['ID'];
};

export type MutationImportMaterialsArgs = {
  organizationId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type MutationCreateEquipmentArgs = {
  data: CreateEquipmentInput;
};

export type MutationUpdateEquipmentArgs = {
  id: Scalars['ID'];
  data: UpdateEquipmentInput;
};

export type MutationDeleteEquipmentArgs = {
  id: Scalars['ID'];
};

export type MutationImportEquipmentsArgs = {
  organizationId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type MutationCreateProjectManpowerArgs = {
  data: CreateProjectManpowerInput;
};

export type MutationUpdateProjectManpowerArgs = {
  id: Scalars['ID'];
  data: UpdateProjectManpowerInput;
};

export type MutationDeleteProjectManpowerArgs = {
  id: Scalars['ID'];
};

export type MutationImportProjectManpowersArgs = {
  projectId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type MutationCreateProjectItemReportArgs = {
  data: CreateProjectItemReportInput;
};

export type MutationUpdateProjectItemReportArgs = {
  id: Scalars['ID'];
  data: UpdateProjectItemReportInput;
};

export type MutationDeleteProjectItemReportArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitProjectItemReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationApproveProjectItemReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationRejectProjectItemReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationExportProjectItemReportArgs = {
  projectId: Scalars['ID'];
  fromDate: Scalars['Int'];
  toDate: Scalars['Int'];
};

export type MutationCopyProjectItemReportsArgs = {
  projectId: Scalars['ID'];
  date: Scalars['Int'];
};

export type MutationExportQuantityCompletedReportsArgs = {
  projectId: Scalars['ID'];
};

export type MutationCreateBoqItemArgs = {
  data: CreateBoqInput;
};

export type MutationImportBoqItemsArgs = {
  file: Scalars['Upload'];
  projectId: Scalars['ID'];
};

export type MutationUpdateBoqItemArgs = {
  data: UpdateBoqInput;
};

export type MutationDeleteBoqItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBoqItemsArgs = {
  projectId: Scalars['ID'];
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type MutationExportBoqItemTemplateArgs = {
  projectId: Scalars['ID'];
};

export type MutationExportBoqItemMaximumQuantityFileArgs = {
  projectId: Scalars['ID'];
};

export type MutationImportBoqItemMaximumQuantityArgs = {
  file: Scalars['Upload'];
  projectId: Scalars['ID'];
};

export type MutationCreateEquipmentReportArgs = {
  data: CreateEquipmentReportInput;
};

export type MutationUpdateEquipmentReportArgs = {
  id: Scalars['ID'];
  data: UpdateEquipmentReportInput;
};

export type MutationDeleteEquipmentReportArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitEquipmentReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationApproveEquipmentReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationRejectEquipmentReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationCopyEquipmentReportsArgs = {
  projectId: Scalars['ID'];
  date: Scalars['Int'];
};

export type MutationCreateMaterialReportArgs = {
  data: CreateMaterialReportInput;
};

export type MutationUpdateMaterialReportArgs = {
  id: Scalars['ID'];
  data: UpdateMaterialReportInput;
};

export type MutationDeleteMaterialReportArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitMaterialReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationApproveMaterialReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationRejectMaterialReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationCopyMaterialReportsArgs = {
  projectId: Scalars['ID'];
  date: Scalars['Int'];
};

export type MutationCreateManpowerReportArgs = {
  data: CreateManpowerReportInput;
};

export type MutationUpdateManpowerReportArgs = {
  id: Scalars['ID'];
  data: UpdateManpowerReportInput;
};

export type MutationDeleteManpowerReportArgs = {
  id: Scalars['ID'];
};

export type MutationSubmitManpowerReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationApproveManpowerReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationRejectManpowerReportArgs = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type MutationExportManpowerReportByWorkerArgs = {
  projectId: Scalars['ID'];
  fromDate: Scalars['Int'];
  toDate: Scalars['Int'];
};

export type MutationExportManpowerReportByWorkingHourArgs = {
  projectId: Scalars['ID'];
  fromDate: Scalars['Int'];
  toDate: Scalars['Int'];
};

export type MutationCopyManpowerReportsArgs = {
  projectId: Scalars['ID'];
  date: Scalars['Int'];
};

export type MutationCreateDcrWorkReportArgs = {
  data: CreateDcrWorkReportInput;
};

export type MutationUpdateDcrWorkReportArgs = {
  data: UpdateDcrWorkReportInput;
};

export type MutationDeleteDcrWorkReportArgs = {
  id: Scalars['ID'];
};

export type MutationSaveDcrNoteArgs = {
  data: SaveDcrNoteInput;
};

export type MutationSaveDcrChecklistArgs = {
  data: SaveDcrChecklistInput;
};

export type MutationSaveDcrImageArgs = {
  data: SaveDcrImageInput;
};

export type MutationExportDcrPdfArgs = {
  data: ExportDcrPdfInput;
};

export type MutationCopyDcrReportArgs = {
  data: CopyDcrReportInput;
};

export type MutationCreateBlogCategoryArgs = {
  data: CreateBlogCategoryInput;
};

export type MutationUpdateBlogCategoryArgs = {
  data: UpdateBlogCategoryInput;
};

export type MutationDeleteBlogCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationCreateBlogTagArgs = {
  data: CreateBlogTagInput;
};

export type MutationUpdateBlogTagArgs = {
  data: UpdateBlogTagInput;
};

export type MutationDeleteBlogTagArgs = {
  id: Scalars['ID'];
};

export type MutationCreateBlogArgs = {
  data?: Maybe<CreateBlogInput>;
};

export type MutationUpdateBlogArgs = {
  data?: Maybe<UpdateBlogInput>;
};

export type MutationDeleteBlogArgs = {
  id: Scalars['ID'];
};

export type MutationCreateBlogStoryArgs = {
  data: CreateBlogStoryInput;
};

export type MutationUpdateBlogStoryArgs = {
  data: UpdateBlogStoryInput;
};

export type MutationDeleteBlogStoryArgs = {
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  getOrganization: Organization;
  getOrganizations: Organizations;
  getOrganizationUsers: OrganizationUsers;
  getOrganizationUser: OrganizationUser;
  getProjectUsers: Array<ProjectUser>;
  getProjectUser: ProjectUser;
  getInvitationInformation: InvitationInformation;
  getFilterUsers: Array<OrganizationUser>;
  getNotifications: Array<Notification>;
  getAllNotification?: Maybe<Notifications>;
  getNoticesNumbers: Scalars['Int'];
  getAccountAuthenticationInfo: AuthenticationAccountInfo;
  getOrganizationUserNotInProject: Array<OrganizationUser>;
  getAllTutorial: Array<Tutorial>;
  getCurrentOrganizationUser?: Maybe<OrganizationUser>;
  getCurrentProjectUser?: Maybe<ProjectUser>;
  me?: Maybe<User>;
  getProjects: Projects;
  getProject: Project;
  getProjectQuickSettingInfo: Project;
  getTags: Array<Tag>;
  projectGlobalFilter: Array<GlobalFilter>;
  getProjectLocation?: Maybe<ProjectLocationDetail>;
  getFolders: Array<Folder>;
  getFileFolders: Array<Folder>;
  getSelectedPlansTags: Array<Tag>;
  getAllTags: Array<Tag>;
  filterPlansByTags: Array<Folder>;
  getCurrentPlanVersion: PlanVersion;
  getPlanVersions: Array<PlanVersion>;
  getPlans: Array<Maybe<Plan>>;
  getPlansByFile: Array<Plan>;
  getPlanVersion?: Maybe<PlanVersion>;
  getNearestPlans: NearestPlans;
  filterAttachments: Files;
  filterPhotos: Array<File>;
  getPhotos: Array<File>;
  getBasicInfoTasks: Tasks;
  getBasicInfoTasksByCategory: Tasks;
  getSelectedTasksTags: Array<Tag>;
  getTask: Task;
  getBasicInfoTasksByPlan: Array<Task>;
  getLifeTimeTasksReport: Array<LifeTimeTasksReport>;
  getInProgressTasksReport: Array<InProgressTasksReport>;
  getCheckListTemplates: Array<CheckListTemplate>;
  getCheckListTemplate: CheckListTemplate;
  mobileFilterTasks?: Maybe<Tasks>;
  getCategories: Array<Category>;
  getLocations: Locations;
  getLocationsByParent: Locations;
  getLocation: Location;
  getLocationsParent: Locations;
  getCompanies: Companies;
  getHyperLinks: Array<HyperLink>;
  admin_getUsers: Users;
  admin_getOrganizations?: Maybe<Organizations>;
  getProjectItems: ProjectItems;
  getProjectItemsByParent: ProjectItems;
  getProjectItemsParent: ProjectItems;
  getMeasurementUnits: MeasurementUnits;
  getMaterials: Materials;
  getEquipments: Equipments;
  getProjectManpowers: ProjectManpowers;
  getProjectItemReports: ProjectItemReports;
  getProjectItemReport?: Maybe<ProjectItemReport>;
  getProjectItemReportLogging: Array<ProjectItemReportLogging>;
  getBOQItems: BoqItems;
  getBOQItem: BoqItem;
  getEquipmentReports: EquipmentReports;
  getEquipmentReport: EquipmentReport;
  getEquipmentReportLogging: Array<EquipmentReportLogging>;
  getMaterialReports: MaterialReports;
  getMaterialReport: MaterialReport;
  getMaterialReportLogging: Array<MaterialReportLogging>;
  getManpowerReports: ManpowerReports;
  getManpowerReport: ManpowerReport;
  getManpowerReportLogging: Array<ManpowerReportLogging>;
  getBOQLocationMaxQuantity?: Maybe<BoqLocationMaxQuantity>;
  getDCRWorkReports: DcrWorkReports;
  getDCRNotes: Array<DcrNote>;
  getDCRChecklist: Array<DcrChecklist>;
  getDCRChecklistQuestions: Array<Maybe<DcrChecklistQuestions>>;
  getDCRImages: Array<DcrImage>;
  getBlogCategories: BlogCategories;
  getBlogCategory: BlogCategory;
  getBlogTags: BlogTags;
  getBlogTag: BlogTag;
  getBlogs?: Maybe<Blogs>;
  getBlog?: Maybe<Blog>;
  getBlogStories: BlogStories;
};

export type QueryGetOrganizationArgs = {
  data: QueryOrganizationInput;
};

export type QueryGetOrganizationsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<OrganizationFilterInput>;
};

export type QueryGetOrganizationUsersArgs = {
  data: QueryOrganizationUsersInput;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<OrganizationUsersFilterInput>;
};

export type QueryGetOrganizationUserArgs = {
  data: QueryOrganizationUserInput;
};

export type QueryGetProjectUsersArgs = {
  data: QueryProjectUsersInput;
};

export type QueryGetProjectUserArgs = {
  id: Scalars['ID'];
};

export type QueryGetInvitationInformationArgs = {
  token: Scalars['String'];
};

export type QueryGetFilterUsersArgs = {
  data: GetFilterUsersInput;
};

export type QueryGetAllNotificationArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<NotificationsFilterInput>;
};

export type QueryGetAccountAuthenticationInfoArgs = {
  token: Scalars['String'];
};

export type QueryGetOrganizationUserNotInProjectArgs = {
  projectId: Scalars['ID'];
  isOutsider?: Maybe<Scalars['Boolean']>;
};

export type QueryGetCurrentOrganizationUserArgs = {
  organizationId: Scalars['ID'];
};

export type QueryGetCurrentProjectUserArgs = {
  projectId: Scalars['ID'];
};

export type QueryGetProjectsArgs = {
  data: QueryProjectsInput;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProjectsFilterInput>;
  orderBy?: Maybe<ProjectOrderByInput>;
};

export type QueryGetProjectArgs = {
  data: QueryProjectInput;
};

export type QueryGetProjectQuickSettingInfoArgs = {
  data: QueryProjectQuickSettingInput;
};

export type QueryGetTagsArgs = {
  data: GetTagsInput;
};

export type QueryProjectGlobalFilterArgs = {
  projectId: Scalars['ID'];
  size?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type QueryGetProjectLocationArgs = {
  projectId: Scalars['ID'];
};

export type QueryGetFoldersArgs = {
  data: QueryFoldersInput;
};

export type QueryGetFileFoldersArgs = {
  data: GetFileFoldersInput;
};

export type QueryGetSelectedPlansTagsArgs = {
  data: QuerySelectedPlanTagsInput;
};

export type QueryGetAllTagsArgs = {
  data: QueryAllTagsInput;
};

export type QueryFilterPlansByTagsArgs = {
  data: QueryFilterPlansByTagsInput;
};

export type QueryGetCurrentPlanVersionArgs = {
  data: QueryCurrentPlanVersionInput;
};

export type QueryGetPlanVersionsArgs = {
  data: QueryPlanVersionInput;
};

export type QueryGetPlansArgs = {
  data: QueryPlansInput;
};

export type QueryGetPlansByFileArgs = {
  data: GetPlansByFileInput;
};

export type QueryGetPlanVersionArgs = {
  data: GetPlanVersionInput;
};

export type QueryGetNearestPlansArgs = {
  currentPlanId: Scalars['ID'];
};

export type QueryFilterAttachmentsArgs = {
  data: QueryAttachmentsInput;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterAttachmentsInput>;
};

export type QueryFilterPhotosArgs = {
  data: FilterPhotosInput;
};

export type QueryGetPhotosArgs = {
  data: GetPhotosInput;
};

export type QueryGetBasicInfoTasksArgs = {
  data: GetBasicInfoTasks;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<TasksFilterInput>;
};

export type QueryGetBasicInfoTasksByCategoryArgs = {
  data: GetBasicInfoTasksByCategory;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
};

export type QueryGetSelectedTasksTagsArgs = {
  data?: Maybe<GetSelectedTasksTagsInput>;
};

export type QueryGetTaskArgs = {
  data: GetTaskInput;
};

export type QueryGetBasicInfoTasksByPlanArgs = {
  data: GetBasicInfoTaskByPlanInput;
};

export type QueryGetLifeTimeTasksReportArgs = {
  data: ReportTasksInput;
};

export type QueryGetInProgressTasksReportArgs = {
  data: ReportTasksInput;
};

export type QueryGetCheckListTemplatesArgs = {
  data: GetTemplatesInput;
};

export type QueryGetCheckListTemplateArgs = {
  data: GetTemplateInput;
};

export type QueryMobileFilterTasksArgs = {
  data: GetBasicInfoTasks;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<TasksFilterInput>;
};

export type QueryGetCategoriesArgs = {
  data: QueryCategoryInput;
};

export type QueryGetLocationsArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  isParent?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  boqItemId?: Maybe<Scalars['ID']>;
  orderBy?: Maybe<LocationOrderByInput>;
};

export type QueryGetLocationsByParentArgs = {
  parentId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<LocationOrderByInput>;
};

export type QueryGetLocationArgs = {
  id: Scalars['ID'];
};

export type QueryGetLocationsParentArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<LocationOrderByInput>;
};

export type QueryGetCompaniesArgs = {
  organizationId?: Maybe<Scalars['ID']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<CompanyOrderByInput>;
};

export type QueryGetHyperLinksArgs = {
  data: QueryHyperLinksInput;
};

export type QueryAdmin_GetUsersArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<Admin_UsersFilterInput>;
};

export type QueryAdmin_GetOrganizationsArgs = {
  types: Array<OrganizationStatus>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<Admin_OrganizationsFilterInput>;
};

export type QueryGetProjectItemsArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  isParent?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  orderBy?: Maybe<ProjectItemOrderByInput>;
  query?: Maybe<Scalars['String']>;
};

export type QueryGetProjectItemsByParentArgs = {
  parentId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<ProjectItemOrderByInput>;
};

export type QueryGetProjectItemsParentArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<ProjectItemOrderByInput>;
};

export type QueryGetMeasurementUnitsArgs = {
  organizationId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MeasurementUnitOrderByInput>;
  filter?: Maybe<MeasurementUnitFilterInput>;
};

export type QueryGetMaterialsArgs = {
  organizationId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MaterialOrderByInput>;
  filter?: Maybe<MaterialFilterInput>;
};

export type QueryGetEquipmentsArgs = {
  organizationId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<EquipmentOrderByInput>;
  filter?: Maybe<EquipmentFilterInput>;
};

export type QueryGetProjectManpowersArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProjectManpowerOrderByInput>;
  filter?: Maybe<ProjectManpowerFilterInput>;
};

export type QueryGetProjectItemReportsArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReportOrderByInput>;
  filter?: Maybe<ProjectItemReportFilterInput>;
};

export type QueryGetProjectItemReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetProjectItemReportLoggingArgs = {
  reportId: Scalars['ID'];
};

export type QueryGetBoqItemsArgs = {
  projectId: Scalars['ID'];
  projectItemId?: Maybe<Scalars['ID']>;
  includedSubProjectItem?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<BoqOrderByInput>;
};

export type QueryGetBoqItemArgs = {
  id: Scalars['ID'];
};

export type QueryGetEquipmentReportsArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReportOrderByInput>;
  filter?: Maybe<EquipmentReportFilterInput>;
};

export type QueryGetEquipmentReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetEquipmentReportLoggingArgs = {
  reportId: Scalars['ID'];
};

export type QueryGetMaterialReportsArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReportOrderByInput>;
  filter?: Maybe<MaterialReportFilterInput>;
};

export type QueryGetMaterialReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetMaterialReportLoggingArgs = {
  reportId: Scalars['ID'];
};

export type QueryGetManpowerReportsArgs = {
  projectId: Scalars['ID'];
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReportOrderByInput>;
  filter?: Maybe<ManpowerReportFilterInput>;
};

export type QueryGetManpowerReportArgs = {
  id: Scalars['ID'];
};

export type QueryGetManpowerReportLoggingArgs = {
  reportId: Scalars['ID'];
};

export type QueryGetBoqLocationMaxQuantityArgs = {
  boqItemId: Scalars['ID'];
  locationId?: Maybe<Scalars['ID']>;
};

export type QueryGetDcrWorkReportsArgs = {
  projectId: Scalars['ID'];
  type: DcrWorkReportType;
  projectItemId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
};

export type QueryGetDcrNotesArgs = {
  projectId: Scalars['ID'];
  type: DcrNoteType;
  userId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
};

export type QueryGetDcrChecklistArgs = {
  projectId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  question?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['ID']>;
};

export type QueryGetDcrChecklistQuestionsArgs = {
  projectId: Scalars['ID'];
};

export type QueryGetDcrImagesArgs = {
  projectId: Scalars['ID'];
  type: DcrImageType;
  userId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
};

export type QueryGetBlogCategoriesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogCategoryFilter>;
  orderBy?: Maybe<BlogCategoryOrderByInput>;
};

export type QueryGetBlogCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryGetBlogTagsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogTagFilter>;
  orderBy?: Maybe<BlogTagOrderByInput>;
};

export type QueryGetBlogTagArgs = {
  id: Scalars['ID'];
};

export type QueryGetBlogsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogFilter>;
  orderBy?: Maybe<BlogTagOrderByInput>;
};

export type QueryGetBlogArgs = {
  id: Scalars['ID'];
};

export type QueryGetBlogStoriesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogStoryFilter>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Photo>;
  logoId?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  owner?: Maybe<User>;
  projects?: Maybe<Array<Project>>;
  membersNumber?: Maybe<Scalars['Int']>;
  role?: Maybe<OrganizationRoleCodes>;
  status?: Maybe<OrganizationStatus>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OrganizationInfo = {
  __typename?: 'OrganizationInfo';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['ID']>;
};

export type Organizations = {
  __typename?: 'Organizations';
  items: Array<Organization>;
  paginate?: Maybe<Paginate>;
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DeleteOrganizationInput = {
  id: Scalars['ID'];
};

export type DeleteOrgInvitationInput = {
  organizationId: Scalars['ID'];
  invitationId: Scalars['ID'];
};

export type QueryOrganizationInput = {
  id: Scalars['ID'];
};

export type QueryOrganizationsInput = {
  projectsNumber: Scalars['Int'];
};

export type OrganizationFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  projectCreated: Project;
  projectDeleted: Array<Project>;
  projectUpdated: Project;
  onPlanProcessed: Plan;
  onPlanVersionProcessed: PlanVersion;
  onPushNotification?: Maybe<Notification>;
  noticesNumber: Scalars['Int'];
  userRemovedFromOrganization: Array<User>;
  userRemovedFromProject: Array<User>;
  userJoinedOrganization: Array<User>;
  userJoinedProject: Array<User>;
};

export type SubscriptionProjectCreatedArgs = {
  organizationId: Scalars['Int'];
};

export type SubscriptionProjectDeletedArgs = {
  organizationId: Scalars['Int'];
};

export type SubscriptionProjectUpdatedArgs = {
  organizationId: Scalars['Int'];
};

export type SubscriptionOnPlanProcessedArgs = {
  projectId: Scalars['ID'];
};

export type SubscriptionOnPlanVersionProcessedArgs = {
  planId: Scalars['ID'];
};

export type SubscriptionOnPushNotificationArgs = {
  userId: Scalars['ID'];
};

export type SubscriptionNoticesNumberArgs = {
  userId: Scalars['ID'];
};

export type SubscriptionUserRemovedFromOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type SubscriptionUserRemovedFromProjectArgs = {
  projectId: Scalars['ID'];
};

export type SubscriptionUserJoinedOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type SubscriptionUserJoinedProjectArgs = {
  projectId: Scalars['ID'];
};

export type Notifications = {
  __typename?: 'Notifications';
  items: Array<Notification>;
  paginate: Paginate;
};

export type InviteEmailPayload = {
  __typename?: 'InviteEmailPayload';
  isAllSent: Scalars['Boolean'];
  message: Scalars['String'];
};

export type Tutorial = {
  __typename?: 'Tutorial';
  id: Scalars['ID'];
  url: Scalars['String'];
  title: Scalars['String'];
  thumbId?: Maybe<Scalars['Int']>;
  thumb?: Maybe<File>;
  sort: Scalars['Int'];
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
};

export type InvitationInformation = {
  __typename?: 'InvitationInformation';
  inviterInfo?: Maybe<UserInfo>;
  withRole: RoleCodes;
  email?: Maybe<Scalars['String']>;
  isUserRegistered: Scalars['Boolean'];
  invitationType: InvitationType;
  organizationInfo?: Maybe<OrganizationInfo>;
  projectInfo?: Maybe<ProjectInfo>;
};

export type AuthenticationAccountInfo = {
  __typename?: 'AuthenticationAccountInfo';
  organizationName: Scalars['String'];
  email: Scalars['String'];
};

export type NotificationData = {
  __typename?: 'NotificationData';
  projectId?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  notificationType?: Maybe<Scalars['String']>;
  ordinalNumber?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isEmailVerified: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  notificationToken?: Maybe<Scalars['String']>;
  interested?: Maybe<Scalars['String']>;
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
  isReceivedNotifications?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  utm?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ProjectUser = {
  __typename?: 'ProjectUser';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  organization?: Maybe<Organization>;
  organizationUser?: Maybe<OrganizationUser>;
  userId: Scalars['ID'];
  user?: Maybe<User>;
  projectId: Scalars['ID'];
  project?: Maybe<Project>;
  role?: Maybe<ProjectRoleCodes>;
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<ProjectUserStatusType>;
  isUserRegistered?: Maybe<Scalars['Boolean']>;
  isOutsider?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<OrganizationUserStatus>;
  isOutsider?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  userId?: Maybe<Scalars['ID']>;
  projectsParticipatedNumber?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  organizationId: Scalars['ID'];
  organization?: Maybe<Organization>;
  projectUser?: Maybe<ProjectUser>;
  role?: Maybe<OrganizationRoleCodes>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  taskId?: Maybe<Scalars['ID']>;
  task?: Maybe<Task>;
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  notificationType?: Maybe<NotificationType>;
  targetId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  ordinalNumber?: Maybe<Scalars['Int']>;
  hasRead?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type OrganizationUsers = {
  __typename?: 'OrganizationUsers';
  items: Array<OrganizationUser>;
  paginate?: Maybe<Paginate>;
};

export type UpdateProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationUserInput = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SendUserInviteOrganizationInput = {
  email: Scalars['String'];
  role: OrganizationRoleCodes;
};

export type SendUserInviteProjectInput = {
  email: Scalars['String'];
  role: ProjectRoleCodes;
};

export type SendOutsiderInviteOrganizationInput = {
  email: Scalars['String'];
  companyId: Scalars['ID'];
};

export type SendOutsiderInviteProjectInput = {
  email: Scalars['String'];
  companyId: Scalars['ID'];
  role: ProjectRoleCodes;
};

export type RemoveUserFromOrganizationInput = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type RemoveUserFromProjectInput = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ChangeOrganizationUserRoleInput = {
  id: Scalars['ID'];
  role: OrganizationRoleCodes;
};

export type ChangeProjectUserRoleInput = {
  forProjectUserId: Scalars['ID'];
  role: ProjectRoleCodes;
};

export type QueryOrganizationUsersInput = {
  organizationId: Scalars['ID'];
  isOutsider?: Maybe<Scalars['Boolean']>;
};

export type QueryOrganizationUserInput = {
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryProjectUsersInput = {
  projectId: Scalars['ID'];
  isOutsider?: Maybe<Scalars['Boolean']>;
};

export type TransferOrganizationInput = {
  toUserId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type OrganizationUsersFilterInput = {
  query: Scalars['String'];
};

export type SetIsActiveOrganizationUserInput = {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  status: OrganizationUserStatus;
};

export type ChangeProjectUserStatusInput = {
  status: ProjectUserStatusType;
  id: Scalars['ID'];
};

export type ChangeOrganizationUserStatusInput = {
  status: OrganizationUserStatus;
  id: Scalars['ID'];
};

export type ResendOrganizationInvitationInput = {
  email: Scalars['String'];
  organizationUserId: Scalars['ID'];
};

export type ResendProjectInvitationInput = {
  email: Scalars['String'];
  projectUserId: Scalars['ID'];
};

export type GetFilterUsersInput = {
  projectId: Scalars['ID'];
};

export type ClearNotificationsInput = {
  notificationIds: Array<Scalars['ID']>;
};

export type AuthenticateAccountInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type AddOrganizationUserToProjectInput = {
  userId: Scalars['ID'];
  role: ProjectRoleCodes;
};

export type GetCurrentOrganizationUserInput = {
  organizationId: Scalars['ID'];
};

export type NotificationsFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type Jwt = {
  __typename?: 'JWT';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  uid?: Maybe<Scalars['ID']>;
  expiresAt: Scalars['Int'];
  refreshTokenExpiresAt: Scalars['Int'];
  payload?: Maybe<User>;
};

export type MutationRegisterInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
};

export type MutationLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Paginate = {
  __typename?: 'Paginate';
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  pageSize: Scalars['Int'];
  totalItems?: Maybe<Scalars['Int']>;
};

export enum Roles {
  User = 'USER',
  Admin = 'ADMIN',
}

export enum PhotoType {
  Pdf = 'PDF',
  Photo = 'PHOTO',
}

export enum FileType {
  File = 'FILE',
  Photo = 'PHOTO',
  MessageFile = 'MESSAGE_FILE',
  MessagePhoto = 'MESSAGE_PHOTO',
}

export enum RoleCodes {
  OrgOwner = 'ORG_OWNER',
  OrgAdmin = 'ORG_ADMIN',
  OrgMember = 'ORG_MEMBER',
  ProjectAdmin = 'PROJECT_ADMIN',
  ProjectMember = 'PROJECT_MEMBER',
  ProjectFollower = 'PROJECT_FOLLOWER',
}

export enum OrganizationRoleCodes {
  OrgOwner = 'ORG_OWNER',
  OrgAdmin = 'ORG_ADMIN',
  OrgMember = 'ORG_MEMBER',
}

export enum ProjectRoleCodes {
  ProjectAdmin = 'PROJECT_ADMIN',
  ProjectMember = 'PROJECT_MEMBER',
  ProjectFollower = 'PROJECT_FOLLOWER',
}

export enum ErrorCodes {
  GraphqlParseFailed = 'GRAPHQL_PARSE_FAILED',
  GraphqlValidationFailed = 'GRAPHQL_VALIDATION_FAILED',
  Unauthenticated = 'UNAUTHENTICATED',
  Forbidden = 'FORBIDDEN',
  BadUserInput = 'BAD_USER_INPUT',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  TokenExpired = 'TOKEN_EXPIRED',
  InvalidToken = 'INVALID_TOKEN',
}

export enum OrganizationUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
}

export enum InvitationType {
  InviteProject = 'INVITE_PROJECT',
  InviteOrganization = 'INVITE_ORGANIZATION',
}

export enum ProjectUserStatusType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Manpower {
  ManHours = 'MAN_HOURS',
  ManDays = 'MAN_DAYS',
  ManMonths = 'MAN_MONTHS',
}

export enum ArchiveTasks {
  AlwaysActive = 'ALWAYS_ACTIVE',
  After_1Days = 'AFTER_1_DAYS',
  After_3Days = 'AFTER_3_DAYS',
  After_7Days = 'AFTER_7_DAYS',
  After_30Days = 'AFTER_30_DAYS',
  After_90Days = 'AFTER_90_DAYS',
  After_365Days = 'AFTER_365_DAYS',
  After_3650Days = 'AFTER_3650_DAYS',
}

export enum TimeZone {
  GmtPlus_7 = 'GMT_PLUS_7',
}

export enum Currency {
  Usd = 'USD',
  Vnd = 'VND',
}

export enum ChangeOrderType {
  Up = 'UP',
  Down = 'DOWN',
}

export enum HyperLinkType {
  PlanLink = 'PLAN_LINK',
  Photo = 'PHOTO',
  Attachment = 'ATTACHMENT',
}

export enum TaskStatus {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Verified = 'VERIFIED',
}

export enum TaskKind {
  PinTypeTask = 'PIN_TYPE_TASK',
  DiamondTask = 'DIAMOND_TASK',
  SquareTask = 'SQUARE_TASK',
}

export enum CheckListState {
  Empty = 'EMPTY',
  NotApplicable = 'NOT_APPLICABLE',
  Unchecked = 'UNCHECKED',
  Checked = 'CHECKED',
}

export enum TaskPriority {
  Priority_1 = 'PRIORITY_1',
  Priority_2 = 'PRIORITY_2',
  Priority_3 = 'PRIORITY_3',
}

export enum MessageType {
  Default = 'DEFAULT',
  Input = 'INPUT',
  File = 'FILE',
  Mention = 'MENTION',
}

export enum OrganizationStatus {
  Active = 'ACTIVE',
  AwaitForActive = 'AWAIT_FOR_ACTIVE',
  Canceled = 'CANCELED',
  Registered = 'REGISTERED',
}

export enum DateFilterBy {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export enum FileFolderUserAccess {
  AllUser = 'ALL_USER',
  AdminOnly = 'ADMIN_ONLY',
  AdminMember = 'ADMIN_MEMBER',
}

export enum ReportStatus {
  Draff = 'DRAFF',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export enum NotificationType {
  Task = 'TASK',
  ManpowerReport = 'MANPOWER_REPORT',
  ProjectItemReport = 'PROJECT_ITEM_REPORT',
  MaterialReport = 'MATERIAL_REPORT',
  EquipmentReport = 'EQUIPMENT_REPORT',
}

export enum ProjectCopyChecklist {
  PlanFolder = 'PLAN_FOLDER',
  FileFolder = 'FILE_FOLDER',
  Setting = 'SETTING',
  ProjectUser = 'PROJECT_USER',
  Tag = 'TAG',
  ChecklistTemplate = 'CHECKLIST_TEMPLATE',
  Manpower = 'MANPOWER',
  ProjectItem = 'PROJECT_ITEM',
}

export enum DcrWorkReportType {
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
  TomorrowInspection = 'TOMORROW_INSPECTION',
}

export enum DcrNoteType {
  Note = 'NOTE',
  Comment = 'COMMENT',
}

export enum DcrImageType {
  Title = 'TITLE',
  Attachment = 'ATTACHMENT',
}

export enum CopyDcrReportType {
  WorkReportToday = 'WORK_REPORT_TODAY',
  WorkReportTomorrow = 'WORK_REPORT_TOMORROW',
  WorkReportTomorrowInspection = 'WORK_REPORT_TOMORROW_INSPECTION',
  Checklist = 'CHECKLIST',
  Note = 'NOTE',
  Comment = 'COMMENT',
}

export enum BlogCategoryStatus {
  Published = 'PUBLISHED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
}

export enum BlogTagStatus {
  Published = 'PUBLISHED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
}

export enum BlogStatus {
  Published = 'PUBLISHED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
}

export enum GlobalFilterType {
  Plan = 'PLAN',
  Task = 'TASK',
  File = 'FILE',
}

export type ProjectLocationDetail = {
  __typename?: 'ProjectLocationDetail';
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type GlobalFilter = {
  __typename?: 'GlobalFilter';
  type?: Maybe<GlobalFilterType>;
  name: Scalars['String'];
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  relatedPlanId?: Maybe<Scalars['ID']>;
};

export type Projects = {
  __typename?: 'Projects';
  items: Array<Project>;
  paginate?: Maybe<Paginate>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  logo?: Maybe<Photo>;
  logoId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  timezone?: Maybe<TimeZone>;
  currency?: Maybe<Currency>;
  manpower?: Maybe<Manpower>;
  archiveTasks?: Maybe<ArchiveTasks>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  folders?: Maybe<Array<Maybe<Folder>>>;
  organizationId: Scalars['ID'];
  organization?: Maybe<Organization>;
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
  membersNumber?: Maybe<Scalars['Int']>;
  tasksNumber?: Maybe<Scalars['Int']>;
  plansNumber?: Maybe<Scalars['Int']>;
  adminNumber?: Maybe<Scalars['Int']>;
  role?: Maybe<RoleCodes>;
  userStatus?: Maybe<ProjectUserStatusType>;
  isMyProject?: Maybe<Scalars['Boolean']>;
};

export type ProjectInfo = {
  __typename?: 'ProjectInfo';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateProjectInput = {
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateProjectInfoInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type MutationRemoveUserFromProject = {
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type ProjectSettingInput = {
  projectId: Scalars['ID'];
  organizationId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  timezone?: Maybe<TimeZone>;
  currency?: Maybe<Currency>;
  manpower?: Maybe<Manpower>;
  archiveTasks?: Maybe<ArchiveTasks>;
  logoId?: Maybe<Scalars['ID']>;
};

export type DeleteProjectInvitationInput = {
  projectId: Scalars['ID'];
  invitationId: Scalars['ID'];
};

export type DeleteProjectInput = {
  id: Scalars['ID'];
};

export type QueryProjectInput = {
  id: Scalars['ID'];
};

export type QueryProjectsInput = {
  organizationId: Scalars['ID'];
};

export type ProjectsFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type ProjectOrderByInput = {
  name?: Maybe<Sort>;
  code?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type ArchivedProjectInput = {
  projectId: Scalars['ID'];
  isArchived: Scalars['Boolean'];
};

export type QueryProjectQuickSettingInput = {
  projectId: Scalars['ID'];
};

export type LeaveProjectInput = {
  projectId: Scalars['ID'];
};

export type CreateProjectTagInput = {
  projectId: Scalars['ID'];
  name: Scalars['String'];
  taskId?: Maybe<Scalars['ID']>;
};

export type UpdateProjectTagInput = {
  tagId: Scalars['ID'];
  name: Scalars['String'];
};

export type DeleteProjectTagInput = {
  tagId: Scalars['ID'];
};

export type GetTagsInput = {
  projectId: Scalars['ID'];
};

export type CopyProjectInput = {
  fromProjectId: Scalars['ID'];
  toProjectId: Scalars['ID'];
  copy: Array<ProjectCopyChecklist>;
};

export type ProjectLocationInput = {
  projectId: Scalars['ID'];
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  project?: Maybe<Project>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
  code: Scalars['String'];
};

export type QueryCategoryInput = {
  projectId: Scalars['ID'];
};

export type UpdateCategoryInfoInput = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type DeleteCategoryInput = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type Folder = {
  __typename?: 'Folder';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  userAccess?: Maybe<FileFolderUserAccess>;
  project?: Maybe<Project>;
  isAllPlans: Scalars['Boolean'];
  files?: Maybe<Array<Maybe<File>>>;
  plans?: Maybe<Array<Maybe<Plan>>>;
};

export type FolderFilesArgs = {
  filter?: Maybe<FilterFileInput>;
};

export type FolderPlansArgs = {
  tagIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  createdByIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateFolderInput = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

export type CreateFileFolderInput = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

export type QueryFoldersInput = {
  projectId: Scalars['ID'];
};

export type UpdatePlanFolderInput = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  planIds: Array<Scalars['ID']>;
};

export type UpdateFolderInfoInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateFileFolderInfoInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type GetFileFoldersInput = {
  projectId: Scalars['ID'];
};

export type DeleteFileFolderInput = {
  folderId: Scalars['ID'];
};

export type DeleteFolderInput = {
  folderId: Scalars['ID'];
};

export type DateRangeFilterInput = {
  dateFilterBy: DateFilterBy;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
};

export type FilterFileInput = {
  dateRange?: Maybe<DateRangeFilterInput>;
  uploadByIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
};

export type NearestPlans = {
  __typename?: 'NearestPlans';
  nextPlanId?: Maybe<Scalars['ID']>;
  currentPlanId?: Maybe<Scalars['ID']>;
  previousPlanId?: Maybe<Scalars['ID']>;
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nonAccentName?: Maybe<Scalars['String']>;
  planVersions?: Maybe<Array<PlanVersion>>;
  description?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  notes?: Maybe<Scalars['String']>;
  drawing?: Maybe<Scalars['Mockup']>;
  version?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  folderId?: Maybe<Scalars['ID']>;
  folder?: Maybe<Folder>;
  tagId?: Maybe<Scalars['ID']>;
  tag?: Maybe<Tag>;
  photoId?: Maybe<Scalars['ID']>;
  photo?: Maybe<Photo>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  processing?: Maybe<Scalars['Boolean']>;
  tasksNumber?: Maybe<Scalars['Int']>;
};

export type PlanVersion = {
  __typename?: 'PlanVersion';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['ID']>;
  photo?: Maybe<Photo>;
  planId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  drawing?: Maybe<Scalars['Mockup']>;
  project?: Maybe<Project>;
  plan?: Maybe<Plan>;
  sort?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  processing?: Maybe<Scalars['Boolean']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  inSelectedPlans?: Maybe<Scalars['Boolean']>;
  plans?: Maybe<Array<Maybe<Plan>>>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type QuerySelectedPlanTagsInput = {
  planIds: Array<Scalars['ID']>;
};

export type QueryAllTagsInput = {
  projectId: Scalars['ID'];
};

export type QueryFilterPlansByTags = {
  projectId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

export type DeletePlansInput = {
  projectId: Scalars['ID'];
  planIds: Array<Scalars['ID']>;
};

export type AddPlansTagInput = {
  planIds: Array<Scalars['ID']>;
  tagId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type CreateTagInput = {
  projectId: Scalars['ID'];
  name: Scalars['String'];
  planIds: Array<Scalars['ID']>;
};

export type RemovePlansTagInput = {
  planIds: Array<Scalars['ID']>;
  tagId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type MovePlansToFolderInput = {
  planIds: Array<Scalars['ID']>;
  folderId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type QueryFilterPlansByTagsInput = {
  projectId: Scalars['ID'];
};

export type QueryCurrentPlanVersionInput = {
  projectId?: Maybe<Scalars['ID']>;
  planId: Scalars['ID'];
};

export type UpdatePlanInfoInput = {
  projectId: Scalars['ID'];
  planId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdatePlanVersionInfoInput = {
  planId: Scalars['ID'];
  planVersionId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type DeletePlanVersionInput = {
  planId: Scalars['ID'];
  planVersionId: Scalars['ID'];
};

export type QueryPlanVersionInput = {
  planId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type ChangePlanVersionOrderInput = {
  planId: Scalars['ID'];
  planVersionId: Scalars['ID'];
  type?: Maybe<ChangeOrderType>;
};

export type QueryPlansInput = {
  projectId: Scalars['ID'];
};

export type GetPlansByFileInput = {
  fileId: Scalars['ID'];
};

export type GetPlanVersionInput = {
  planVersionId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type RotatePlanInput = {
  planVersionId: Scalars['ID'];
  rotate: Scalars['Int'];
};

export type Files = {
  __typename?: 'Files';
  items: Array<File>;
  paginate?: Maybe<Paginate>;
};

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  imageUrlFolder?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  thumbnail?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  uploadById?: Maybe<Scalars['ID']>;
  uploadBy?: Maybe<OrganizationUser>;
  type?: Maybe<PhotoType>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  urlHeight?: Maybe<Scalars['Int']>;
  urlWidth?: Maybe<Scalars['Int']>;
  mime?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nonAccentName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  thumbnail?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['ID']>;
  folder?: Maybe<Folder>;
  uploadById?: Maybe<Scalars['ID']>;
  uploadBy?: Maybe<OrganizationUser>;
  relatedPlans?: Maybe<Array<Maybe<Plan>>>;
  urlWidth?: Maybe<Scalars['Int']>;
  urlHeight?: Maybe<Scalars['Int']>;
  thumbWidth?: Maybe<Scalars['Int']>;
  thumbHeight?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  mime?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type QueryAttachmentsInput = {
  projectId: Scalars['ID'];
};

export type FilterAttachmentsInput = {
  query?: Maybe<Scalars['String']>;
};

export type GetPhotosInput = {
  projectId: Scalars['ID'];
};

export type FilterPhotosInput = {
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  uploadByIds?: Maybe<Array<Scalars['ID']>>;
  projectId: Scalars['ID'];
};

export type DeletePhotosInput = {
  fileIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type DeleteFilesInput = {
  fileIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type MoveFileFolderInput = {
  fileIds: Array<Scalars['ID']>;
  folderId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type UpdateFileInfoInput = {
  fileId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type HyperLink = {
  __typename?: 'HyperLink';
  id: Scalars['ID'];
  type: HyperLinkType;
  attachments?: Maybe<Array<Maybe<File>>>;
  planVersionId?: Maybe<Scalars['ID']>;
  planVersion?: Maybe<PlanVersion>;
  planLinkId?: Maybe<Scalars['ID']>;
  planLink?: Maybe<Plan>;
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  lastEditorId?: Maybe<Scalars['ID']>;
  lastEditor?: Maybe<OrganizationUser>;
  position?: Maybe<Scalars['Mockup']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateHyperLinkInput = {
  type: HyperLinkType;
  planVersionId: Scalars['ID'];
  position?: Maybe<Scalars['Mockup']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type EditPlanLinkInput = {
  hyperLinkId: Scalars['ID'];
  planLinkId: Scalars['ID'];
};

export type UpdateHyperLinkInfoInput = {
  hyperLinkId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type QueryHyperLinksInput = {
  planVersionId: Scalars['ID'];
};

export type DeleteHyperLinkInput = {
  hyperLinkId: Scalars['ID'];
};

export type UpdateMockupInput = {
  planVersionId: Scalars['ID'];
  drawing?: Maybe<Scalars['Mockup']>;
};

export type AddHyperAttachmentInput = {
  attachmentId: Scalars['ID'];
  hyperLinkId: Scalars['ID'];
};

export type DeleteHypeLinkPhotoInput = {
  attachmentId: Scalars['ID'];
  hyperLinkId: Scalars['ID'];
};

export type ChangeTaskPositionInput = {
  taskId: Scalars['ID'];
  position: Scalars['Mockup'];
};

export type ChangeHyperLinkPositionInput = {
  hyperLinkId: Scalars['ID'];
  position: Scalars['Mockup'];
};

export type LifeTimeTasksReport = {
  __typename?: 'LifeTimeTasksReport';
  todo: Scalars['Int'];
  inProgress: Scalars['Int'];
  completed: Scalars['Int'];
  verified: Scalars['Int'];
  date: Scalars['Int'];
};

export type InProgressTasksReport = {
  __typename?: 'InProgressTasksReport';
  openRate: Scalars['Int'];
  completed: Scalars['Int'];
  verified: Scalars['Int'];
  date: Scalars['Int'];
};

export type Tasks = {
  __typename?: 'Tasks';
  items?: Maybe<Array<Task>>;
  paginate?: Maybe<Paginate>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  status?: Maybe<TaskStatus>;
  kind?: Maybe<TaskKind>;
  priority?: Maybe<TaskPriority>;
  cost?: Maybe<Scalars['Int']>;
  ordinalNumber?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  relatedTasks?: Maybe<Array<Maybe<Task>>>;
  checkList?: Maybe<Array<Maybe<CheckItem>>>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  categoryId?: Maybe<Scalars['ID']>;
  category?: Maybe<Category>;
  assigneeId?: Maybe<Scalars['ID']>;
  assignee?: Maybe<OrganizationUser>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  relatedPlanId?: Maybe<Scalars['ID']>;
  relatedPlan?: Maybe<Plan>;
  position?: Maybe<Scalars['Mockup']>;
  manpower?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  watchers?: Maybe<Array<Maybe<OrganizationUser>>>;
};

export type CheckItem = {
  __typename?: 'CheckItem';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<CheckListState>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  taskId?: Maybe<Scalars['ID']>;
  task?: Maybe<Task>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CheckListTemplate = {
  __typename?: 'CheckListTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  projectId: Scalars['ID'];
  project?: Maybe<Project>;
  checkList?: Maybe<Array<CheckItem>>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  type?: Maybe<MessageType>;
  message?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  projectId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  taskId?: Maybe<Scalars['ID']>;
  task?: Maybe<Task>;
  ranges?: Maybe<Array<Maybe<Range>>>;
  fileId?: Maybe<Scalars['ID']>;
  file?: Maybe<File>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type Range = {
  __typename?: 'Range';
  mentionedId: Scalars['ID'];
  mentionedUser?: Maybe<OrganizationUser>;
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export type CreateTaskInput = {
  projectId: Scalars['ID'];
  kind?: Maybe<TaskKind>;
  name?: Maybe<Scalars['String']>;
  status: TaskStatus;
  locationId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['ID']>;
  assigneeId?: Maybe<Scalars['ID']>;
  relatedPlanId?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['Mockup']>;
  location?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  manpower?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  priority?: Maybe<TaskPriority>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  watcherIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateTaskInfoInput = {
  taskId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateTaskFieldsInput = {
  taskId: Scalars['ID'];
  status?: Maybe<TaskStatus>;
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  assigneeId?: Maybe<Scalars['ID']>;
  relatedPlanId?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  manpower?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  priority?: Maybe<TaskPriority>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  watcherIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ChangeTasksStatusInput = {
  projectId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
  status?: Maybe<TaskStatus>;
};

export type MoveTasksOffPlanInput = {
  projectId: Scalars['ID'];
  taskIds?: Maybe<Array<Scalars['ID']>>;
};

export type MoveTasksOnPlanInput = {
  projectId: Scalars['ID'];
  taskIds?: Maybe<Array<Scalars['ID']>>;
};

export type DeleteTasksInput = {
  projectId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type CreateRelatedTaskInput = {
  taskId: Scalars['ID'];
  name: Scalars['String'];
};

export type RelateTaskInput = {
  taskId: Scalars['ID'];
  toTaskId: Scalars['ID'];
};

export type CreateTaskCheckItemInput = {
  taskId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateTaskCheckListInput = {
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
  names: Array<Scalars['String']>;
};

export type UpdateTaskCheckItemInput = {
  checkItemId: Scalars['ID'];
  state?: Maybe<CheckListState>;
  name?: Maybe<Scalars['String']>;
};

export type DeleteTaskCheckItemInput = {
  checkItemId: Scalars['ID'];
};

export type ChangeCheckListStateInput = {
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
  checkItemId: Scalars['ID'];
  state: CheckListState;
};

export type CreateTaskMessageInput = {
  taskId: Scalars['ID'];
  message: Scalars['String'];
  mentionedIds?: Maybe<Array<Scalars['ID']>>;
  ranges?: Maybe<Array<RangeInput>>;
};

export type UpdateTaskMessageInfoInput = {
  taskMessageId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  ranges?: Maybe<Array<RangeInput>>;
};

export type DeleteTaskMessageInput = {
  taskMessageId: Scalars['ID'];
};

export type AddTaskMessageFileInput = {
  taskId: Scalars['ID'];
  fileId: Scalars['ID'];
};

export type GetBasicInfoTasks = {
  projectId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type GetBasicInfoTasksByCategory = {
  projectId: Scalars['ID'];
  categoryId: Scalars['ID'];
};

export type AddTasksTagInput = {
  projectId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
  tagId: Scalars['ID'];
};

export type RemoveTasksTagInput = {
  projectId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
  tagId: Scalars['ID'];
};

export type UnpinTaskFromPlanInput = {
  taskId: Scalars['ID'];
};

export type RemoveRelatedPlanInput = {
  taskId: Scalars['ID'];
};

export type GetSelectedTasksTagsInput = {
  taskIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type GetTaskInput = {
  taskId: Scalars['ID'];
};

export type CreateTagWithTasksInput = {
  taskIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
  name: Scalars['String'];
};

export type RemoveRelatedTaskInput = {
  taskId: Scalars['ID'];
  fromTaskId: Scalars['ID'];
};

export type TasksFilterInput = {
  query?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['ID']>;
};

export type GetBasicInfoTaskByPlanInput = {
  planId?: Maybe<Scalars['ID']>;
};

export type ReportTasksInput = {
  projectId: Scalars['ID'];
  reportBy: ReportBy;
  categoryId?: Maybe<Scalars['ID']>;
};

export enum ReportBy {
  Category = 'CATEGORY',
  MyTasks = 'MY_TASKS',
  AllTask = 'ALL_TASK',
  WatchedTask = 'WATCHED_TASK',
}

export type DuplicateTaskInput = {
  fromTaskId: Scalars['ID'];
};

export type CreateCheckListTemplateInput = {
  name: Scalars['String'];
  checkItems?: Maybe<Array<Scalars['String']>>;
  projectId: Scalars['ID'];
};

export type AddCheckItemsToTemplateInput = {
  names: Array<Scalars['String']>;
  templateId: Scalars['ID'];
};

export type UpdateCheckItemTemplateInput = {
  name: Scalars['String'];
  checkItemId: Scalars['ID'];
  templateId: Scalars['ID'];
};

export type UpdateCheckListTemplateInfoInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RemoveCheckItemsTemplateInput = {
  checkItemIds: Array<Scalars['ID']>;
  templateId?: Maybe<Scalars['ID']>;
};

export type DeleteCheckListTemplateInput = {
  id: Scalars['ID'];
};

export type GetTemplatesInput = {
  projectId: Scalars['ID'];
};

export type AddCheckListToTaskInput = {
  templateId: Scalars['ID'];
  taskId: Scalars['ID'];
};

export type UpdateCheckListTemplateInput = {
  checkItemNames?: Maybe<Array<Scalars['String']>>;
  templateId: Scalars['ID'];
};

export type GetTemplateInput = {
  id: Scalars['ID'];
};

export type EachUpdateTaskFieldsInput = {
  status?: Maybe<TaskStatus>;
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  assigneeId?: Maybe<Scalars['ID']>;
  relatedPlanId?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  manpower?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  priority?: Maybe<TaskPriority>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  watcherIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type RangeInput = {
  mentionedId: Scalars['ID'];
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export enum ResponseType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type Admin = {
  __typename?: 'Admin';
  id: Scalars['ID'];
  fullname?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Users = {
  __typename?: 'Users';
  items: Array<User>;
  paginate?: Maybe<Paginate>;
};

export type AdminJwt = {
  __typename?: 'AdminJWT';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  uid?: Maybe<Scalars['ID']>;
  expiresAt: Scalars['Int'];
  refreshTokenExpiresAt: Scalars['Int'];
  payload?: Maybe<AdminUser>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  id: Scalars['ID'];
  fullname?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type AdminLoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type Admin_UsersFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type Admin_OrganizationsFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type Admin_ChangeOrganizationStatus = {
  organizationId: Scalars['ID'];
  status: OrganizationStatus;
};

export type Admin_UpdateOrganizationInfoInput = {
  phoneNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  ownerFirstName?: Maybe<Scalars['String']>;
  ownerLastName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  status?: Maybe<OrganizationStatus>;
};

export type Admin_CreateAdminUserInput = {
  fullname: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type Admin_UpdateAdminUserPasswordInput = {
  adminUserId: Scalars['ID'];
  password: Scalars['String'];
};

export type Admin_UpdateUserPasswordInput = {
  userId: Scalars['ID'];
  password: Scalars['String'];
};

export type Admin_DeleteOrganizationInput = {
  organizationId: Scalars['ID'];
};

export type CreateTutorialInput = {
  url: Scalars['String'];
  title: Scalars['String'];
  sort: Scalars['Int'];
  thumbId?: Maybe<Scalars['ID']>;
  thumbUrl?: Maybe<Scalars['String']>;
};

export type UpdateTutorialInput = {
  url: Scalars['String'];
  title: Scalars['String'];
  sort: Scalars['Int'];
  thumbId?: Maybe<Scalars['ID']>;
};

export type ProjectItems = {
  __typename?: 'ProjectItems';
  items: Array<ProjectItem>;
  paginate?: Maybe<Paginate>;
};

export type ProjectItem = {
  __typename?: 'ProjectItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<ProjectItem>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateProjectItemInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  projectId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
};

export type UpdateProjectItemInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
};

export type ProjectItemFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type ProjectItemOrderByInput = {
  createdAt?: Maybe<Sort>;
  name?: Maybe<Sort>;
  code?: Maybe<Sort>;
};

export type MeasurementUnits = {
  __typename?: 'MeasurementUnits';
  items: Array<MeasurementUnit>;
  paginate?: Maybe<Paginate>;
};

export type MeasurementUnit = {
  __typename?: 'MeasurementUnit';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  organizationId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateMeasurementUnitInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type UpdateMeasurementUnitInput = {
  name: Scalars['String'];
};

export type MeasurementUnitFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type MeasurementUnitOrderByInput = {
  name?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type Materials = {
  __typename?: 'Materials';
  items: Array<Material>;
  paginate?: Maybe<Paginate>;
};

export type Material = {
  __typename?: 'Material';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  organizationId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateMaterialInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type UpdateMaterialInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type MaterialFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type MaterialOrderByInput = {
  name?: Maybe<Sort>;
  code?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type Equipments = {
  __typename?: 'Equipments';
  items: Array<Equipment>;
  paginate?: Maybe<Paginate>;
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  organizationId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateEquipmentInput = {
  name: Scalars['String'];
  number: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type UpdateEquipmentInput = {
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type EquipmentFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type EquipmentOrderByInput = {
  name?: Maybe<Sort>;
  number?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type ProjectManpowers = {
  __typename?: 'ProjectManpowers';
  items: Array<ProjectManpower>;
  paginate?: Maybe<Paginate>;
};

export type ProjectManpower = {
  __typename?: 'ProjectManpower';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  projectId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateProjectManpowerInput = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

export type UpdateProjectManpowerInput = {
  name: Scalars['String'];
};

export type ProjectManpowerFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type ProjectManpowerOrderByInput = {
  createdAt?: Maybe<Sort>;
};

export type ProjectItemReports = {
  __typename?: 'ProjectItemReports';
  items: Array<ProjectItemReport>;
  paginate?: Maybe<Paginate>;
};

export type ProjectItemReport = {
  __typename?: 'ProjectItemReport';
  id: Scalars['ID'];
  projectItemName?: Maybe<Scalars['String']>;
  projectItemCode?: Maybe<Scalars['String']>;
  projectItemId?: Maybe<Scalars['ID']>;
  projectItem?: Maybe<ProjectItem>;
  boqItemId?: Maybe<Scalars['ID']>;
  boqItem?: Maybe<BoqItem>;
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  measurementUnitId?: Maybe<Scalars['ID']>;
  measurementUnit?: Maybe<Scalars['String']>;
  quantityCompleted?: Maybe<Scalars['Float']>;
  quantityInspected?: Maybe<Scalars['Float']>;
  quantityExpected?: Maybe<Scalars['Float']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attachments?: Maybe<Array<Maybe<File>>>;
  companyId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  note?: Maybe<Scalars['String']>;
  status: ReportStatus;
  projectId?: Maybe<Scalars['ID']>;
  reporterId?: Maybe<Scalars['ID']>;
  reporter?: Maybe<OrganizationUser>;
  approverId?: Maybe<Scalars['ID']>;
  approver?: Maybe<OrganizationUser>;
  reportDate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ProjectItemReportLogging = {
  __typename?: 'ProjectItemReportLogging';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  user?: Maybe<OrganizationUser>;
  reportId: Scalars['ID'];
  report?: Maybe<ProjectItemReport>;
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  reportUpdatedAt?: Maybe<Scalars['Int']>;
};

export type CreateProjectItemReportInput = {
  boqItemId: Scalars['ID'];
  quantityCompleted?: Maybe<Scalars['Float']>;
  quantityInspected?: Maybe<Scalars['Float']>;
  quantityExpected?: Maybe<Scalars['Float']>;
  reportDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ReportStatus>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  locationId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type UpdateProjectItemReportInput = {
  boqItemId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  quantityCompleted?: Maybe<Scalars['Float']>;
  quantityInspected?: Maybe<Scalars['Float']>;
  quantityExpected?: Maybe<Scalars['Float']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status?: Maybe<ReportStatus>;
  locationId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type ProjectItemReportFilterInput = {
  query?: Maybe<Scalars['String']>;
  status?: Maybe<ReportStatus>;
  reportDate?: Maybe<Scalars['Int']>;
  reporterId?: Maybe<Scalars['ID']>;
};

export type EquipmentReports = {
  __typename?: 'EquipmentReports';
  items: Array<EquipmentReport>;
  paginate?: Maybe<Paginate>;
};

export type EquipmentReport = {
  __typename?: 'EquipmentReport';
  id: Scalars['ID'];
  equipmentName: Scalars['String'];
  equipmentNumber: Scalars['String'];
  equipmentId: Scalars['ID'];
  projectItemId?: Maybe<Scalars['ID']>;
  projectItem?: Maybe<ProjectItem>;
  projectItemName?: Maybe<Scalars['String']>;
  projectItemCode?: Maybe<Scalars['String']>;
  boqItemId?: Maybe<Scalars['ID']>;
  boqItem?: Maybe<BoqItem>;
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attachments?: Maybe<Array<Maybe<File>>>;
  reporterId: Scalars['ID'];
  reporter?: Maybe<OrganizationUser>;
  approverId?: Maybe<Scalars['ID']>;
  approver?: Maybe<OrganizationUser>;
  companyId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  dayWorkingHours?: Maybe<Scalars['Float']>;
  nightWorkingHours?: Maybe<Scalars['Float']>;
  dayNumberOfEquipments?: Maybe<Scalars['Int']>;
  nightNumberOfEquipments?: Maybe<Scalars['Int']>;
  status?: Maybe<ReportStatus>;
  projectId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type EquipmentReportLogging = {
  __typename?: 'EquipmentReportLogging';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  user?: Maybe<OrganizationUser>;
  reportId: Scalars['ID'];
  report?: Maybe<EquipmentReport>;
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  reportUpdatedAt?: Maybe<Scalars['Int']>;
};

export type CreateEquipmentReportInput = {
  projectId: Scalars['ID'];
  equipmentId: Scalars['ID'];
  projectItemId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ReportStatus>;
  locationId?: Maybe<Scalars['ID']>;
  boqItemId?: Maybe<Scalars['ID']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dayWorkingHours?: Maybe<Scalars['Float']>;
  nightWorkingHours?: Maybe<Scalars['Float']>;
  dayNumberOfEquipments?: Maybe<Scalars['Int']>;
  nightNumberOfEquipments?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type UpdateEquipmentReportInput = {
  equipmentId?: Maybe<Scalars['ID']>;
  projectItemId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ReportStatus>;
  locationId?: Maybe<Scalars['ID']>;
  boqItemId?: Maybe<Scalars['ID']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dayWorkingHours?: Maybe<Scalars['Float']>;
  nightWorkingHours?: Maybe<Scalars['Float']>;
  dayNumberOfEquipments?: Maybe<Scalars['Int']>;
  nightNumberOfEquipments?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type EquipmentReportFilterInput = {
  query?: Maybe<Scalars['String']>;
  status?: Maybe<ReportStatus>;
  reportDate?: Maybe<Scalars['Int']>;
  reporterId?: Maybe<Scalars['ID']>;
};

export type MaterialReports = {
  __typename?: 'MaterialReports';
  items: Array<MaterialReport>;
  paginate?: Maybe<Paginate>;
};

export type MaterialReport = {
  __typename?: 'MaterialReport';
  id: Scalars['ID'];
  materialName: Scalars['String'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  measurementUnitId: Scalars['ID'];
  measurementUnit?: Maybe<Scalars['String']>;
  mass?: Maybe<Scalars['Float']>;
  projectItemId?: Maybe<Scalars['ID']>;
  projectItem?: Maybe<ProjectItem>;
  projectItemName?: Maybe<Scalars['String']>;
  projectItemCode?: Maybe<Scalars['String']>;
  boqItemId?: Maybe<Scalars['ID']>;
  boqItem?: Maybe<BoqItem>;
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  companyId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  projectItemParent?: Maybe<ProjectItem>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attachments?: Maybe<Array<Maybe<File>>>;
  reporterId: Scalars['ID'];
  reporter?: Maybe<OrganizationUser>;
  approverId?: Maybe<Scalars['ID']>;
  approver?: Maybe<OrganizationUser>;
  status?: Maybe<ReportStatus>;
  projectId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type MaterialReportLogging = {
  __typename?: 'MaterialReportLogging';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  user?: Maybe<OrganizationUser>;
  reportId: Scalars['ID'];
  report?: Maybe<MaterialReport>;
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  reportUpdatedAt?: Maybe<Scalars['Int']>;
};

export type CreateMaterialReportInput = {
  projectId: Scalars['ID'];
  materialId: Scalars['ID'];
  projectItemId?: Maybe<Scalars['ID']>;
  measurementUnitId: Scalars['ID'];
  locationId?: Maybe<Scalars['ID']>;
  boqItemId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ReportStatus>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  mass: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type UpdateMaterialReportInput = {
  materialId?: Maybe<Scalars['ID']>;
  projectItemId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  boqItemId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  measurementUnitId?: Maybe<Scalars['ID']>;
  status?: Maybe<ReportStatus>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  mass?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type MaterialReportFilterInput = {
  query?: Maybe<Scalars['String']>;
  status?: Maybe<ReportStatus>;
  reportDate?: Maybe<Scalars['Int']>;
  reporterId?: Maybe<Scalars['ID']>;
};

export type ManpowerReports = {
  __typename?: 'ManpowerReports';
  items: Array<ManpowerReport>;
  paginate?: Maybe<Paginate>;
};

export type ManpowerReport = {
  __typename?: 'ManpowerReport';
  id: Scalars['ID'];
  manpowerName: Scalars['String'];
  manpowerId: Scalars['ID'];
  projectItem?: Maybe<ProjectItem>;
  projectItemId?: Maybe<Scalars['ID']>;
  projectItemName?: Maybe<Scalars['String']>;
  projectItemCode?: Maybe<Scalars['String']>;
  boqItemId?: Maybe<Scalars['ID']>;
  boqItem?: Maybe<BoqItem>;
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  dayManpowerNumber?: Maybe<Scalars['Int']>;
  nightManpowerNumber?: Maybe<Scalars['Int']>;
  dayWorkingHours?: Maybe<Scalars['Float']>;
  nightWorkingHours?: Maybe<Scalars['Float']>;
  totalWorkingHours?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attachments?: Maybe<Array<Maybe<File>>>;
  companyId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  reporterId: Scalars['ID'];
  reporter?: Maybe<OrganizationUser>;
  approverId?: Maybe<Scalars['ID']>;
  approver?: Maybe<OrganizationUser>;
  status?: Maybe<ReportStatus>;
  projectId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type ManpowerReportLogging = {
  __typename?: 'ManpowerReportLogging';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  user?: Maybe<OrganizationUser>;
  reportId: Scalars['ID'];
  report?: Maybe<ManpowerReport>;
  note?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  reportUpdatedAt?: Maybe<Scalars['Int']>;
};

export type CreateManpowerReportInput = {
  projectId: Scalars['ID'];
  manpowerId: Scalars['ID'];
  locationId?: Maybe<Scalars['ID']>;
  boqItemId?: Maybe<Scalars['ID']>;
  projectItemId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ReportStatus>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dayManpowerNumber?: Maybe<Scalars['Int']>;
  nightManpowerNumber?: Maybe<Scalars['Int']>;
  dayWorkingHours?: Maybe<Scalars['Float']>;
  nightWorkingHours?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type UpdateManpowerReportInput = {
  manpowerId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  boqItemId?: Maybe<Scalars['ID']>;
  projectItemId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  status?: Maybe<ReportStatus>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dayManpowerNumber?: Maybe<Scalars['Int']>;
  nightManpowerNumber?: Maybe<Scalars['Int']>;
  dayWorkingHours?: Maybe<Scalars['Float']>;
  nightWorkingHours?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  loggingNote?: Maybe<Scalars['String']>;
};

export type ManpowerReportFilterInput = {
  query?: Maybe<Scalars['String']>;
  status?: Maybe<ReportStatus>;
  reportDate?: Maybe<Scalars['Int']>;
  reporterId?: Maybe<Scalars['ID']>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Location>;
  createdById?: Maybe<Scalars['ID']>;
  updatedById?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  project?: Maybe<Project>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type Locations = {
  __typename?: 'Locations';
  items: Array<Location>;
  paginate?: Maybe<Paginate>;
};

export type CreateLocationInput = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
};

export type UpdateLocationInput = {
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationOrderByInput = {
  name?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type BoqItems = {
  __typename?: 'BOQItems';
  items: Array<BoqItem>;
  paginate?: Maybe<Paginate>;
};

export type BoqItem = {
  __typename?: 'BOQItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['ID']>;
  unit?: Maybe<MeasurementUnit>;
  note?: Maybe<Scalars['String']>;
  projectItemId: Scalars['ID'];
  projectItem?: Maybe<ProjectItem>;
  createdById?: Maybe<Scalars['ID']>;
  updatedById?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateBoqInput = {
  projectItemId: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateBoqInput = {
  id: Scalars['ID'];
  projectItemId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type BoqOrderByInput = {
  name?: Maybe<Sort>;
  code?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type Companies = {
  __typename?: 'Companies';
  items: Array<Company>;
  paginate?: Maybe<Paginate>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  logoId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  logoId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  logoId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CompanyOrderByInput = {
  name?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type BoqLocationMaxQuantity = {
  __typename?: 'BOQLocationMaxQuantity';
  id?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Float']>;
  quantityAccumulated?: Maybe<Scalars['Float']>;
  boqItemId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  createdById?: Maybe<Scalars['ID']>;
};

export type DcrWorkReports = {
  __typename?: 'DCRWorkReports';
  items: Array<DcrWorkReport>;
  paginate?: Maybe<Paginate>;
};

export type DcrWorkReport = {
  __typename?: 'DCRWorkReport';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DcrWorkReportType;
  projectItemId?: Maybe<Scalars['ID']>;
  projectItem?: Maybe<ProjectItem>;
  locationId?: Maybe<Scalars['ID']>;
  location?: Maybe<Location>;
  time?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attachments?: Maybe<Array<Maybe<File>>>;
  projectId: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<OrganizationUser>;
};

export type CreateDcrWorkReportInput = {
  name: Scalars['String'];
  type: DcrWorkReportType;
  projectId: Scalars['ID'];
  projectItemId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  time?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reportDate?: Maybe<Scalars['Int']>;
};

export type UpdateDcrWorkReportInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  projectItemId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  time?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DcrNote = {
  __typename?: 'DCRNote';
  id: Scalars['ID'];
  type: DcrNoteType;
  note?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<OrganizationUser>;
};

export type SaveDcrNoteInput = {
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  reportDate?: Maybe<Scalars['Int']>;
  type: DcrNoteType;
};

export type DcrChecklist = {
  __typename?: 'DCRChecklist';
  id: Scalars['ID'];
  question: Scalars['String'];
  answer?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attachments?: Maybe<Array<Maybe<File>>>;
  companyId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<OrganizationUser>;
};

export type SaveDcrChecklistInput = {
  list: Array<DcrChecklistItem>;
  projectId: Scalars['ID'];
  reportDate?: Maybe<Scalars['Int']>;
};

export type DcrChecklistItem = {
  question: Scalars['String'];
  answer?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DcrChecklistQuestions = {
  __typename?: 'DCRChecklistQuestions';
  question: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type DcrImage = {
  __typename?: 'DCRImage';
  id: Scalars['ID'];
  type: DcrImageType;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attachments?: Maybe<Array<Maybe<File>>>;
  companyId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  createdById?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<OrganizationUser>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  updatedById?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<OrganizationUser>;
};

export type SaveDcrImageInput = {
  id?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  reportDate?: Maybe<Scalars['Int']>;
  type: DcrImageType;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DcrExportPdf = {
  __typename?: 'DcrExportPdf';
  success: Scalars['Boolean'];
  url: Scalars['String'];
};

export type ExportDcrPdfInput = {
  projectId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  projectItemId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  reportDate?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['ID']>;
  titleImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  attachmentImages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CopyDcrReportInput = {
  projectId: Scalars['ID'];
  reportDate?: Maybe<Scalars['Int']>;
};

export type BlogCategories = {
  __typename?: 'BlogCategories';
  items: Array<BlogCategory>;
  paginate?: Maybe<Paginate>;
};

export type BlogCategoryFilter = {
  query?: Maybe<Scalars['String']>;
};

export type BlogCategory = {
  __typename?: 'BlogCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  nameForSeo?: Maybe<Scalars['String']>;
  descriptionForSeo?: Maybe<Scalars['String']>;
  status: BlogCategoryStatus;
  icon?: Maybe<Scalars['String']>;
  createdById: Scalars['ID'];
  createdBy?: Maybe<OrganizationUser>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateBlogCategoryInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  nameForSeo?: Maybe<Scalars['String']>;
  descriptionForSeo?: Maybe<Scalars['String']>;
  status?: Maybe<BlogCategoryStatus>;
  icon?: Maybe<Scalars['String']>;
};

export type UpdateBlogCategoryInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nameForSeo?: Maybe<Scalars['String']>;
  descriptionForSeo?: Maybe<Scalars['String']>;
  status?: Maybe<BlogCategoryStatus>;
  icon?: Maybe<Scalars['String']>;
};

export type BlogCategoryOrderByInput = {
  name?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type BlogTags = {
  __typename?: 'BlogTags';
  items: Array<BlogTag>;
  paginate?: Maybe<Paginate>;
};

export type BlogTagFilter = {
  query?: Maybe<Scalars['String']>;
};

export type BlogTag = {
  __typename?: 'BlogTag';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  nameForSeo?: Maybe<Scalars['String']>;
  descriptionForSeo?: Maybe<Scalars['String']>;
  status?: Maybe<BlogTagStatus>;
  createdById?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateBlogTagInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  nameForSeo?: Maybe<Scalars['String']>;
  descriptionForSeo?: Maybe<Scalars['String']>;
  status?: Maybe<BlogTagStatus>;
};

export type UpdateBlogTagInput = {
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nameForSeo?: Maybe<Scalars['String']>;
  descriptionForSeo?: Maybe<Scalars['String']>;
  status?: Maybe<BlogTagStatus>;
};

export type BlogTagOrderByInput = {
  name?: Maybe<Sort>;
  slug?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type Blogs = {
  __typename?: 'Blogs';
  items: Array<Blog>;
  paginate?: Maybe<Paginate>;
};

export type BlogFilter = {
  query?: Maybe<Scalars['String']>;
};

export type Blog = {
  __typename?: 'Blog';
  id: Scalars['ID'];
  title: Scalars['String'];
  titleForSeo?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionForSeo?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  titleImage: Scalars['String'];
  titleImageAlt?: Maybe<Scalars['String']>;
  titleImageCaption?: Maybe<Scalars['String']>;
  titleImageDescription?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<BlogStatus>;
  createdById: Scalars['ID'];
  createdBy?: Maybe<AdminUser>;
  blogTags?: Maybe<Array<Maybe<BlogTag>>>;
  blogCategories?: Maybe<Array<Maybe<BlogCategory>>>;
  storyId?: Maybe<Scalars['ID']>;
  story?: Maybe<BlogStory>;
  storyPart?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<User>;
  publishDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateBlogInput = {
  title: Scalars['String'];
  titleForSeo?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionForSeo?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  titleImage: Scalars['String'];
  titleImageAlt?: Maybe<Scalars['String']>;
  titleImageCaption?: Maybe<Scalars['String']>;
  titleImageDescription?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<BlogStatus>;
  blogTagIds?: Maybe<Array<Scalars['ID']>>;
  storyId?: Maybe<Scalars['ID']>;
  storyPart?: Maybe<Scalars['String']>;
  blogCategoryIds?: Maybe<Array<Scalars['ID']>>;
  publishDate?: Maybe<Scalars['Date']>;
};

export type UpdateBlogInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleForSeo?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionForSeo?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  titleImage: Scalars['String'];
  titleImageAlt?: Maybe<Scalars['String']>;
  titleImageCaption?: Maybe<Scalars['String']>;
  titleImageDescription?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<BlogStatus>;
  blogTagIds?: Maybe<Array<Scalars['ID']>>;
  storyId?: Maybe<Scalars['ID']>;
  storyPart?: Maybe<Scalars['String']>;
  blogCategoryIds?: Maybe<Array<Scalars['ID']>>;
  publishDate?: Maybe<Scalars['Date']>;
};

export type BlogOrderByInput = {
  title?: Maybe<Sort>;
  slug?: Maybe<Sort>;
  publishDate?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
};

export type BlogStoryFilter = {
  query?: Maybe<Scalars['String']>;
};

export type BlogStories = {
  __typename?: 'BlogStories';
  items: Array<BlogStory>;
  paginate?: Maybe<Paginate>;
};

export type BlogStory = {
  __typename?: 'BlogStory';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type CreateBlogStoryInput = {
  name: Scalars['String'];
};

export type UpdateBlogStoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImportApiResponse = {
  __typename?: 'ImportAPIResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ReportOrderByInput = {
  reportDate?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
  createdAt?: Maybe<Sort>;
  name?: Maybe<Sort>;
};

export type JwtFields = { __typename?: 'AdminJWT' } & Pick<
  AdminJwt,
  'token' | 'refreshToken' | 'uid' | 'expiresAt' | 'refreshTokenExpiresAt'
> & { payload?: Maybe<{ __typename?: 'AdminUser' } & AuthUserFields> };

export type PaginateFields = { __typename?: 'Paginate' } & Pick<
  Paginate,
  'pageIndex' | 'totalPage' | 'pageSize' | 'totalItems'
>;

export type AuthUserFields = { __typename?: 'AdminUser' } & Pick<AdminUser, 'id' | 'fullname'>;

export type UserFields = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'isEmailVerified'
  | 'password'
  | 'jobTitle'
  | 'phoneNumber'
  | 'createdAt'
  | 'updatedAt'
>;

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'Mutation' } & { adminLogin: { __typename?: 'AdminJWT' } & JwtFields };

export type RefreshTokenVariables = Exact<{
  token: Scalars['String'];
}>;

export type RefreshToken = { __typename?: 'Mutation' } & { refreshToken: { __typename?: 'AdminJWT' } & JwtFields };

export type MeVariables = Exact<{ [key: string]: never }>;

export type Me = { __typename?: 'Query' } & { me?: Maybe<{ __typename?: 'User' } & UserFields> };

export type GetBlogTagsVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogTagFilter>;
  orderBy?: Maybe<BlogTagOrderByInput>;
}>;

export type GetBlogTags = { __typename?: 'Query' } & {
  getBlogTags: { __typename?: 'BlogTags' } & {
    items: Array<
      { __typename?: 'BlogTag' } & Pick<
        BlogTag,
        | 'id'
        | 'name'
        | 'slug'
        | 'description'
        | 'nameForSeo'
        | 'descriptionForSeo'
        | 'status'
        | 'createdById'
        | 'createdAt'
        | 'updatedAt'
      >
    >;
    paginate?: Maybe<
      { __typename?: 'Paginate' } & Pick<Paginate, 'pageIndex' | 'totalPage' | 'pageSize' | 'totalItems'>
    >;
  };
};

export type CreateBlogTagVariables = Exact<{
  data: CreateBlogTagInput;
}>;

export type CreateBlogTag = { __typename?: 'Mutation' } & {
  createBlogTag: { __typename?: 'BlogTag' } & Pick<
    BlogTag,
    | 'id'
    | 'name'
    | 'slug'
    | 'description'
    | 'nameForSeo'
    | 'descriptionForSeo'
    | 'status'
    | 'createdById'
    | 'createdAt'
    | 'updatedAt'
  >;
};

export type UpdateBlogTagVariables = Exact<{
  data: UpdateBlogTagInput;
}>;

export type UpdateBlogTag = { __typename?: 'Mutation' } & Pick<Mutation, 'updateBlogTag'>;

export type DeleteBlogTagVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBlogTag = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteBlogTag'>;

export type GetBlogCategoriesVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogCategoryFilter>;
  orderBy?: Maybe<BlogCategoryOrderByInput>;
}>;

export type GetBlogCategories = { __typename?: 'Query' } & {
  getBlogCategories: { __typename?: 'BlogCategories' } & {
    items: Array<
      { __typename?: 'BlogCategory' } & Pick<
        BlogCategory,
        | 'id'
        | 'name'
        | 'slug'
        | 'description'
        | 'nameForSeo'
        | 'descriptionForSeo'
        | 'status'
        | 'icon'
        | 'createdAt'
        | 'updatedAt'
      >
    >;
    paginate?: Maybe<
      { __typename?: 'Paginate' } & Pick<Paginate, 'pageIndex' | 'totalPage' | 'pageSize' | 'totalItems'>
    >;
  };
};

export type CreateBlogCategoryVariables = Exact<{
  data: CreateBlogCategoryInput;
}>;

export type CreateBlogCategory = { __typename?: 'Mutation' } & {
  createBlogCategory: { __typename?: 'BlogCategory' } & Pick<BlogCategory, 'id'>;
};

export type UpdateBlogCategoryVariables = Exact<{
  data: UpdateBlogCategoryInput;
}>;

export type UpdateBlogCategory = { __typename?: 'Mutation' } & Pick<Mutation, 'updateBlogCategory'>;

export type DeleteBlogCategoryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBlogCategory = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteBlogCategory'>;

export type GetBlogsVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogFilter>;
  orderBy?: Maybe<BlogTagOrderByInput>;
}>;

export type GetBlogs = { __typename?: 'Query' } & {
  getBlogs?: Maybe<
    { __typename?: 'Blogs' } & {
      items: Array<
        { __typename?: 'Blog' } & Pick<
          Blog,
          | 'id'
          | 'title'
          | 'titleForSeo'
          | 'shortDescription'
          | 'shortDescriptionForSeo'
          | 'slug'
          | 'titleImage'
          | 'content'
          | 'order'
          | 'status'
          | 'publishDate'
          | 'createdAt'
          | 'updatedAt'
          | 'createdById'
        > & {
            createdBy?: Maybe<{ __typename?: 'AdminUser' } & Pick<AdminUser, 'fullname' | 'username'>>;
            blogCategories?: Maybe<Array<Maybe<{ __typename?: 'BlogCategory' } & Pick<BlogCategory, 'id' | 'name'>>>>;
          }
      >;
      paginate?: Maybe<
        { __typename?: 'Paginate' } & Pick<Paginate, 'pageIndex' | 'totalPage' | 'pageSize' | 'totalItems'>
      >;
    }
  >;
};

export type GetBlogVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBlog = { __typename?: 'Query' } & {
  getBlog?: Maybe<
    { __typename?: 'Blog' } & Pick<
      Blog,
      | 'id'
      | 'title'
      | 'titleForSeo'
      | 'shortDescription'
      | 'shortDescriptionForSeo'
      | 'slug'
      | 'titleImage'
      | 'content'
      | 'order'
      | 'status'
      | 'createdById'
      | 'storyId'
      | 'storyPart'
      | 'titleImageAlt'
      | 'titleImageCaption'
      | 'titleImageDescription'
      | 'updatedById'
      | 'publishDate'
      | 'createdAt'
      | 'updatedAt'
    > & {
        createdBy?: Maybe<{ __typename?: 'AdminUser' } & Pick<AdminUser, 'username' | 'fullname'>>;
        blogTags?: Maybe<Array<Maybe<{ __typename?: 'BlogTag' } & Pick<BlogTag, 'id' | 'name'>>>>;
        blogCategories?: Maybe<Array<Maybe<{ __typename?: 'BlogCategory' } & Pick<BlogCategory, 'id' | 'name'>>>>;
        story?: Maybe<{ __typename?: 'BlogStory' } & Pick<BlogStory, 'id' | 'name'>>;
      }
  >;
};

export type CreateBlogVariables = Exact<{
  data?: Maybe<CreateBlogInput>;
}>;

export type CreateBlog = { __typename?: 'Mutation' } & { createBlog: { __typename?: 'Blog' } & Pick<Blog, 'id'> };

export type UpdateBlogVariables = Exact<{
  data?: Maybe<UpdateBlogInput>;
}>;

export type UpdateBlog = { __typename?: 'Mutation' } & Pick<Mutation, 'updateBlog'>;

export type DeleteBlogVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBlog = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteBlog'>;

export type SingleUploadVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type SingleUpload = { __typename?: 'Mutation' } & {
  singleUpload: { __typename?: 'File' } & Pick<File, 'id' | 'url' | 'name'>;
};

export type GetBlogStoriesVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlogStoryFilter>;
}>;

export type GetBlogStories = { __typename?: 'Query' } & {
  getBlogStories: { __typename?: 'BlogStories' } & {
    items: Array<{ __typename?: 'BlogStory' } & Pick<BlogStory, 'id' | 'name' | 'createdAt' | 'updatedAt'>>;
    paginate?: Maybe<
      { __typename?: 'Paginate' } & Pick<Paginate, 'pageIndex' | 'totalPage' | 'pageSize' | 'totalItems'>
    >;
  };
};

export type CreateBlogStoryVariables = Exact<{
  data: CreateBlogStoryInput;
}>;

export type CreateBlogStory = { __typename?: 'Mutation' } & {
  createBlogStory: { __typename?: 'BlogStory' } & Pick<BlogStory, 'id' | 'name' | 'createdAt' | 'updatedAt'>;
};

export type UpdateBlogStoryVariables = Exact<{
  data: UpdateBlogStoryInput;
}>;

export type UpdateBlogStory = { __typename?: 'Mutation' } & Pick<Mutation, 'updateBlogStory'>;

export type DeleteBlogStoryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBlogStory = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteBlogStory'>;

export type ChangeOrganizationStatusVariables = Exact<{
  data: Admin_ChangeOrganizationStatus;
}>;

export type ChangeOrganizationStatus = { __typename?: 'Mutation' } & Pick<Mutation, 'admin_ChangeOrganizationStatus'>;

export type UpdateOrganizationInfoVariables = Exact<{
  data: Admin_UpdateOrganizationInfoInput;
}>;

export type UpdateOrganizationInfo = { __typename?: 'Mutation' } & Pick<Mutation, 'admin_updateOrganizationInfo'>;

export type GetOrganizationsVariables = Exact<{
  types: Array<OrganizationStatus>;
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<Admin_OrganizationsFilterInput>;
}>;

export type GetOrganizations = { __typename?: 'Query' } & {
  organizations?: Maybe<
    { __typename?: 'Organizations' } & {
      items: Array<
        { __typename?: 'Organization' } & Pick<
          Organization,
          | 'id'
          | 'name'
          | 'createdAt'
          | 'updatedAt'
          | 'status'
          | 'email'
          | 'phoneNumber'
          | 'startDate'
          | 'endDate'
          | 'membersNumber'
        > & { owner?: Maybe<{ __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'message' | 'utm'>> }
      >;
      paginate?: Maybe<
        { __typename?: 'Paginate' } & Pick<Paginate, 'pageIndex' | 'totalPage' | 'pageSize' | 'totalItems'>
      >;
    }
  >;
};

export type CreateTutorialMutationVariables = Exact<{
  data: CreateTutorialInput;
}>;

export type CreateTutorialMutation = { __typename?: 'Mutation' } & {
  admin_createTutorial: { __typename?: 'Tutorial' } & Pick<Tutorial, 'id' | 'url' | 'title' | 'sort'> & {
      thumb?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'url'>>;
    };
};

export type DeleteTutorialMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTutorialMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'admin_deleteTutorial'>;

export type UpdateTutorialMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateTutorialInput;
}>;

export type UpdateTutorialMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'admin_updateTutorial'>;

export type SingleUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type SingleUploadMutation = { __typename?: 'Mutation' } & {
  singleUpload: { __typename?: 'File' } & Pick<File, 'id' | 'url' | 'thumbnail'>;
};

export type GetAllTutorialVariables = Exact<{ [key: string]: never }>;

export type GetAllTutorial = { __typename?: 'Query' } & {
  getAllTutorial: Array<
    { __typename?: 'Tutorial' } & Pick<Tutorial, 'id' | 'url' | 'title' | 'thumbId' | 'sort'> & {
        thumb?: Maybe<{ __typename?: 'File' } & Pick<File, 'id' | 'url' | 'thumbnail'>>;
      }
  >;
};

export type ListUserVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  filter?: Maybe<Admin_UsersFilterInput>;
}>;

export type ListUser = { __typename?: 'Query' } & {
  users: { __typename?: 'Users' } & {
    items: Array<{ __typename?: 'User' } & UserFields>;
    paginate?: Maybe<{ __typename?: 'Paginate' } & PaginateFields>;
  };
};

export const AuthUserFields = gql`
  fragment AuthUserFields on AdminUser {
    id
    fullname
  }
`;
export const JwtFields = gql`
  fragment JWTFields on AdminJWT {
    token
    refreshToken
    uid
    expiresAt
    refreshTokenExpiresAt
    payload {
      ...AuthUserFields
    }
  }
  ${AuthUserFields}
`;
export const PaginateFields = gql`
  fragment PaginateFields on Paginate {
    pageIndex
    totalPage
    pageSize
    totalItems
  }
`;
export const UserFields = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    email
    isEmailVerified
    password
    jobTitle
    phoneNumber
    createdAt
    updatedAt
  }
`;
export const LoginMutationDocument = gql`
  mutation loginMutation($username: String!, $password: String!) {
    adminLogin(data: { username: $username, password: $password }) {
      ...JWTFields
    }
  }
  ${JwtFields}
`;
export const RefreshTokenDocument = gql`
  mutation refreshToken($token: String!) {
    refreshToken: admin_refreshToken(token: $token) {
      ...JWTFields
    }
  }
  ${JwtFields}
`;
export const MeDocument = gql`
  query me {
    me {
      ...UserFields
    }
  }
  ${UserFields}
`;
export const GetBlogTagsDocument = gql`
  query getBlogTags($pageSize: Int, $pageIndex: Int, $filter: BlogTagFilter, $orderBy: BlogTagOrderByInput) {
    getBlogTags(pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter, orderBy: $orderBy) {
      items {
        id
        name
        slug
        description
        nameForSeo
        descriptionForSeo
        status
        createdById
        createdAt
        updatedAt
      }
      paginate {
        pageIndex
        totalPage
        pageSize
        totalItems
      }
    }
  }
`;
export const CreateBlogTagDocument = gql`
  mutation createBlogTag($data: CreateBlogTagInput!) {
    createBlogTag(data: $data) {
      id
      name
      slug
      description
      nameForSeo
      descriptionForSeo
      status
      createdById
      createdAt
      updatedAt
    }
  }
`;
export const UpdateBlogTagDocument = gql`
  mutation updateBlogTag($data: UpdateBlogTagInput!) {
    updateBlogTag(data: $data)
  }
`;
export const DeleteBlogTagDocument = gql`
  mutation deleteBlogTag($id: ID!) {
    deleteBlogTag(id: $id)
  }
`;
export const GetBlogCategoriesDocument = gql`
  query getBlogCategories(
    $pageSize: Int
    $pageIndex: Int
    $filter: BlogCategoryFilter
    $orderBy: BlogCategoryOrderByInput
  ) {
    getBlogCategories(pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter, orderBy: $orderBy) {
      items {
        id
        name
        slug
        description
        nameForSeo
        descriptionForSeo
        status
        icon
        createdAt
        updatedAt
      }
      paginate {
        pageIndex
        totalPage
        pageSize
        totalItems
      }
    }
  }
`;
export const CreateBlogCategoryDocument = gql`
  mutation createBlogCategory($data: CreateBlogCategoryInput!) {
    createBlogCategory(data: $data) {
      id
    }
  }
`;
export const UpdateBlogCategoryDocument = gql`
  mutation updateBlogCategory($data: UpdateBlogCategoryInput!) {
    updateBlogCategory(data: $data)
  }
`;
export const DeleteBlogCategoryDocument = gql`
  mutation deleteBlogCategory($id: ID!) {
    deleteBlogCategory(id: $id)
  }
`;
export const GetBlogsDocument = gql`
  query getBlogs($pageSize: Int, $pageIndex: Int, $filter: BlogFilter, $orderBy: BlogTagOrderByInput) {
    getBlogs(pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter, orderBy: $orderBy) {
      items {
        id
        title
        titleForSeo
        shortDescription
        shortDescriptionForSeo
        slug
        titleImage
        content
        order
        status
        publishDate
        createdAt
        updatedAt
        createdById
        createdBy {
          fullname
          username
        }
        blogCategories {
          id
          name
        }
      }
      paginate {
        pageIndex
        totalPage
        pageSize
        totalItems
      }
    }
  }
`;
export const GetBlogDocument = gql`
  query getBlog($id: ID!) {
    getBlog(id: $id) {
      id
      title
      titleForSeo
      shortDescription
      shortDescriptionForSeo
      slug
      titleImage
      content
      order
      status
      createdById
      createdBy {
        username
        fullname
      }
      blogTags {
        id
        name
      }
      blogCategories {
        id
        name
      }
      storyId
      story {
        id
        name
      }
      storyPart
      titleImageAlt
      titleImageCaption
      titleImageDescription
      updatedById
      publishDate
      createdAt
      updatedAt
    }
  }
`;
export const CreateBlogDocument = gql`
  mutation createBlog($data: CreateBlogInput) {
    createBlog(data: $data) {
      id
    }
  }
`;
export const UpdateBlogDocument = gql`
  mutation updateBlog($data: UpdateBlogInput) {
    updateBlog(data: $data)
  }
`;
export const DeleteBlogDocument = gql`
  mutation deleteBlog($id: ID!) {
    deleteBlog(id: $id)
  }
`;
export const SingleUploadDocument = gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file) {
      id
      url
      name
    }
  }
`;
export const GetBlogStoriesDocument = gql`
  query getBlogStories($pageSize: Int, $pageIndex: Int, $filter: BlogStoryFilter) {
    getBlogStories(pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      paginate {
        pageIndex
        totalPage
        pageSize
        totalItems
      }
    }
  }
`;
export const CreateBlogStoryDocument = gql`
  mutation createBlogStory($data: CreateBlogStoryInput!) {
    createBlogStory(data: $data) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const UpdateBlogStoryDocument = gql`
  mutation updateBlogStory($data: UpdateBlogStoryInput!) {
    updateBlogStory(data: $data)
  }
`;
export const DeleteBlogStoryDocument = gql`
  mutation deleteBlogStory($id: ID!) {
    deleteBlogStory(id: $id)
  }
`;
export const ChangeOrganizationStatusDocument = gql`
  mutation changeOrganizationStatus($data: Admin_ChangeOrganizationStatus!) {
    admin_ChangeOrganizationStatus(data: $data)
  }
`;
export const UpdateOrganizationInfoDocument = gql`
  mutation updateOrganizationInfo($data: Admin_UpdateOrganizationInfoInput!) {
    admin_updateOrganizationInfo(data: $data)
  }
`;
export const GetOrganizationsDocument = gql`
  query getOrganizations(
    $types: [OrganizationStatus!]!
    $pageSize: Int
    $pageIndex: Int
    $filter: Admin_OrganizationsFilterInput
  ) {
    organizations: admin_getOrganizations(types: $types, pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter) {
      items {
        id
        name
        owner {
          firstName
          lastName
          message
          utm
        }
        createdAt
        updatedAt
        status
        email
        phoneNumber
        startDate
        endDate
        membersNumber
      }
      paginate {
        pageIndex
        totalPage
        pageSize
        totalItems
      }
    }
  }
`;
export const CreateTutorialMutationDocument = gql`
  mutation createTutorialMutation($data: CreateTutorialInput!) {
    admin_createTutorial(data: $data) {
      id
      url
      title
      thumb {
        id
        url
      }
      sort
    }
  }
`;
export const DeleteTutorialMutationDocument = gql`
  mutation deleteTutorialMutation($id: ID!) {
    admin_deleteTutorial(id: $id)
  }
`;
export const UpdateTutorialMutationDocument = gql`
  mutation updateTutorialMutation($id: ID!, $data: UpdateTutorialInput!) {
    admin_updateTutorial(id: $id, data: $data)
  }
`;
export const SingleUploadMutationDocument = gql`
  mutation singleUploadMutation($file: Upload!) {
    singleUpload(file: $file) {
      id
      url
      thumbnail
    }
  }
`;
export const GetAllTutorialDocument = gql`
  query getAllTutorial {
    getAllTutorial {
      id
      url
      title
      thumb {
        id
        url
        thumbnail
      }
      thumbId
      sort
    }
  }
`;
export const ListUserDocument = gql`
  query listUser($pageSize: Int, $pageIndex: Int, $filter: Admin_UsersFilterInput) {
    users: admin_getUsers(pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter) {
      items {
        ...UserFields
      }
      paginate {
        ...PaginateFields
      }
    }
  }
  ${UserFields}
  ${PaginateFields}
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    loginMutation(variables: LoginMutationVariables): Promise<LoginMutation> {
      return withWrapper(() => client.request<LoginMutation>(print(LoginMutationDocument), variables));
    },
    refreshToken(variables: RefreshTokenVariables): Promise<RefreshToken> {
      return withWrapper(() => client.request<RefreshToken>(print(RefreshTokenDocument), variables));
    },
    me(variables?: MeVariables): Promise<Me> {
      return withWrapper(() => client.request<Me>(print(MeDocument), variables));
    },
    getBlogTags(variables?: GetBlogTagsVariables): Promise<GetBlogTags> {
      return withWrapper(() => client.request<GetBlogTags>(print(GetBlogTagsDocument), variables));
    },
    createBlogTag(variables: CreateBlogTagVariables): Promise<CreateBlogTag> {
      return withWrapper(() => client.request<CreateBlogTag>(print(CreateBlogTagDocument), variables));
    },
    updateBlogTag(variables: UpdateBlogTagVariables): Promise<UpdateBlogTag> {
      return withWrapper(() => client.request<UpdateBlogTag>(print(UpdateBlogTagDocument), variables));
    },
    deleteBlogTag(variables: DeleteBlogTagVariables): Promise<DeleteBlogTag> {
      return withWrapper(() => client.request<DeleteBlogTag>(print(DeleteBlogTagDocument), variables));
    },
    getBlogCategories(variables?: GetBlogCategoriesVariables): Promise<GetBlogCategories> {
      return withWrapper(() => client.request<GetBlogCategories>(print(GetBlogCategoriesDocument), variables));
    },
    createBlogCategory(variables: CreateBlogCategoryVariables): Promise<CreateBlogCategory> {
      return withWrapper(() => client.request<CreateBlogCategory>(print(CreateBlogCategoryDocument), variables));
    },
    updateBlogCategory(variables: UpdateBlogCategoryVariables): Promise<UpdateBlogCategory> {
      return withWrapper(() => client.request<UpdateBlogCategory>(print(UpdateBlogCategoryDocument), variables));
    },
    deleteBlogCategory(variables: DeleteBlogCategoryVariables): Promise<DeleteBlogCategory> {
      return withWrapper(() => client.request<DeleteBlogCategory>(print(DeleteBlogCategoryDocument), variables));
    },
    getBlogs(variables?: GetBlogsVariables): Promise<GetBlogs> {
      return withWrapper(() => client.request<GetBlogs>(print(GetBlogsDocument), variables));
    },
    getBlog(variables: GetBlogVariables): Promise<GetBlog> {
      return withWrapper(() => client.request<GetBlog>(print(GetBlogDocument), variables));
    },
    createBlog(variables?: CreateBlogVariables): Promise<CreateBlog> {
      return withWrapper(() => client.request<CreateBlog>(print(CreateBlogDocument), variables));
    },
    updateBlog(variables?: UpdateBlogVariables): Promise<UpdateBlog> {
      return withWrapper(() => client.request<UpdateBlog>(print(UpdateBlogDocument), variables));
    },
    deleteBlog(variables: DeleteBlogVariables): Promise<DeleteBlog> {
      return withWrapper(() => client.request<DeleteBlog>(print(DeleteBlogDocument), variables));
    },
    singleUpload(variables: SingleUploadVariables): Promise<SingleUpload> {
      return withWrapper(() => client.request<SingleUpload>(print(SingleUploadDocument), variables));
    },
    getBlogStories(variables?: GetBlogStoriesVariables): Promise<GetBlogStories> {
      return withWrapper(() => client.request<GetBlogStories>(print(GetBlogStoriesDocument), variables));
    },
    createBlogStory(variables: CreateBlogStoryVariables): Promise<CreateBlogStory> {
      return withWrapper(() => client.request<CreateBlogStory>(print(CreateBlogStoryDocument), variables));
    },
    updateBlogStory(variables: UpdateBlogStoryVariables): Promise<UpdateBlogStory> {
      return withWrapper(() => client.request<UpdateBlogStory>(print(UpdateBlogStoryDocument), variables));
    },
    deleteBlogStory(variables: DeleteBlogStoryVariables): Promise<DeleteBlogStory> {
      return withWrapper(() => client.request<DeleteBlogStory>(print(DeleteBlogStoryDocument), variables));
    },
    changeOrganizationStatus(variables: ChangeOrganizationStatusVariables): Promise<ChangeOrganizationStatus> {
      return withWrapper(() =>
        client.request<ChangeOrganizationStatus>(print(ChangeOrganizationStatusDocument), variables),
      );
    },
    updateOrganizationInfo(variables: UpdateOrganizationInfoVariables): Promise<UpdateOrganizationInfo> {
      return withWrapper(() =>
        client.request<UpdateOrganizationInfo>(print(UpdateOrganizationInfoDocument), variables),
      );
    },
    getOrganizations(variables: GetOrganizationsVariables): Promise<GetOrganizations> {
      return withWrapper(() => client.request<GetOrganizations>(print(GetOrganizationsDocument), variables));
    },
    createTutorialMutation(variables: CreateTutorialMutationVariables): Promise<CreateTutorialMutation> {
      return withWrapper(() =>
        client.request<CreateTutorialMutation>(print(CreateTutorialMutationDocument), variables),
      );
    },
    deleteTutorialMutation(variables: DeleteTutorialMutationVariables): Promise<DeleteTutorialMutation> {
      return withWrapper(() =>
        client.request<DeleteTutorialMutation>(print(DeleteTutorialMutationDocument), variables),
      );
    },
    updateTutorialMutation(variables: UpdateTutorialMutationVariables): Promise<UpdateTutorialMutation> {
      return withWrapper(() =>
        client.request<UpdateTutorialMutation>(print(UpdateTutorialMutationDocument), variables),
      );
    },
    singleUploadMutation(variables: SingleUploadMutationVariables): Promise<SingleUploadMutation> {
      return withWrapper(() => client.request<SingleUploadMutation>(print(SingleUploadMutationDocument), variables));
    },
    getAllTutorial(variables?: GetAllTutorialVariables): Promise<GetAllTutorial> {
      return withWrapper(() => client.request<GetAllTutorial>(print(GetAllTutorialDocument), variables));
    },
    listUser(variables?: ListUserVariables): Promise<ListUser> {
      return withWrapper(() => client.request<ListUser>(print(ListUserDocument), variables));
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
