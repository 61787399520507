/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import { Card, message, Modal, Space, Table, Empty } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DocumentNode } from 'graphql';
import ZFilter, { ITableFilterItem } from '../ZFilter';
import useTQuery from './useTQuery';
import useTDelete from './useTDelete';
import { EditBtn, DeleteBtn } from '../ActionBtns';
import { SizeType } from 'antd/es/config-provider/SizeContext';
export interface ITableRef {
  reload: () => void;
  onSearch: (values: any) => void;
}
const DEFAULT_PAGE_SIZE = 20;
export interface IColumnType<T> {
  title: string;
  dataIndex: string | string[];
  sorter?: boolean;
  width?: number;
  ellipsis?: boolean;
  align?: 'left' | 'right' | 'center';
  render?: (value: any, record: T, index: number) => React.ReactElement[] | React.ReactElement | string;
  fixed?: 'left' | 'right';
}
export interface IFilterItem extends ITableFilterItem {}

interface IProps<T> {
  columns: any[];
  primaryKey: string;
  gql: DocumentNode;
  defaultVariables?: {};
  defaultFilter?: {};
  extendQuery?: {};
  delete_gql?: DocumentNode;
  pageSize?: number;
  schema: string;
  allowEdit?: boolean;
  hideEditIcon?: boolean;
  editText?: string;
  onEdit?(record: T): void;
  allowDelete?: boolean;
  showDelete?(record: T): boolean;
  showEdit?(record: T): boolean;
  onDelete?(record: T): void;
  deleteMessage?: string;
  transformDataSource?(data: any): {};
  filters?: Array<ITableFilterItem>;
  order_by?: { [key: string]: string };
  bordered?: boolean;
  extraActions?: { render(record: T): any }[];

  onFilterChange?: (filters: any) => void;
  extraBtns?: any;
  onDataSource?: (data: any) => void;
  transformQuery?: (q: any) => any;
  scroll?: { x: number };
  size?: SizeType;
  actionRight?: boolean;
  renderTableTitle?: () => any;
}

const getDefaultFilter = (filters: Array<ITableFilterItem> | undefined) => {
  const defaultWhere: any = {};
  filters?.forEach((f) => {
    if (f.defaultValue) {
      defaultWhere[f.name] = f.parseValue ? f.parseValue(f.defaultValue) : f.defaultValue;
    }
  });
  return defaultWhere;
};
function ZTable<T = any>(props: IProps<T> & { tableRef?: React.Ref<ITableRef> }) {
  const t = (text: any) => text;

  const actions = React.useMemo(() => {
    const actions = [];
    if (props.extraActions) {
      props.extraActions.map((action) => actions.push(action));
    }

    if (props.allowEdit) {
      actions.push({
        render: (record: any) => {
          if (props.showEdit) {
            if (props.showEdit(record)) {
              return (
                <EditBtn
                  onClick={() => {
                    if (props.onEdit) {
                      props.onEdit(record);
                    }
                  }}
                >
                  {props.editText}
                </EditBtn>
              );
            }
            return null;
          } else {
            return (
              <EditBtn
                onClick={() => {
                  if (props.onEdit) {
                    props.onEdit(record);
                  }
                }}
              >
                {props.editText}
              </EditBtn>
            );
          }
        },
      });
    }

    if (props.allowDelete) {
      actions.push({
        render: (record: any) => {
          if (props.showDelete) {
            if (props.showDelete(record)) {
              return <DeleteBtn onClick={() => handleDelete(record)} />;
            }
            return null;
          } else {
            return <DeleteBtn danger onClick={() => handleDelete(record)} />;
          }
        },
      });
    }
    return actions;
  }, []);

  const columns = React.useMemo(() => {
    let cols = [
      ...props.columns.map((column) => {
        column.title = t(column.title);
        return column;
      }),
    ];
    if (actions.length > 0) {
      const actionCol = {
        width: 120,
        title: 'Action',
        dataIndex: '_actions',
        align: 'center',
        fixed: props.scroll ? 'left' : undefined,
        render(_: any, record: any) {
          return (
            <Space>
              {actions.map((action, index) => (
                <span key={index.toString()}>{action.render(record)}</span>
              ))}
            </Space>
          );
        },
      };
      if (props.actionRight) {
        cols = [actionCol, ...cols];
      } else {
        cols.push(actionCol);
      }
    }
    return cols;
  }, [props.columns]);

  const [deleteMutation] = useTDelete(
    props.delete_gql,
    () => {
      refetch && refetch();
    },
    (err) => {
      message.error(err.message);
    },
  );
  const handleDelete = (record: any) => {
    Modal.confirm({
      title: props.deleteMessage ? props.deleteMessage : 'Are you sure want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk: () => {
        if (props.onDelete) {
          props.onDelete(record);
        } else {
          deleteMutation({ variables: { id: record[props.primaryKey] } });
        }
      },
    });
  };

  const [query, setQuery] = useState<{
    defaultVariables?: any;
    filter?: any;
    orderBy?: any;
    pageSize: number;
    pageIndex: number;
  }>();
  React.useEffect(() => {
    const prevFilter = query?.filter || {};
    setQuery({
      defaultVariables: props.defaultVariables,
      filter: { ...getDefaultFilter(props.filters), ...prevFilter, ...props.defaultFilter },
      orderBy: props.order_by,
      pageSize: DEFAULT_PAGE_SIZE,
      pageIndex: 1,
    });
  }, [props.defaultVariables, props.defaultFilter]);
  const [getData, { data, loading, refetch }] = useTQuery(props.gql, { ...query } as any);

  const { dataSource, totalItems } = useMemo(() => {
    if (!data || !data[props.schema]) return { dataSource: [], totalItems: 0 };

    const dataSource = props.transformDataSource ? props.transformDataSource(data) : data[props.schema].items;
    if (props.onDataSource) {
      props.onDataSource(dataSource);
    }
    return { dataSource, totalItems: data[props.schema].paginate.totalItems };
  }, [data]);

  useEffect(() => {
    if (query) {
      console.log(query);
      let variables: any = { ...query };
      delete variables.defaultVariables;
      delete variables.pageIndex;
      if (props.onFilterChange) {
        props.onFilterChange(variables);
      }
      if (props.transformQuery) {
        variables = props.transformQuery(variables);
      }
      getData({ variables } as any);
    }
  }, [query, props.defaultFilter]);

  const onSearch = (values: any) => {
    setQuery({
      ...query,
      filter: { ...props.defaultFilter, ...values },
      orderBy: props.order_by,
      pageSize: DEFAULT_PAGE_SIZE,
      pageIndex: 1,
    });
  };

  React.useImperativeHandle(
    props.tableRef,
    () => {
      return {
        reload: () => {
          refetch && refetch();
        },
        onSearch,
      };
    },
    [refetch],
  );
  const onTableChange = React.useCallback(
    (pagination, _, sorter) => {
      const { field, order } = sorter;
      const p = pagination?.current || 1;
      setQuery({
        ...query,
        pageIndex: p,
        pageSize: pagination.pageSize,
      });
    },
    [query, props.order_by],
  );

  React.useEffect(() => {
    if (props.scroll) {
      const clientHeight = document.getElementsByClassName('ant-layout-content ')[0].clientHeight;
      const seachFormElm = document.getElementById('ztable-search');
      const searchHeight = seachFormElm ? seachFormElm.clientHeight : 0;
      (document.getElementsByClassName('ant-table-content')[0] as any).style['overflow'] = 'auto auto';
      (document.getElementsByClassName('ant-table-content')[0] as any).style.height = `${
        clientHeight - searchHeight - 50 - 20 - 40
      }px`;
    }
  }, []);
  return (
    <div className="zsport-main-table">
      {props.filters && props.filters.length > 0 && (
        <div style={{ padding: '12px 10px 12px 10px', backgroundColor: 'white', marginBottom: 2 }}>
          <ZFilter
            extraBtns={props.extraBtns}
            onSearch={onSearch}
            filters={props.filters.map((filter) => {
              filter.label = t(filter.label);
              return filter;
            })}
          />
        </div>
      )}
      <Table
        title={props.renderTableTitle}
        size={props.size}
        columns={columns}
        dataSource={dataSource}
        rowKey={props.primaryKey}
        showSorterTooltip={false}
        loading={loading}
        locale={{
          emptyText: <Empty description="Không có dữ liệu" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
        pagination={{
          hideOnSinglePage: !props.scroll,
          current: query?.pageIndex || 1,
          pageSize: query?.pageSize || 20,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ['10', '15', '20', '50', '100'],
          showTotal: (total) => <div>{`Tổng số ${total} bản ghi`}</div>,
        }}
        onChange={onTableChange}
        bordered={props.bordered}
        scroll={props.scroll}
      />
    </div>
  );
}

ZTable.defaultProps = {
  order_by: { createdAt: 'DESC' },
  resizeable: true,
  size: 'default',
};
export default ZTable;
