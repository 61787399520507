import { useLazyQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';

interface FunctionProps {
  defaultVariables?: {};
  filter: any;
  orderBy?: {};
  pageSize: number;
  pageIndex: number;
}
export default (gql: DocumentNode, { defaultVariables, filter, orderBy, pageSize, pageIndex }: FunctionProps) => {
  return useLazyQuery(gql, {
    variables: {
      ...defaultVariables,
      filter,
      pageSize,
      pageIndex,
      orderBy: orderBy,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
};
