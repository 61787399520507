import { protectedComponent } from '@hoc/protectedComponent';
import ListTutorialPage from './pages/list';

export const TutorialRouter = [
  {
    path: '/tutorials',
    exact: true,
    component: protectedComponent(ListTutorialPage),
  },
];
