import { GetOrganizations, GetOrganizationsVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  ListOrganizationActionTypes,
  LIST_ORGANIZATION,
  LIST_ORGANIZATION_ERROR,
  LIST_ORGANIZATION_SUCCESS,
  LIST_ORGANIZATION_RESET,
} from '../action-types';

export const getOrganizationAction = (payload: GetOrganizationsVariables): ListOrganizationActionTypes => ({
  type: LIST_ORGANIZATION,
  payload,
});

export const getOrganizationActionError = (payload: AppError): ListOrganizationActionTypes => ({
  type: LIST_ORGANIZATION_ERROR,
  payload,
});

export const getOrganizationActionSuccess = (payload: GetOrganizations): ListOrganizationActionTypes => ({
  type: LIST_ORGANIZATION_SUCCESS,
  payload,
});

export const getOrganizationActionReset = (): ListOrganizationActionTypes => ({
  type: LIST_ORGANIZATION_RESET,
});
