import { Organization, OrganizationStatus } from '@/graphql/generated/graphql';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useUpdateOrganization from '../hooks/useUpdateOrganization';

const { Option } = Select;

interface PropsInterface {
  isShow: boolean;
  toggleShow(visible?: boolean): void;
  data: Organization | null;
  updateList: () => void;
}

export default function OrganizationUpdateForm(props: PropsInterface) {
  const [form] = Form.useForm();
  const { onUpdate, loading } = useUpdateOrganization();
  const [state, setState] = useState<Organization | null>(null);

  const onClose = () => {
    props.toggleShow(false);
    form.resetFields();
  };

  useEffect(() => {
    return () => onClose();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setState(props.data);
  }, [props.data]);

  const onFinish = (values: any) => {
    if (props.data?.id) {
      const startDate = moment(values.startDate).format('X');
      const endDate = moment(values.endDate).format('X');
      delete values.owner;
      delete values.email;
      // delete values.status;
      values.startDate = parseInt(startDate, 10);
      values.endDate = parseInt(endDate, 10);
      onUpdate({ data: { organizationId: props.data?.id, ...values } });
      props.updateList();
      onClose();
    }
  };

  return (
    <Modal
      width="60%"
      visible={props.isShow}
      title="Cập nhật thông tin tổ chức"
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          initialValue={props.data?.name}
          name="name"
          label="Tên tổ chức"
          rules={[
            { required: true, message: 'Trường này là bắt buộc' },
            { whitespace: true, message: 'Trường này là bắt buộc' },
            { max: 90, message: 'Tên tổ chức không quá 90 ký tự' },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Tên tổ chức *" />
        </Form.Item>
        <Form.Item label="Người đại diện" labelCol={{ span: 6 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item initialValue={`${props.data?.owner?.lastName}`} name="ownerLastName">
                <Input placeholder="Họ" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item initialValue={`${props.data?.owner?.firstName}`} name="ownerFirstName">
                <Input placeholder="Tên" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          initialValue={props.data?.phoneNumber}
          name="phoneNumber"
          label="Số điện thoại"
          rules={[
            { required: true, message: 'Trường này là bắt buộc' },
            { whitespace: true, message: 'Trường này là bắt buộc' },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Số điện thoại *" />
        </Form.Item>
        <Form.Item
          initialValue={props.data?.email}
          name="email"
          label="Email"
          // rules={[
          //   { required: true, message: 'Trường này là bắt buộc' },
          //   { whitespace: true, message: 'Trường này là bắt buộc' },
          // ]}
          labelCol={{ span: 6 }}
        >
          <Input type="email" placeholder="Email *" disabled={props.data?.email ? true : false} />
        </Form.Item>
        <Form.Item
          initialValue={props.data?.startDate ? moment.unix(props.data?.startDate) : null}
          label="Ngày bắt đầu"
          name="startDate"
          labelCol={{ span: 6 }}
        >
          <DatePicker
            placeholder="Chọn ngày bắt đầu"
            getPopupContainer={(trigger) => trigger.parentElement || document.body}
            format="DD/MM/YYYY"
            // disabled={true}
          />
        </Form.Item>
        <Form.Item
          initialValue={props.data?.endDate ? moment.unix(props.data?.endDate) : null}
          label="Ngày kết thúc"
          name="endDate"
          labelCol={{ span: 6 }}
        >
          <DatePicker
            placeholder="Chọn ngày kết thúc"
            getPopupContainer={(trigger) => trigger.parentElement || document.body}
            format="DD/MM/YYYY"
            // disabled={true}
          />
        </Form.Item>
        <Form.Item name="status" initialValue={state?.status} label="Trạng thái" labelCol={{ span: 6 }}>
          <Select
            placeholder="Chọn trạng thái"
            style={{ width: '100%' }}
            getPopupContainer={(trigger) => trigger.parentElement || document.body}
          >
            {state?.status &&
            [OrganizationStatus.Registered, OrganizationStatus.AwaitForActive].includes(state.status) ? (
              <>
                <Option key={OrganizationStatus.Registered} value={OrganizationStatus.Registered}>
                  {OrganizationStatus.Registered}
                </Option>
                <Option key={OrganizationStatus.AwaitForActive} value={OrganizationStatus.AwaitForActive}>
                  {OrganizationStatus.AwaitForActive}
                </Option>
                <Option key={OrganizationStatus.Active} value={OrganizationStatus.Active}>
                  {OrganizationStatus.Active}
                </Option>
              </>
            ) : (
              <>
                <Option key={OrganizationStatus.Active} value={OrganizationStatus.Active}>
                  {OrganizationStatus.Active}
                </Option>
                <Option key={OrganizationStatus.Canceled} value={OrganizationStatus.Canceled}>
                  {OrganizationStatus.Canceled}
                </Option>
              </>
            )}
          </Select>
        </Form.Item>
        <Form.Item initialValue={props.data?.membersNumber} label="Số thành viên" labelCol={{ span: 6 }}>
          {props.data?.membersNumber}
        </Form.Item>
        <div className="text-right">
          <Button key="back" onClick={onClose} className="mr-3">
            Huỷ
          </Button>
          <Button htmlType="submit" key="submit" type="primary" loading={loading}>
            Cập nhật
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
