import { AdminLoginInput, AuthUserFields, JwtFields } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface LoginState {
  loading: boolean;
  userInfo?: AuthUserFields;
}

export interface LoginAction {
  type: typeof LOGIN;
  payload: AdminLoginInput;
}

export interface LoginActionSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: JwtFields;
  isAutoLogin: boolean;
}

export interface LoginActionError {
  type: typeof LOGIN_ERROR;
  payload: AppError;
}

export type LoginActionTypes = LoginAction | LoginActionSuccess | LoginActionError;
