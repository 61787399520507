import {
  UpdateTutorialActionTypes,
  UPDATE_TUTORIAL,
  UPDATE_TUTORIAL_ERROR,
  UPDATE_TUTORIAL_SUCCESS,
} from '../action-types';

export interface UpdateTutorialState {
  loading: boolean;
  isSuccess: boolean;
}

const initialState = {
  loading: false,
  isSuccess: false,
};

export default function updateTutorial(
  state: UpdateTutorialState = initialState,
  action: UpdateTutorialActionTypes,
): UpdateTutorialState {
  switch (action.type) {
    case UPDATE_TUTORIAL:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_TUTORIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: action.payload,
      };
    case UPDATE_TUTORIAL_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };

    default:
      return {
        ...state,
        isSuccess: false,
      };
  }
}
