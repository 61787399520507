import { ListUser, ListUserVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { ListUserActionTypes, LIST_USER, LIST_USER_ERROR, LIST_USER_RESET, LIST_USER_SUCCESS } from '../action-types';

export const listUser = (payload: ListUserVariables): ListUserActionTypes => ({
  type: LIST_USER,
  payload,
});

export const listUserError = (payload: AppError): ListUserActionTypes => ({
  type: LIST_USER_ERROR,
  payload,
});

export const listUserSuccess = (payload: ListUser): ListUserActionTypes => ({
  type: LIST_USER_SUCCESS,
  payload,
});

export const listUserReset = (): ListUserActionTypes => ({
  type: LIST_USER_RESET,
});
