import { Tutorial } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const GET_TUTORIALS = 'GET_TUTORIALS';
export const GET_TUTORIALS_SUCCESS = 'GET_TUTORIALS_SUCCESS';
export const GET_TUTORIALS_ERROR = 'GET_TUTORIALS_ERROR';
export const GET_TUTORIALS_RESET = 'GET_TUTORIALS_RESET';

export interface GetTutorialsAction {
  type: typeof GET_TUTORIALS;
}

export interface GetTutorialsActionSuccess {
  type: typeof GET_TUTORIALS_SUCCESS;
  payload?: Tutorial[];
}

export interface GetTutorialsActionError {
  type: typeof GET_TUTORIALS_ERROR;
  payload: AppError;
}

export interface GetTutorialsActionReset {
  type: typeof GET_TUTORIALS_RESET;
}

export type GetTutorialsActionTypes =
  | GetTutorialsAction
  | GetTutorialsActionSuccess
  | GetTutorialsActionError
  | GetTutorialsActionReset;
