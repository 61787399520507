import { UpdateOrganizationInfoVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  UpdateOrganizationActionTypes,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION_RESET,
  UPDATE_ORGANIZATION_SUCCESS,
} from '../action-types';

export const updateOrganizationAction = (payload: UpdateOrganizationInfoVariables): UpdateOrganizationActionTypes => ({
  type: UPDATE_ORGANIZATION,
  payload,
});

export const updateOrganizationActionError = (payload: AppError): UpdateOrganizationActionTypes => ({
  type: UPDATE_ORGANIZATION_ERROR,
  payload,
});

export const updateOrganizationActionSuccess = (payload: boolean): UpdateOrganizationActionTypes => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload,
});

export const updateOrganizationActionReset = (): UpdateOrganizationActionTypes => ({
  type: UPDATE_ORGANIZATION_RESET,
});
