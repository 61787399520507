import { all, take } from 'redux-saga/effects';
import authSaga from '@modules/Auth/redux/sagas';
import userSaga from '@modules/User/redux/sagas';
import organizationSaga from '@modules/Organization/redux/sagas';
import tutorialSaga from '@modules/Tutorial/redux/sagas';

import * as commonSaga from './common-saga';
import { APP_STARTED } from './actions';

export default function* rootSaga() {
  yield take(APP_STARTED);
  yield all([
    authSaga(),
    userSaga(),
    organizationSaga(),
    tutorialSaga(),
    commonSaga.checkErrorAsync(),
    // for-generator - don't remove this comment
  ]);
}
