import { Tutorial } from '@/graphql/generated/graphql';
import { getTutorials } from '@modules/Tutorial/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useGetTutorials = () => {
  const loading = useSelector<RootState>((state) => state.tutorial.list.loading) as boolean;
  const data = useSelector<RootState, Tutorial[] | undefined>((state) => state.tutorial.list.data);
  const dispatch = useDispatch();
  const onGetList = useCallback(() => {
    dispatch(getTutorials());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onGetList,
    loading,
    data,
  };
};

export default useGetTutorials;
