import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@redux/reducers';
import { uploadPhoto } from '../redux/actions';
import { File, SingleUploadMutationVariables } from '@/graphql/generated/graphql';

const useUploadPhoto = () => {
  const loading = useSelector<RootState, boolean>((state) => state.tutorial.upload.loading);
  const visible = useSelector<RootState, boolean>((state) => state.tutorial.upload.visible);
  const isSuccess = useSelector<RootState, boolean>((state) => state.tutorial.upload.isSuccess);
  const data = useSelector<RootState, File | undefined>((state) => state.tutorial.upload.data);

  const dispatch = useDispatch();
  const onUpload = useCallback((data: SingleUploadMutationVariables) => {
    dispatch(uploadPhoto(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onUpload,
    loading,
    visible,
    isSuccess,
    data,
  };
};

export default useUploadPhoto;
