import { DeleteTutorialMutationVariables } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTutorial } from '../redux/actions';

const useDeleteTutorial = () => {
  const loading = useSelector<RootState, boolean>((state) => state.tutorial.delete.loading);
  const isSuccess = useSelector<RootState, boolean>((state) => state.tutorial.delete.isSuccess);
  const dispatch = useDispatch();
  const onDelete = useCallback((data: DeleteTutorialMutationVariables) => {
    dispatch(deleteTutorial(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onDelete,
    loading,
    isSuccess,
  };
};

export default useDeleteTutorial;
