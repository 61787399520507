import { GetOrganizations } from '@/graphql/generated/graphql';
import {
  ListOrganizationActionTypes,
  LIST_ORGANIZATION,
  LIST_ORGANIZATION_ERROR,
  LIST_ORGANIZATION_RESET,
  LIST_ORGANIZATION_SUCCESS,
} from '../action-types';

export interface ListOrganizationState {
  loading: boolean;
  data?: GetOrganizations['organizations'];
}

const initialState = {
  loading: false,
};

export default function organizationOrganization(
  state: ListOrganizationState = initialState,
  action: ListOrganizationActionTypes,
): ListOrganizationState {
  switch (action.type) {
    case LIST_ORGANIZATION_RESET:
      return initialState;

    case LIST_ORGANIZATION:
      return {
        ...state,
        loading: true,
      };

    case LIST_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.organizations,
      };

    case LIST_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
