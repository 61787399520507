import { ChangeOrganizationStatusVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  ChangeOrganizationStatusActionTypes,
  CHANGE_ORGANIZATION_STATUS,
  CHANGE_ORGANIZATION_STATUS_ERROR,
  CHANGE_ORGANIZATION_STATUS_RESET,
  CHANGE_ORGANIZATION_STATUS_SUCCESS,
} from '../action-types';

export const changeOrganizationStatusAction = (
  payload: ChangeOrganizationStatusVariables,
): ChangeOrganizationStatusActionTypes => ({
  type: CHANGE_ORGANIZATION_STATUS,
  payload,
});

export const changeOrganizationStatusActionError = (payload: AppError): ChangeOrganizationStatusActionTypes => ({
  type: CHANGE_ORGANIZATION_STATUS_ERROR,
  payload,
});

export const changeOrganizationStatusActionSuccess = (payload: boolean): ChangeOrganizationStatusActionTypes => ({
  type: CHANGE_ORGANIZATION_STATUS_SUCCESS,
  payload,
});

export const changeOrganizationStatusActionReset = (): ChangeOrganizationStatusActionTypes => ({
  type: CHANGE_ORGANIZATION_STATUS_RESET,
});
