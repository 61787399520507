import { Tutorial } from '@/graphql/generated/graphql';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import PageHeader from '@commons/components/PageHeader';
import TableHeader from '@commons/components/TableHeader';
import TutorialCreateModal from '@modules/Tutorial/components/CreateModal';
import TutorialUpdateModal from '@modules/Tutorial/components/UpdateModal';
import useDeleteTutorial from '@modules/Tutorial/hooks/useDeleteTutorial';
import useGetTutorials from '@modules/Tutorial/hooks/useGetTutorials';
import { Button, Popconfirm, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';

const routes = [
  {
    path: '/',
    breadcrumbName: 'Trang chủ',
  },
  {
    path: '/tutorials',
    breadcrumbName: 'Quản lý video',
  },
];

interface InitState {
  selectedRowKeys: any[];
  pageIndex: number;
  pageSize: number;
  isShowDetail: boolean;
  isShowUpdate: boolean;
  isShowCreate: boolean;
  data: Tutorial | null;
  items?: Tutorial[];
}

export default function ListTutorialPage() {
  const { onGetList, data: tutorials, loading } = useGetTutorials();
  const { onDelete, isSuccess: deleteSuccess } = useDeleteTutorial();

  const [state, setState] = useState<InitState>({
    selectedRowKeys: [],
    pageIndex: 1,
    pageSize: 20,
    isShowDetail: false,
    isShowUpdate: false,
    isShowCreate: false,
    data: null,
    items: tutorials,
  });

  useEffect(() => {
    if (deleteSuccess) {
      onGetList();
    }
    // eslint-disable-next-line
  }, [deleteSuccess]);

  useEffect(() => {
    onGetList();
    // eslint-disable-next-line
  }, []);

  const toggleShowUpdateForm = (visible?: boolean) => {
    if (typeof visible !== undefined) {
      setState({ ...state, isShowUpdate: !!visible });
    } else {
      setState({ ...state, isShowUpdate: !!state.isShowUpdate });
    }
  };

  const toggleShowCreateForm = (visible?: boolean) => {
    if (typeof visible !== undefined) {
      setState({ ...state, isShowCreate: !!visible });
    } else {
      setState({ ...state, isShowCreate: !!state.isShowCreate });
    }
  };

  const handleEdit = (event: any, record: Tutorial) => {
    event.stopPropagation();
    setState({ ...state, isShowUpdate: true, data: record });
  };

  const handleDelete = (event: any, record: Tutorial) => {
    event.stopPropagation();
    onDelete({ id: record.id });
  };

  const columns: ColumnsType<Tutorial> = [
    {
      title: '#',
      width: 1,
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      width: 3,
    },
    {
      title: 'Ảnh',
      dataIndex: 'thumbId',
      width: 3,
      render: (value: string, record: Tutorial) => {
        return record.thumb?.thumbnail ? (
          <img style={{ maxWidth: '100px' }} src={record.thumb.thumbnail} alt={record.title} />
        ) : null;
      },
    },
    {
      title: 'Video url',
      dataIndex: 'url',
      width: 3,
    },
    {
      title: 'Thứ tự',
      dataIndex: 'sort',
      width: 1,
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: 2,
      fixed: 'right',
      render: (_text: string, record: Tutorial) => {
        return (
          <Row style={{ whiteSpace: 'nowrap' }}>
            <Button
              size="small"
              className="ant-btn ml-1 mr-1 ant-btn-sm mb-1"
              onClick={(event) => handleEdit(event, record)}
            >
              <EditFilled />
            </Button>
            <Popconfirm title="Chắc chắn xóa?" onConfirm={(event: any) => handleDelete(event, record)}>
              <Button size="small" className="ant-btn ml-1 mr-1 ant-btn-sm mb-1">
                <DeleteFilled />
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader title="Danh sách video" breadcrumb={{ routes }} />
      <TableHeader title="Danh sách video">
        <Button
          type="primary"
          className="position-absolute"
          style={{ right: '12px', top: '12px' }}
          onClick={() => toggleShowCreateForm(true)}
        >
          Tạo mới
        </Button>
        <Table
          loading={loading}
          dataSource={tutorials as Tutorial[]}
          columns={columns}
          rowKey="id"
          tableLayout="fixed"
          scroll={{ x: 1024 }}
          size="middle"
          pagination={false}
          // rowSelection={rowSelection}
        />
      </TableHeader>

      <TutorialUpdateModal isShow={state.isShowUpdate} toggleShow={toggleShowUpdateForm} data={state.data} />
      <TutorialCreateModal isShow={state.isShowCreate} toggleShow={toggleShowCreateForm} />
    </>
  );
}
