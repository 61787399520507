import {
  UpdateOrganizationActionTypes,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION_RESET,
  UPDATE_ORGANIZATION_SUCCESS,
} from '../action-types';

export interface UpdateOrganizationState {
  loading: boolean;
  isSuccess: boolean;
}

const initialState = {
  loading: false,
  isSuccess: false,
};

export default function organizationOrganization(
  state: UpdateOrganizationState = initialState,
  action: UpdateOrganizationActionTypes,
): UpdateOrganizationState {
  switch (action.type) {
    case UPDATE_ORGANIZATION_RESET:
      return initialState;

    case UPDATE_ORGANIZATION:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: action.payload,
      };
    case UPDATE_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
