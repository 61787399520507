import {
  CreateBlogTag,
  CreateBlogTagDocument,
  CreateBlogTagVariables,
  GetBlogTagsDocument,
  UpdateBlogTag,
  UpdateBlogTagDocument,
  UpdateBlogTagVariables,
} from '@/graphql/generated/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GetBlogTags, GetBlogTagsVariables } from '@/graphql/generated/graphql';

export const useGetBlogTags = () => {
  return useLazyQuery<GetBlogTags, GetBlogTagsVariables>(GetBlogTagsDocument);
};

export const useCreateBlogTag = () => {
  return useMutation<CreateBlogTag, CreateBlogTagVariables>(CreateBlogTagDocument);
};

export const useUpdateBlogTag = () => {
  return useMutation<UpdateBlogTag, UpdateBlogTagVariables>(UpdateBlogTagDocument);
};
