import { DeleteTutorialMutationVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const DELETE_TUTORIAL = 'DELETE_TUTORIAL';
export const DELETE_TUTORIAL_SUCCESS = 'DELETE_TUTORIAL_SUCCESS';
export const DELETE_TUTORIAL_ERROR = 'DELETE_TUTORIAL_ERROR';
export const DELETE_TUTORIAL_RESET = 'DELETE_TUTORIAL_RESET';

export interface DeleteTutorialAction {
  type: typeof DELETE_TUTORIAL;
  payload: DeleteTutorialMutationVariables;
}

export interface DeleteTutorialActionSuccess {
  type: typeof DELETE_TUTORIAL_SUCCESS;
  payload: boolean;
}

export interface DeleteTutorialActionError {
  type: typeof DELETE_TUTORIAL_ERROR;
  payload: AppError;
}

export type DeleteTutorialActionTypes = DeleteTutorialAction | DeleteTutorialActionSuccess | DeleteTutorialActionError;
