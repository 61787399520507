import { Tutorial, CreateTutorialMutationVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const CREATE_TUTORIAL = 'CREATE_TUTORIAL';
export const CREATE_TUTORIAL_SUCCESS = 'CREATE_TUTORIAL_SUCCESS';
export const CREATE_TUTORIAL_ERROR = 'CREATE_TUTORIAL_ERROR';

export interface CreateTutorialAction {
  type: typeof CREATE_TUTORIAL;
  payload: CreateTutorialMutationVariables;
}

export interface CreateTutorialActionSuccess {
  type: typeof CREATE_TUTORIAL_SUCCESS;
  payload: Tutorial;
}

export interface CreateTutorialActionError {
  type: typeof CREATE_TUTORIAL_ERROR;
  payload: AppError;
}

export type CreateTutorialActionTypes = CreateTutorialAction | CreateTutorialActionSuccess | CreateTutorialActionError;
