import EmptyLayout from '@commons/components/layouts/Empty';
import MasterLayout from '@commons/components/layouts/MasterLayout';
import { AuthRouter } from '@modules/Auth/router';
import { DashboardRouter } from '@modules/Dashboard/router';
import { ListUserRouter } from '@modules/User/routers';
import { OrganizationRouter } from '@modules/Organization/routers';
import { BlogRouter } from '@modules/Blog/routers';
import { TutorialRouter } from '@modules/Tutorial/routers';
import { RouteConfig } from 'react-router-config';

const routes: RouteConfig[] = [
  {
    component: EmptyLayout,
    routes: [
      AuthRouter,
      {
        component: MasterLayout,
        path: '/',
        routes: [DashboardRouter, ListUserRouter, ...OrganizationRouter, ...TutorialRouter, ...BlogRouter],
      },
    ],
  },
];

export default routes;
