import {
  CreateBlogCategory,
  CreateBlogCategoryDocument,
  CreateBlogCategoryVariables,
  GetBlogCategories,
  GetBlogCategoriesDocument,
  GetBlogCategoriesVariables,
  UpdateBlogCategory,
  UpdateBlogCategoryDocument,
  UpdateBlogCategoryVariables,
} from '@/graphql/generated/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';

export const useGetBlogCategories = () => {
  return useLazyQuery<GetBlogCategories, GetBlogCategoriesVariables>(GetBlogCategoriesDocument);
};

export const useCreateBlogCategory = () => {
  return useMutation<CreateBlogCategory, CreateBlogCategoryVariables>(CreateBlogCategoryDocument);
};

export const useUpdateBlogCategory = () => {
  return useMutation<UpdateBlogCategory, UpdateBlogCategoryVariables>(UpdateBlogCategoryDocument);
};
