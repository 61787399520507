import { isJsonString } from './string';
import env from '@/env';
import moment from 'moment';
import { setRedirectUrl } from './history';
import { JwtFields } from '@/graphql/generated/graphql';

let TOKEN: any = '';
const TOKEN_KEY = env.tokenKey;

export const setAuthData = (authData: JwtFields) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(authData || {}));
  TOKEN = authData.token;
};

export const getAuthLocalData = () => {
  const authData = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  if (!tokenChecker(authData)) {
    localStorage.removeItem(TOKEN_KEY);
    return null;
  }
  TOKEN = authData?.token;
  return authData;
};

export const getToken = () => {
  return TOKEN;
};

export const setToken = (token: string) => {
  TOKEN = token;
};

export const removeToken = () => {
  localStorage.removeItem(env.tokenKey);
  setToken('');
  setRedirectUrl('');
};

export const parseTokenString = (str: string) => {
  if (isJsonString(str)) {
    const authObject: JwtFields = JSON.parse(str);
    return authObject;
  }
  return null;
};

export const tokenChecker = (authData: JwtFields | null) => {
  if (!authData || !authData.token || authData.expiresAt < moment().unix()) return false;
  return true;
};
