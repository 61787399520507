import { DeleteTutorialMutationVariables } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  DeleteTutorialActionTypes,
  DELETE_TUTORIAL,
  DELETE_TUTORIAL_ERROR,
  DELETE_TUTORIAL_SUCCESS,
} from '../action-types';

export const deleteTutorial = (payload: DeleteTutorialMutationVariables): DeleteTutorialActionTypes => ({
  type: DELETE_TUTORIAL,
  payload,
});

export const deleteTutorialError = (payload: AppError): DeleteTutorialActionTypes => ({
  type: DELETE_TUTORIAL_ERROR,
  payload,
});

export const deleteTutorialSuccess = (payload: boolean): DeleteTutorialActionTypes => ({
  type: DELETE_TUTORIAL_SUCCESS,
  payload,
});
