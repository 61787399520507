import { GetOrganizations, GetOrganizationsVariables } from '@/graphql/generated/graphql';
import { getOrganizationAction } from '@modules/Organization/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useGetOrganizations = () => {
  const loading = useSelector<RootState>((state) => state.organization.list.loading) as boolean;
  const data = useSelector<RootState, GetOrganizations['organizations'] | undefined>(
    (state) => state.organization.list.data,
  );
  const dispatch = useDispatch();
  const onGetList = useCallback((data: GetOrganizationsVariables) => {
    dispatch(getOrganizationAction(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onGetList,
    loading,
    data,
  };
};

export default useGetOrganizations;
