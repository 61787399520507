import { User } from '@/graphql/generated/graphql';
import { Button, Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect } from 'react';
import updateUser from '../hooks/useUpdateUser';

interface PropsInterface {
  isShow: boolean;
  toggleShow(visible?: boolean): void;
  data: User | null;
}

export default function UserUpdateModal(props: PropsInterface) {
  const [form] = Form.useForm();
  const { loading } = updateUser();

  const onClose = () => {
    props.toggleShow(false);
    form.resetFields();
  };

  useEffect(() => {
    return () => onClose();
    // eslint-disable-next-line
  }, []);

  const onFinish = (values: any) => {
    if (props.data?.id) {
      //onUpdate({ data: { organizationId: props.data?.id, ...values } });
      onClose();
    }
  };

  return (
    <Modal
      width="60%"
      visible={props.isShow}
      title="Cập nhật thông tin người dùng"
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          name="password"
          label="Mật khẩu mới"
          rules={[
            { required: true, message: 'Trường này là bắt buộc' },
            { whitespace: true, message: 'Trường này là bắt buộc' },
          ]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Mật khẩu mới *" />
        </Form.Item>
        <div className="text-right">
          <Button key="back" onClick={onClose} className="mr-3">
            Huỷ
          </Button>
          <Button htmlType="submit" key="submit" type="primary" loading={loading}>
            Cập nhật
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
