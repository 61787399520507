import { ChangeOrganizationStatusVariables } from '@/graphql/generated/graphql';
import { changeOrganizationStatusAction } from '@modules/Organization/redux/actions';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useChangeOrganizationStatus = () => {
  const loading = useSelector<RootState>((state) => state.organization.changeStatus.loading) as boolean;
  const isSuccess = useSelector<RootState>((state) => state.organization.changeStatus.isSuccess) as boolean;

  const dispatch = useDispatch();
  const onChangeStatus = useCallback((data: ChangeOrganizationStatusVariables) => {
    dispatch(changeOrganizationStatusAction(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onChangeStatus,
    loading,
    isSuccess,
  };
};

export default useChangeOrganizationStatus;
