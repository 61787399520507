export enum YoutubeThumbSize {
  LOW = 'sddefault',
  MEDIUM = 'mqdefault',
  HIGHT = 'hqdefault',
  MAX = 'maxresdefault',
}

export const getThumbYoutube = (url: string, size: YoutubeThumbSize) => {
  let videoId, results;

  if (url === null) {
    return '';
  }
  size = size === null ? YoutubeThumbSize.HIGHT : size;
  results = url.match('[\\?&]v=([^&#]*)');
  videoId = results === null ? url : results[1];
  return `http://img.youtube.com/vi/${videoId}/${size}.jpg`;
};
