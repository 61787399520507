import { JwtFields, LoginMutation, RefreshToken } from '@/graphql/generated/graphql';
import { getHistory, getRedirectUrl } from '@/helpers/history';
import { getAuthLocalData, setAuthData } from '@/helpers/token';
import { CommonPath } from '@commons/base-routes';
import { NotificationSuccess } from '@commons/components/Notification';
import { getSDK } from '@services/graphql-client';
import { call, delay, put, race, take } from 'redux-saga/effects';
import { LoginAction, LoginActionSuccess, LOGIN_SUCCESS, LOGOUT } from '../action-types';
import { loginError, loginSuccess } from '../actions';

export function* loginAsync(action: LoginAction) {
  try {
    const payload: LoginMutation = yield call(getSDK().loginMutation, {
      password: action.payload.password,
      username: action.payload.username,
    });
    yield put(loginSuccess(payload.adminLogin));
  } catch (error) {
    yield put(loginError(error));
  }
}

export function loginSuccessAsync(action: LoginActionSuccess) {
  const data = action.payload;

  if (!action.isAutoLogin) {
    setAuthData(data);
    NotificationSuccess('Thông báo', 'Đăng nhập thành công');
  }

  const redirectUrl = getRedirectUrl();
  if (redirectUrl) {
    const rUrl = new URL(redirectUrl);
    const cOrigin = window.location.origin;
    if (cOrigin === rUrl.origin) {
      const newPath = rUrl.pathname + rUrl.search;
      if (newPath.indexOf(CommonPath.LOGIN_PATH) === 0) {
        getHistory().replace(CommonPath.DEFAULT_PATH);
      } else {
        getHistory().replace(newPath);
      }
    } else {
      window.location.href = redirectUrl;
    }
  } else getHistory().push(CommonPath.DEFAULT_PATH);
}

export function* autoLoginFlow() {
  while (1) {
    const userInfo = getAuthLocalData();
    if (userInfo) {
      yield put(loginSuccess(userInfo, true));
    }
    yield take(LOGOUT);
  }
}

function* backgroundRefreshTokenTask() {
  try {
    while (true) {
      yield delay(10000);
      const userInfo: JwtFields | null = getAuthLocalData();
      if (userInfo && userInfo?.expiresAt - Date.now() / 1000 < 60000) {
        const newJwt: RefreshToken = yield call(getSDK().refreshToken, {
          token: userInfo.refreshToken,
        });
        setAuthData(newJwt.refreshToken);
      }
    }
  } finally {
  }
}

export function* refreshTokenLoginFlow() {
  while (1) {
    yield take(LOGIN_SUCCESS);
    yield race({
      task: call(backgroundRefreshTokenTask),
      cancel: take(LOGOUT),
    });
  }
}
