import { CreateTutorialMutationVariables, Tutorial } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  CreateTutorialActionTypes,
  CREATE_TUTORIAL,
  CREATE_TUTORIAL_ERROR,
  CREATE_TUTORIAL_SUCCESS,
} from '../action-types';

export const createTutorial = (payload: CreateTutorialMutationVariables): CreateTutorialActionTypes => ({
  type: CREATE_TUTORIAL,
  payload,
});

export const createTutorialError = (payload: AppError): CreateTutorialActionTypes => ({
  type: CREATE_TUTORIAL_ERROR,
  payload,
});

export const createTutorialSuccess = (payload: Tutorial): CreateTutorialActionTypes => ({
  type: CREATE_TUTORIAL_SUCCESS,
  payload,
});
