import { CreateTutorialMutationVariables, Tutorial } from '@/graphql/generated/graphql';
import { RootState } from '@redux/reducers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTutorial } from '../redux/actions';

const useCreateTutorial = () => {
  const loading = useSelector<RootState, boolean>((state) => state.tutorial.create.loading);
  const isSuccess = useSelector<RootState, boolean>((state) => state.tutorial.create.isSuccess);
  const data = useSelector<RootState, Tutorial | undefined>((state) => state.tutorial.create.data);
  const dispatch = useDispatch();
  const onCreate = useCallback((data: CreateTutorialMutationVariables) => {
    dispatch(createTutorial(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onCreate,
    loading,
    isSuccess,
    data,
  };
};

export default useCreateTutorial;
