import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';

export default (gql: DocumentNode | null | undefined, onCompleted: () => void, onError: (err: any) => void) => {
  if (!gql) {
    return [];
  }
  return useMutation(gql, {
    onCompleted,
    onError,
  });
};
