import { call, put } from 'redux-saga/effects';
import { createTutorialError, createTutorialSuccess } from '../actions/create';
import { CreateTutorialAction } from '../action-types';
import { getSDK } from '@services/graphql-client';

export function* createTutorialSaga(action: CreateTutorialAction) {
  const sdk = getSDK();
  try {
    const res = yield call(sdk.createTutorialMutation, action.payload);
    yield put(createTutorialSuccess(res));
  } catch (error) {
    yield put(createTutorialError(error));
  }
}
