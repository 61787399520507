import { Tutorial } from '@/graphql/generated/graphql';
import {
  CreateTutorialActionTypes,
  CREATE_TUTORIAL,
  CREATE_TUTORIAL_ERROR,
  CREATE_TUTORIAL_SUCCESS,
} from '../action-types';

export interface CreateTutorialState {
  loading: boolean;
  isSuccess: boolean;
  data?: Tutorial;
}

const initialState = {
  loading: false,
  isSuccess: false,
};

export default function createTutorial(
  state: CreateTutorialState = initialState,
  action: CreateTutorialActionTypes,
): CreateTutorialState {
  switch (action.type) {
    case CREATE_TUTORIAL:
      return {
        ...state,
        loading: true,
      };

    case CREATE_TUTORIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        data: action.payload,
      };
    case CREATE_TUTORIAL_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };

    default:
      return {
        ...state,
        isSuccess: false,
      };
  }
}
